import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Dropdown from 'antd/lib/dropdown/dropdown';
import useToggle from '@app/hooks/useToggle';
import EmailForm from './EmailForm';
import { useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';

const Container = styled(HorizontalAlignedContainer)`
  flex-wrap: wrap;
  padding: 12px;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  gap: 12px;
`;

const ItemChip = styled(HorizontalAlignedContainer)`
  padding: 8px 10px;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #272727;
  line-height: 14px;
`;

const ItemChipCloseButton = styled(CloseOutlined)`
  margin-left: 5px;
  cursor: pointer;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
  color: #272727;
`;

export const DeleteIcon = styled(RiDeleteBinLine)`
  width: 1rem;
  height: 1rem;
  color: #d81c2e;
`;

interface IEmailGroupInputProps {
  emails: string[];
  isDropdownOpen: boolean;
  single?: boolean;
  toggle: () => void;
  removeEmail: (index: number) => void;
  handleAddEmail: (email: string) => void;
  clearEmails: () => void;
}

export default function EmailGroupInput({
  emails,
  isDropdownOpen,
  single,
  toggle,
  handleAddEmail,
  removeEmail,
  clearEmails,
}: IEmailGroupInputProps) {
  return (
    <Container>
      {emails.map((email, index) => (
        <ItemChip key={index}>
          {email}
          <ItemChipCloseButton onClick={() => removeEmail(index)} />
        </ItemChip>
      ))}
      {single && emails.length >= 1 ? null : (
        <Dropdown
          trigger={['click']}
          arrow
          open={isDropdownOpen}
          onOpenChange={toggle}
          destroyPopupOnHide={true}
          dropdownRender={(menu) => <EmailForm onAdd={handleAddEmail} />}
        >
          <Text role="button" style={{ flexShrink: 0 }}>
            Add Email
          </Text>
        </Dropdown>
      )}
      <div style={{ flexGrow: 1 }} />
      <StyledButton btnSize="sm" variant="outlined" color="dark" onClick={clearEmails}>
        <DeleteIcon />
        Clear
      </StyledButton>
    </Container>
  );
}
