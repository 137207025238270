import userReducer from '@app/store/slices/userSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import appValuesReducer from '@app/store/slices/appSlice';

export default {
  user: userReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  app: appValuesReducer,
};
