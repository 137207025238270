import { IconProps } from '../types';

export default function Actions(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_203_3)">
        <path
          d="M9.24606 8.5544C9.47326 8.7915 9.53868 8.98489 9.56591 9.30737C9.55221 9.843 9.41576 10.3577 9.29351 10.877C9.26818 10.9868 9.24285 11.0966 9.21676 11.2097C9.14954 11.4998 9.08156 11.7897 9.01333 12.0796C8.95803 12.3158 8.90391 12.5524 8.84978 12.7889C8.7847 13.0724 8.71843 13.3556 8.65226 13.6389C8.62806 13.7445 8.60386 13.8502 8.57893 13.9591C8.55553 14.0576 8.53212 14.1561 8.50801 14.2577C8.48782 14.344 8.46764 14.4304 8.44684 14.5194C8.33537 14.8682 8.17854 15.1314 7.91996 15.39C7.52871 15.5083 7.28645 15.4731 6.91627 15.3056C6.62154 15.1361 6.40171 14.8993 6.17058 14.6531C6.12191 14.6028 6.07324 14.5525 6.0231 14.5007C5.90454 14.378 5.78712 14.2541 5.66996 14.13C5.34787 14.3929 5.04913 14.6485 4.78332 14.9685C4.31494 15.5106 3.8028 16.0106 3.29566 16.516C3.12036 16.6908 2.94556 16.8661 2.7708 17.0415C2.65944 17.1528 2.54805 17.264 2.43664 17.3753C2.35831 17.4539 2.35831 17.4539 2.2784 17.5341C1.91544 17.8949 1.74628 18.0157 1.23746 18.0337C0.885042 18.0319 0.668232 18.0139 0.393706 17.7862C0.120195 17.4839 0.073291 17.3406 0.0618311 16.9425C0.0584666 16.8669 0.0551021 16.7912 0.0516357 16.7133C0.155503 16.0539 0.781753 15.5749 1.23324 15.1291C1.30864 15.054 1.38404 14.9788 1.46173 14.9014C1.70155 14.6626 1.94199 14.4244 2.18246 14.1862C2.34562 14.024 2.50875 13.8617 2.67183 13.6993C3.07073 13.3024 3.47015 12.906 3.86996 12.51C3.70592 12.1525 3.47037 11.9129 3.18933 11.6437C2.77153 11.2403 2.60525 11.0544 2.57164 10.4551C2.62979 10.159 2.75901 10.025 2.96996 9.81C3.21522 9.71514 3.43374 9.64656 3.68714 9.58675C3.75813 9.56858 3.82911 9.5504 3.90224 9.53167C4.05369 9.493 4.20531 9.45503 4.35709 9.4177C4.58709 9.36071 4.81619 9.30099 5.04523 9.24025C5.42986 9.13839 5.81513 9.0393 6.20082 8.94153C6.35831 8.9014 6.51569 8.86082 6.67296 8.8198C6.90556 8.75917 7.13848 8.69997 7.37152 8.64105C7.44054 8.62278 7.50956 8.6045 7.58066 8.58567C8.79827 8.28361 8.79827 8.28361 9.24606 8.5544Z"
          fill={fill}
        />
        <path
          d="M14.1526 1.3275C16.0956 2.69505 17.4034 4.63186 17.8594 6.97184C18.2163 9.21436 17.7764 11.396 16.5601 13.32C16.3071 13.6663 16.0355 13.9901 15.7501 14.31C15.6825 14.3861 15.615 14.4622 15.5454 14.5406C14.0827 16.0957 12.1199 16.8896 10.0206 17.1074C9.77878 17.0989 9.64821 17.0584 9.45005 16.92C9.36454 16.6121 9.3126 16.3998 9.44197 16.1005C9.74032 15.83 10.0846 15.8169 10.4738 15.7556C11.2233 15.6214 11.9057 15.4417 12.6001 15.12C12.6836 15.0814 12.7671 15.0427 12.8532 15.0029C14.5536 14.1455 15.7365 12.6234 16.3688 10.8506C16.9759 8.86529 16.8066 6.84284 15.8583 5.00342C15.5777 4.48618 15.2483 4.034 14.8501 3.6C14.7903 3.53201 14.7306 3.46403 14.669 3.39398C13.6339 2.28042 12.1287 1.48296 10.6201 1.26C10.5224 1.24521 10.5224 1.24521 10.4228 1.23012C10.2186 1.20348 10.0155 1.18475 9.81005 1.17C9.71243 1.16269 9.71243 1.16269 9.61283 1.15523C8.44396 1.11172 7.26585 1.40644 6.21005 1.89C6.12722 1.9277 6.04438 1.96541 5.95904 2.00426C4.30927 2.82914 3.06186 4.32278 2.45255 6.0525C2.2609 6.65744 2.14846 7.2597 2.05845 7.8873C1.97265 8.21859 1.88919 8.34446 1.62005 8.55C1.39505 8.60062 1.39505 8.60062 1.17005 8.55C0.895173 8.29088 0.812813 8.16011 0.795288 7.77832C0.808764 7.6546 0.825114 7.53117 0.843804 7.40812C0.853012 7.34155 0.862221 7.27498 0.871709 7.20639C1.2077 5.051 2.36602 3.02052 4.10859 1.68627C7.10133 -0.451402 11.0368 -0.649714 14.1526 1.3275Z"
          fill={fill}
        />
        <path
          d="M13.6374 3.50016C14.8911 4.54327 15.6266 6.12863 15.8178 7.73367C15.9678 9.60463 15.4627 11.2746 14.3012 12.7498C13.4163 13.7829 12.2713 14.4567 10.9575 14.7938C10.8991 14.8089 10.8406 14.824 10.7804 14.8395C10.3553 14.9395 9.9733 14.967 9.53999 14.94C9.35999 14.76 9.35999 14.76 9.33924 14.5487C9.34052 14.4681 9.3418 14.3875 9.34311 14.3044C9.34369 14.2235 9.34427 14.1427 9.34487 14.0593C9.35999 13.86 9.35999 13.86 9.44999 13.77C9.62583 13.7428 9.8022 13.7191 9.97874 13.6969C11.2925 13.4992 12.5584 12.9284 13.41 11.88C14.2824 10.6869 14.7102 9.40269 14.58 7.92C14.3582 6.50128 13.7969 5.27673 12.6791 4.34004C11.4536 3.45631 9.92475 3.16654 8.44733 3.36867C7.07693 3.66547 5.87325 4.42041 5.06741 5.57227C4.56536 6.37494 4.32404 7.19056 4.13436 8.11125C4.10652 8.22634 4.07867 8.34143 4.04999 8.46C3.84938 8.5603 3.73224 8.56483 3.50999 8.56688C3.41532 8.56879 3.41532 8.56879 3.31874 8.57074C3.14999 8.55 3.14999 8.55 2.96999 8.37C2.87627 6.83442 3.57315 5.32071 4.56151 4.18184C6.98132 1.60697 10.8261 1.3971 13.6374 3.50016Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_203_3">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
