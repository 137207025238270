import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  min-width: 250px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

const EmailInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #272727;
  outline: none;
  width: 100%;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 700;
`;

const AddButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 4px 24px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;
`;

const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface IEmailForm {
  onAdd?: (email: string) => void;
}

export default function EmailForm({ onAdd }: IEmailForm) {
  const [email, setEmail] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);

  const handleOnAdd = () => {
    setIsNotValid(false);
    const isValid = EMAIL_REGEX.test(email.toLowerCase());

    if (!isValid) {
      setIsNotValid(true);
      return;
    }

    onAdd?.(email);
  };

  return (
    <Container>
      <EmailInput placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {isNotValid ? <ErrorMessage>Not a valid email</ErrorMessage> : null}
      <AddButton onClick={handleOnAdd}>Add</AddButton>
    </Container>
  );
}
