import { IconProps } from '../types';

export default function ChevronDownIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill={fill} xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M7.5 9L15 1.71515L13.2367 0L7.5 5.57576L1.76456 0L0 1.71394L7.5 9Z" fill={fill} />
    </svg>
  );
}
