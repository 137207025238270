import { IconProps } from '../types';

export default function SemiCirleCheckIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg style={style} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.23307 26.9685C8.95587 23.7061 10.3142 20.6179 12.2306 17.8804C14.1469 15.143 16.5837 12.8098 19.4018 11.0141C22.2199 9.2184 25.3641 7.99541 28.6549 7.41494C31.9457 6.83447 35.3185 6.90789 38.5809 7.63101C39.4705 7.80815 40.3941 7.62988 41.1539 7.13439C41.9136 6.6389 42.4491 5.8656 42.6457 4.98011C42.8423 4.09461 42.6843 3.16735 42.2056 2.39692C41.7268 1.6265 40.9654 1.07417 40.0845 0.858256C33.8316 -0.526887 27.3083 -0.0268265 21.3396 2.2952C15.3709 4.61724 10.2247 8.65696 6.55183 13.9036C2.87891 19.1502 0.844182 25.3681 0.704925 31.7711C0.565668 38.174 2.32813 44.4745 5.76947 49.8759C9.21081 55.2772 14.1765 59.5369 20.0386 62.1162C25.9007 64.6955 32.396 65.4787 38.7032 64.3668C45.0104 63.2548 50.8463 60.2976 55.4729 55.869C60.0995 51.4405 63.3091 45.7396 64.6958 39.487C64.8952 38.5883 64.7294 37.6472 64.2349 36.8707C63.7404 36.0942 62.9576 35.546 62.0589 35.3466C61.1602 35.1472 60.219 35.313 59.4426 35.8075C58.6661 36.302 58.1178 37.0848 57.9184 37.9835C57.1952 41.2459 55.8364 44.3339 53.9198 47.0712C52.0031 49.8084 49.5661 52.1414 46.7478 53.9368C41.056 57.5629 34.157 58.7794 27.5683 57.3187C20.9796 55.858 15.241 51.8398 11.6149 46.1481C7.98888 40.4563 6.77239 33.5572 8.23307 26.9685Z"
        fill="#3A50D1"
      />
      <path
        d="M60.9728 17.5745C61.3137 17.2568 61.5871 16.8738 61.7768 16.4482C61.9664 16.0226 62.0684 15.5631 62.0766 15.0973C62.0848 14.6314 61.9991 14.1686 61.8246 13.7366C61.6501 13.3046 61.3904 12.9121 61.0609 12.5826C60.7314 12.2532 60.339 11.9934 59.9069 11.8189C59.4749 11.6444 59.0121 11.5587 58.5463 11.5669C58.0804 11.5752 57.6209 11.6771 57.1953 11.8668C56.7697 12.0564 56.3867 12.3298 56.069 12.6707L33.0768 35.6629L22.8067 25.3928C22.1489 24.7799 21.279 24.4462 20.3801 24.4621C19.4812 24.4779 18.6236 24.8421 17.9879 25.4778C17.3522 26.1135 16.9881 26.9711 16.9722 27.87C16.9564 28.7689 17.29 29.6388 17.9029 30.2965L30.6249 43.0186C31.2755 43.6683 32.1574 44.0333 33.0768 44.0333C33.9963 44.0333 34.8782 43.6683 35.5287 43.0186L60.9728 17.5745Z"
        fill="#30AF5B"
      />
    </svg>
  );
}
