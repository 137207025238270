import { IconProps } from '../types';

export default function CloudSyncIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.625 15.5625C65.922 19.9726 69.2984 25.3441 70.875 31.3125C72.4055 34.9687 74.6603 35.7563 78.0273 37.6445C82.0049 40.3776 83.5054 44.516 85 49C85.7833 55.5103 84.8496 60.6376 81 66C76.7216 70.672 72.3546 73.6033 65.864 74.1946C63.3488 74.2803 60.8409 74.3034 58.3242 74.3008C57.3996 74.305 56.475 74.3093 55.5224 74.3137C53.5717 74.3195 51.6209 74.32 49.6702 74.3157C46.7075 74.3125 43.7458 74.3359 40.7832 74.3613C38.8802 74.3636 36.9772 74.3643 35.0742 74.3633C34.1989 74.3725 33.3236 74.3817 32.4218 74.3912C25.309 74.3385 18.4686 73.1547 12.6875 68.75C7.64987 63.5952 4.93813 58.0543 4.6875 50.8125C4.79901 44.6389 6.58529 39.5087 10.9062 35.0195C12.8931 33.281 14.9212 31.6269 17 30C19.7503 27.564 21.4133 25.2958 23.1875 22.125C26.3048 16.9456 31.1722 13.2863 36.918 11.3554C46.0192 9.80158 53.7101 10.8512 61.625 15.5625ZM31.75 22.125C29.1522 24.8409 28.3998 26.187 27.125 29.5625C25.6873 33.2173 24.5918 34.6264 21 36.25C20.3078 36.5323 19.6155 36.8146 18.9023 37.1054C16.066 38.4391 14.6641 40.404 13 43C11.2645 48.2065 11.3366 53.1493 13.1875 58.3125C15.9856 62.4613 19.4333 65.0365 24 67C26.1274 67.3117 26.1274 67.3117 28.3821 67.2905C29.2401 67.3033 30.0981 67.316 30.9821 67.3292C32.3619 67.3325 32.3619 67.3325 33.7695 67.3359C34.7239 67.3426 35.6783 67.3492 36.6615 67.3561C38.6797 67.3662 40.6979 67.3709 42.7161 67.3706C45.7911 67.3749 48.8647 67.4113 51.9395 67.4492C53.9036 67.4551 55.8678 67.459 57.832 67.4609C58.7458 67.4753 59.6596 67.4897 60.601 67.5045C66.5928 67.462 70.0822 66.4721 75 63C78.2125 59.3729 78.527 55.9011 78.3477 51.2148C77.7898 47.6605 76.3889 45.6404 74 43C70.7228 40.9935 68.8476 40 65 40C64.902 39.2755 64.8041 38.5511 64.7031 37.8047C63.5853 30.5772 62.0434 25.9612 56.6875 20.75C47.9286 15.0465 39.9459 16.7661 31.75 22.125Z"
        fill={fill}
      />
      <path
        d="M31.5625 38.6875C35.016 40.5471 37.8005 42.7165 40 46C39.875 48.875 39.875 48.875 39 51C38.01 51.33 37.02 51.66 36 52C37.1823 54.7407 37.1823 54.7407 40 57C43.161 57.2885 46.1544 57.1856 49.3203 56.9805C52 57 52 57 54 59C53.625 61.625 53.625 61.625 53 64C47.6008 65.7997 42.0644 65.7011 36.6875 63.875C32.5838 61.0119 29.5892 58.0847 28.1875 53.125C28.0947 52.0731 28.0947 52.0731 28 51C26.35 51 24.7 51 23 51C22.0625 48.9375 22.0625 48.9375 22 46C24.4399 42.4306 27.1456 39.2262 31.5625 38.6875Z"
        fill={fill}
      />
      <path
        d="M51 31C55.3433 33.2946 58.2968 35.8952 61 40C61.75 42.8125 61.75 42.8125 62 45C63.65 45 65.3 45 67 45C67.9375 47.0625 67.9375 47.0625 68 50C66.0526 52.8488 63.8183 55.015 61 57C57.8748 57.3811 56.7863 56.5883 54.3242 54.7461C52.6279 53.2526 51.2584 51.8786 50 50C50.125 47.125 50.125 47.125 51 45C52.32 45 53.64 45 55 45C53.0889 41.3689 51.8667 39.45 48 38C45.1791 37.7592 45.1791 37.7592 42.25 37.875C41.2652 37.893 40.2803 37.9111 39.2656 37.9297C38.518 37.9529 37.7703 37.9761 37 38C36.67 36.35 36.34 34.7 36 33C41.2125 29.6586 45.1665 29.4604 51 31Z"
        fill={fill}
      />
    </svg>
  );
}
