import React, { useState } from 'react';

import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import ManageUsersHeader from '@app/components/apps/manage-users/ManageUsersRepositoryHeader';
import { useBlockUser, useDeleteUser, useReInviteUser } from '@app/api/user.api';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import { TUsers } from '@app/types/users';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';

const tableCols: TDataColumns<TUsers> = [
  {
    title: 'Email',
    dataIndex: 'email',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '25vw',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'UserRole',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Date Joined',
    dataIndex: 'creationDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    width: '14vw',
  },
  {
    title: 'Last Login Date',
    dataIndex: 'lastLoginDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    width: '14vw',
  },
];

const ManageUsersPage: React.FC = () => {
  const { mutateAsync: blockUser, isLoading: isBlocking } = useBlockUser();
  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { mutateAsync: reinviteUser, isLoading: isReinviteUserLoading } = useReInviteUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<TUsers | null>(null);
  const [action, setAction] = useState<'block' | 'delete' | 're-invite' | null>(null);
  const [message, setMessage] = useState('');

  const status = (status: 'Active' | 'Pending' | 'Blocked') => {
    if (status === 'Active') {
      return 'enabled';
    }

    if (status === 'Pending') {
      return 'pending';
    }

    return 'disabled';
  };

  const actionItems: TActionItems<TUsers>[] = [
    {
      label: 'Block',
      key: 'block',
      multiSelect: false,
      onClick: (item) => {
        setSelectedUser(item);
        setAction('block');
        setMessage('Are you sure you want to block this user?');
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) !== 'Deleted',
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: false,
      onClick: (item) => {
        setSelectedUser(item);
        setAction('delete');
        setMessage('Are you sure you want to delete this user?');
        setIsModalOpen(true);
      },
      show: (item) => (item.status as any) !== 'Deleted',
    },
    {
      label: 'Re-invite user',
      key: 'reInviteUser',
      multiSelect: false,
      onClick: (item) => {
        reinviteUser(item.email);
      },
      show: (item) => (item.status as any) === 'Pending',
    },
  ];

  const manageUsersCols: TDataColumns<TUsers> = [
    ...tableCols,
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'UserStatus',
      showSortDirections: true,
      width: '10vw',
      render: (val, record) => <StatusBadge type={status(record.status as any)}>{record.status}</StatusBadge>,
    },
  ];

  const dataTableProps = useDataTable<TUsers>({
    model: 'user',
    columns: manageUsersCols,
    searchableColumns: ['email'],
    defaultOrder: {
      column: 'email',
      order: 'asc',
    },
    actionItems,
  });

  const handleModalClose = () => {
    setSelectedUser(null);
    setAction(null);
    setMessage('');
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    if (action === 'block') {
      if (selectedUser) {
        await blockUser(selectedUser?.id);
        dataTableProps.refetch();
        handleModalClose();
      }
      return;
    }

    if (action === 'delete') {
      if (selectedUser) {
        await deleteUser(selectedUser?.id);
        dataTableProps.refetch();
        handleModalClose();
      }
      return;
    }

    if (action === 're-invite') {
      return;
    }
  };

  return (
    <>
      <PageTitle>Manage Users</PageTitle>
      <ManageUsersHeader onInviteSuccess={dataTableProps.refetch} />
      <DataTable {...dataTableProps} />
      <ActionConfirmationModal
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        isLoading={isBlocking || isDeleting}
      />
    </>
  );
};

export default ManageUsersPage;
