import { getBlobFileUrl } from '@app/api/azure-storage.api';
import { TReport } from '@app/types/reports';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #e9eaeb;
  border-radius: 10px;
  background-color: white;
  width: 304px;
`;

const Thumbnail = styled.img`
  height: 270px;
  width: 270px;
  object-fit: fill;
  margin: 0px 16px;
  margin-top: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const NoThumbnailContainer = styled.div`
  height: 270px;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
`;

const ReportName = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #272727;
  margin: 16px;
  margin-bottom: 0px;
`;

const Description = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: #272727;
  margin: 0 16px;
  margin-bottom: 16px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

type IReportCardProps = TReport;

export default function ReportCard({ id, name, description, thumbnailUrl }: IReportCardProps) {
  const blobUrl = !!thumbnailUrl ? getBlobFileUrl(thumbnailUrl) : undefined;
  return (
    <Container>
      {blobUrl ? <Thumbnail src={blobUrl} alt="" /> : <NoThumbnailContainer>No thumbnail</NoThumbnailContainer>}
      <ReportName>{name}</ReportName>
      <Description>{description}</Description>
    </Container>
  );
}
