import { IconProps } from '../types';

export default function WarningIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.15" cx="22.998" cy="23" r="23" fill={fill} />
      <path
        d="M33.2727 27.1966L24.6208 12.8481C24.2799 12.2828 23.6581 11.9316 22.998 11.9316C22.3379 11.9316 21.7162 12.2828 21.3752 12.8481L12.7234 27.1966C12.3711 27.7809 12.3607 28.5128 12.6961 29.1069C13.0317 29.701 13.6639 30.07 14.3461 30.07H31.65C32.3322 30.07 32.9644 29.701 33.3 29.1069C33.6354 28.5128 33.625 27.7808 33.2727 27.1966ZM32.1042 28.4316C32.0587 28.512 31.9927 28.5789 31.9129 28.6255C31.8331 28.6721 31.7423 28.6967 31.65 28.6967H14.3461C14.2537 28.6967 14.163 28.6721 14.0832 28.6255C14.0034 28.5789 13.9374 28.512 13.8919 28.4316C13.8466 28.3512 13.8233 28.2601 13.8247 28.1677C13.826 28.0753 13.8518 27.9849 13.8994 27.9058L22.5513 13.5572C22.598 13.4804 22.6635 13.4169 22.7418 13.3727C22.82 13.3286 22.9082 13.3052 22.9981 13.3049C23.1798 13.3049 23.3509 13.4016 23.4448 13.5572L32.0966 27.9058C32.1443 27.9849 32.1701 28.0753 32.1714 28.1677C32.1728 28.26 32.1496 28.3511 32.1042 28.4316Z"
        fill={fill}
      />
      <path
        d="M23.0044 17.582C22.482 17.582 22.0743 17.8623 22.0743 18.3593C22.0743 19.8754 22.2527 22.054 22.2527 23.5702C22.2527 23.9652 22.5967 24.1308 23.0044 24.1308C23.3102 24.1308 23.7434 23.9652 23.7434 23.5702C23.7434 22.0541 23.9217 19.8754 23.9217 18.3593C23.9217 17.8624 23.5013 17.582 23.0044 17.582ZM23.0172 25.0099C22.4566 25.0099 22.0361 25.4558 22.0361 25.9909C22.0361 26.5133 22.4566 26.972 23.0172 26.972C23.5396 26.972 23.9855 26.5133 23.9855 25.9909C23.9855 25.4558 23.5395 25.0099 23.0172 25.0099Z"
        fill={fill}
      />
    </svg>
  );
}
