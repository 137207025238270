import { ButtonProps as AntButtonProps } from 'antd';
import { forwardRef } from 'react';
import * as S from './StyledButton.styled';

export interface BaseButtonProps extends AntButtonProps {
  color?: 'default' | 'danger' | 'success' | 'dark';
  variant?: 'default' | 'ghost' | 'outlined' | 'light';
  btnSize?: 'default' | 'sm' | 'md' | 'lg';
}

export const StyledButton = forwardRef<HTMLElement, BaseButtonProps>(
  ({ color = 'default', variant = 'default', btnSize, children, ...props }, ref) => (
    <S.StyledButton ref={ref} {...props} $btnSize={btnSize} $variant={variant} $color={color}>
      {children}
    </S.StyledButton>
  ),
);
