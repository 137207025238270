import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useState } from 'react';
import { useEmailGroupInput } from '../manage-users/EmailGroupInput/useEmailGroupInput';
import styled from 'styled-components';
import { CenterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { SemiCirleCheckIcon } from '@app/components/icons';
import EmailGroupInput from '../manage-users/EmailGroupInput/EmailGroupInput';
import { useInviteAccount } from '@app/api/user.api';
import Spin from 'antd/lib/spin';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const Message = styled.span`
  font-size: 32px;
  font-weight: 800;
  line-height: 50px;
  color: #272727;
  text-align: center;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={() => onCancel?.()}>Cancel</CancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Invite
      </ApplyButton>
    </FooterContainer>
  );
};

interface IInviteAccountModalProps extends ModalFuncProps {
  isLoading?: boolean;
  onInviteSuccess?: () => void;
}

export default function InviteAccountModal({ isLoading, onInviteSuccess, ...modalProps }: IInviteAccountModalProps) {
  const { mutateAsync: inviteAccount, isLoading: isInviteAccountLoading } = useInviteAccount();
  const [isSuccess, setIsSuccess] = useState(false);

  const emailGroupInputProps = useEmailGroupInput();

  const handleInviteUsers = async () => {
    const email = emailGroupInputProps.emails[0];
    const response = await inviteAccount(email);
    if (response) {
      setIsSuccess(true);
      onInviteSuccess?.();
    }
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    emailGroupInputProps.clearEmails();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={!isInviteAccountLoading && isSuccess ? null : 'Invite Account'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isInviteAccountLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : isSuccess ? (
          <FooterContainer>
            <ApplyButton onClick={handleCloseModal}>Continue</ApplyButton>
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={handleInviteUsers}
            onApplyDisabled={emailGroupInputProps.emails.length === 0}
          />
        )
      }
    >
      {isSuccess ? (
        <CenterContainer
          style={{
            gap: '16px',
            marginTop: '60px',
          }}
        >
          <SemiCirleCheckIcon />
          <Message>Accounts Are Invited!</Message>
        </CenterContainer>
      ) : (
        <Container>
          <FieldContainer>
            <FieldLabel>User Email Addresses:</FieldLabel>
            <EmailGroupInput {...emailGroupInputProps} single />
          </FieldContainer>
        </Container>
      )}
    </Modal>
  );
}
