import { IconProps } from '../types';

export default function ConnectorsIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg style={style} width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.15" cx="22.998" cy="23.4922" r="23" fill={fill} />
      <path
        d="M33.7366 12.9072C33.4437 12.6143 32.9688 12.6143 32.676 12.9072L30.0966 15.4865C27.8929 13.803 24.7201 13.9678 22.7065 15.9814L20.1811 18.5068C19.8882 18.7997 19.8882 19.2746 20.1811 19.5674L27.0764 26.4627C27.146 26.5324 27.2287 26.5876 27.3197 26.6253C27.4107 26.663 27.5082 26.6824 27.6067 26.6824C27.7052 26.6824 27.8027 26.663 27.8937 26.6253C27.9847 26.5876 28.0673 26.5324 28.137 26.4627L30.6624 23.9373C32.676 21.9237 32.8408 18.7509 31.1572 16.5472L33.7366 13.9678C34.0295 13.6749 34.0295 13.2001 33.7366 12.9072ZM29.6018 22.8767L27.6067 24.8718L21.7721 19.0371L23.7671 17.042C25.3758 15.4335 27.9932 15.4335 29.6018 17.042C31.2103 18.6506 31.2103 21.2681 29.6018 22.8767Z"
        fill={fill}
      />
      <path
        d="M25.1362 23.9626L23.7487 25.3497L20.9884 22.5894L22.3758 21.2022C22.6688 20.9093 22.6688 20.4344 22.3758 20.1416C22.083 19.8486 21.6081 19.8486 21.3152 20.1416L19.9277 21.5288L18.9209 20.522C18.628 20.2291 18.1531 20.229 17.8603 20.522L15.3349 23.0473C13.3213 25.0609 13.1565 28.2337 14.8401 30.4374L12.2607 33.0169C11.9678 33.3097 11.9678 33.7846 12.2607 34.0775C12.4071 34.2239 12.5991 34.2972 12.791 34.2972C12.983 34.2972 13.1749 34.2239 13.3213 34.0775L15.9006 31.4981C16.9041 32.2647 18.1084 32.6483 19.3129 32.6483C20.7535 32.6483 22.1941 32.0999 23.2908 31.0032L25.8162 28.4779C25.8859 28.4082 25.9411 28.3256 25.9788 28.2346C26.0165 28.1436 26.0359 28.046 26.0359 27.9476C26.0359 27.8491 26.0165 27.7515 25.9788 27.6606C25.9411 27.5696 25.8859 27.4869 25.8162 27.4173L24.8094 26.4104L26.1968 25.0233C26.4897 24.7304 26.4897 24.2555 26.1968 23.9626C25.9039 23.6697 25.429 23.6697 25.1362 23.9626ZM22.2302 29.9426C20.6216 31.5512 18.0043 31.5512 16.3956 29.9426C14.787 28.334 14.787 25.7166 16.3956 24.108L18.3906 22.1129L24.2252 27.9476L22.2302 29.9426Z"
        fill={fill}
      />
    </svg>
  );
}
