import { ChangeEvent } from 'react';
import * as S from './IntervalDaySelect.styled';

interface IntervalDaySelectProps {
  value: number;
  onChange: (value: number) => void;
}

const IntervalDaySelect = ({ value, onChange }: IntervalDaySelectProps) => {
  const handleIncrease = () => {
    onChange(value + 1);
  };

  const handleDecrease = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <S.Container>
      <S.Button>Days</S.Button>
      <S.NumberInputWrapper>
        <S.Input
          type="number"
          defaultValue={0}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(parseInt(e.target.value))}
        />
        <S.DropdownIcon>
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleIncrease}
          >
            <path
              d="M5 0.107421L9.99622e-08 4.96399L1.17554 6.10742L5 2.39025L8.82363 6.10742L10 4.9648L5 0.107421Z"
              fill="#272727"
            />
          </svg>
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleDecrease}
          >
            <path
              d="M5 6.10742L10 1.25086L8.82446 0.107422L5 3.82459L1.17637 0.107422L0 1.25005L5 6.10742Z"
              fill="#272727"
            />
          </svg>
        </S.DropdownIcon>
      </S.NumberInputWrapper>
    </S.Container>
  );
};

export default IntervalDaySelect;
