import { TAccountRegulations } from '@app/types/accountRegulations';
import { Card } from 'antd';
import styled, { keyframes, css } from 'styled-components';
import React, { useState, useEffect } from 'react';

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  cursor: pointer;
  transition: all ease 200ms;
  min-height: 200px;
  margin-right: 6px;

  &:hover {
    transform: scale(1.02);
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const ComplianceName = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 36px;
  font-size: 14px;
  color: gray;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const progressAnimation = (percentage: number) => keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${percentage}%;
  }
`;

const ComplianceProgress = styled.div<{
  percentage: number;
  strokeColor: string;
  numOfCompliant: number;
  total: number;
  animate: boolean;
}>`
  position: relative;
  width: 100%;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 2px;
  margin-top: 8px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: ${({ animate, percentage }) => (animate ? '0%' : `${percentage}%`)};
    height: 100%;
    background-color: ${({ strokeColor }) => strokeColor};
    border-radius: 2px;
    ${({ animate, percentage }) =>
      animate &&
      css`
        animation: ${progressAnimation(percentage)} 2s ease-out forwards;
      `}
  }

  &::after {
    content: '${({ numOfCompliant, total, percentage }) =>
      `${numOfCompliant} out of ${total} compliant (${percentage}%)`}';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

interface ComplianceCardProps extends TAccountRegulations {
  onCardClick: () => void;
}

const ComplianceCard = ({
  id,
  accountId,
  name,
  description,
  status,
  numOfCompliant,
  numOfNonCompliant,
  onCardClick,
}: ComplianceCardProps) => {
  const percentage = Math.round((numOfCompliant * 100) / (numOfCompliant + numOfNonCompliant));
  const validPercentageScore = isNaN(percentage) ? 0 : percentage;
  const strokeColor =
    validPercentageScore <= 20
      ? 'red'
      : validPercentageScore > 20 && validPercentageScore <= 60
      ? 'darkorange'
      : validPercentageScore > 60 && validPercentageScore < 100
      ? 'orange'
      : 'green';
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <CardContainer key={id} onClick={onCardClick}>
      <ComplianceName>{name}</ComplianceName>
      <Description>{description}</Description>
      <ComplianceProgress
        percentage={validPercentageScore}
        strokeColor={strokeColor}
        numOfCompliant={numOfCompliant}
        total={numOfCompliant + numOfNonCompliant}
        animate={animate}
      />
    </CardContainer>
  );
};

export default ComplianceCard;
