import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { getEnumValue } from '@app/services/enum.service';
import { LogoIcon } from '@app/components/icons';

const enabledStatusFilter = [
  {
    column: 'status',
    enumKey: 'AccountStatus',
    value: JSON.stringify([1]),
    type: 'enum',
    title: 'Status',
  },
];

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly, desktopOnly } = useResponsive();
  const userRole: number = useSelector<RootState>((state) => state.user.user?.role) as number;

  const logoLink =
    !!userRole && [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(userRole)
      ? `/manage-accounts?$added=${JSON.stringify(enabledStatusFilter)}`
      : '/dashboard';

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={logoLink}>
        {isSiderCollapsed ? <LogoIcon fill="#272727" /> : <img src={logo} width={'75%'} />}
      </S.SiderLogoLink>
      {(tabletOnly || desktopOnly) && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
