import { useMemo, useState } from 'react';
import { Radio, RadioChangeEvent, Select } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { TrendByProduct } from '@app/types/dashboardData';
import { format } from 'date-fns';
import { TimeFrameOptions, camelCaseToWords } from '@app/utils/utils';
import { NoData } from '../common/NoData';

interface IBarChartCardProps {
  onSelectDateRangeCallback?: (value: TimeFrameOptions) => void;
  data?: TrendByProduct;
}

const colors = ['#3B50D0', '#FFB156', '#30AE5B', '#D4DAFF'];

export default function BarChartCard({ data, onSelectDateRangeCallback }: IBarChartCardProps) {
  const [radioValue, setRadioValue] = useState(1);

  const products = useMemo(() => {
    if (data) {
      const keys: Record<string, boolean> = {};
      for (const sc of data.standardScore) {
        for (const product in sc.products) {
          keys[product] = true;
        }
      }
      return Object.keys(keys).sort();
    }
    return [];
  }, [data]);

  const barChartData = useMemo(() => {
    if (data) {
      if (radioValue === 1) {
        return data.standardScore.map((sc) => {
          const productKeys = Object.keys(sc.products).map((key) => {
            return {
              [key]: sc.products[key],
            };
          });

          return {
            name: format(new Date(sc.date), 'MMM dd'),
            ...Object.assign({}, ...productKeys),
          };
        });
      } else {
        return data.customScore.map((cs) => {
          const productKeys = Object.keys(cs.products).map((key) => {
            return {
              [key]: cs.products[key],
            };
          });

          return {
            name: format(cs.date, 'MMM dd'),
            ...Object.assign({}, ...productKeys),
          };
        });
      }
    }

    return [];
  }, [data, products, radioValue]);

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div style={{ display: 'flex', gap: '20px', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        {payload.map((entry: any, index: number) => (
          <div
            key={`item-${index}`}
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              fontSize: '0.75rem',
              fontWeight: 500,
              color: '#303030',
            }}
          >
            <div style={{ width: '15px', height: '15px', background: entry.color, borderRadius: '4px' }} />
            {camelCaseToWords(entry.dataKey).trim()}
          </div>
        ))}
      </div>
    );
  };

  const handleChange = (value: TimeFrameOptions) => {
    onSelectDateRangeCallback?.(value);
  };

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  return (
    <div style={{ backgroundColor: 'white', padding: '16px', border: '1px solid #E9EAEB', borderRadius: '9px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '2lh',
          marginBottom: '8px',
        }}
      >
        <span style={{ fontSize: '1rem', fontWeight: 700 }}>Trend By Product Over Time</span>
        <div style={{ display: 'flex', fontSize: '.2rem' }}>
          <Radio.Group onChange={onChange} value={radioValue} size="small">
            <Radio value={1} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Baseline Security Score
            </Radio>
            <Radio value={2} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Custom Security Score
            </Radio>
          </Radio.Group>
        </div>
        <Select
          defaultValue="last30days"
          style={{ width: 110, fontSize: '10px', padding: '0px' }}
          size="small"
          onChange={handleChange}
          options={[
            { value: 'last30days', label: 'Last 30 Days' },
            { value: 'last90days', label: 'Last 90 Days' },
            { value: 'last12months', label: 'Last 12 Months' },
            { value: 'alltime', label: 'All Time' },
          ]}
        />
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        {!barChartData || barChartData.length === 0 ? (
          <NoData />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={barChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barGap={0}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" color="#303030" dy={10} />
              <YAxis type="number" tickFormatter={(tick) => `${tick}%`} />
              <Legend iconType="square" content={renderLegend} />
              {products.map((product, index: number) => (
                <Bar
                  key={index}
                  dataKey={product}
                  fill={colors[index % colors.length]}
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                  radius={[10, 10, 0, 0]}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
