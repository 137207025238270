import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid #acacac;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: black;
`;

const ImagePreview = styled.img`
  height: 50px;
  width: 70px;
  object-fit: fill;
  margin-right: 12px;
`;

interface IFilePickerProps<T> {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  isDragActive: boolean;
  label?: string;
  acceptedFiles?: File[];
  imgPreview?: string;
}

export default function FilePicker<T>({
  isDragActive,
  label,
  imgPreview,
  getInputProps,
  getRootProps,
}: IFilePickerProps<T>) {
  return (
    <Container {...getRootProps()}>
      {imgPreview && <ImagePreview src={imgPreview} alt={imgPreview} />}
      <input {...getInputProps()} />
      {isDragActive ? (
        <Label>Drop the files here ...</Label>
      ) : (
        <Label>{label || 'Drag and drop some files here, or click to select files'}</Label>
      )}
    </Container>
  );
}
