import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Card, Col, Row, Popover } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { PieChartCard } from '@app/components/dashboard/PieChartCard';
import LineChartCard from '@app/components/dashboard/LineChartCard';
import BarChartCard from '@app/components/dashboard/BarChartCard';
import { RemediatedTable } from '@app/components/dashboard/Remediated';
import { AlertItem } from '@app/components/dashboard/AlertItem';
import { InsightItem } from '@app/components/dashboard/InsightItem';
import { NewsCard } from '@app/components/dashboard/NewsCard';
import { useGetDashboardData, useGetTrendByProduct, useGetTrendOverTime } from '@app/api/statistics.api';
import { TimeFrameOptions, getRandomColor, getTimeFrameFromOption } from '@app/utils/utils';
import GeneralWidgetTooltip from '@app/components/dashboard/GeneralWidgetTooltip';
import { TWidgetData } from '@app/types/widget';
import { TFilterValue } from '@app/components/tables/AntdTableWrapper/types';
import { getEnumValue } from '@app/services/enum.service';
import { getProductLabel } from '@app/utils/utils';

import { useNavigate } from 'react-router-dom';
import { useGetModelItems } from '@app/api/table.api';
import { TInsight } from '@app/types/insight';
import { format } from 'date-fns';

const remediatedFilters = [
  {
    column: 'snapshotType',
    value: JSON.stringify(['3']),
    type: 'enum',
    title: 'Change Type',
  },
];

const HomePage: React.FC = () => {
  const [trendByProductDate, setTrendByProductDate] = useState(getTimeFrameFromOption('last30days'));
  const [trendOverTimeDate, setTrendOverTimeDate] = useState(getTimeFrameFromOption('last30days'));

  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { data: dashboardData } = useGetDashboardData();
  const { data: trendOverTime, refetch: refetchTrendOverTime } = useGetTrendOverTime(
    trendOverTimeDate.from,
    trendOverTimeDate.to,
  );
  const { data: trendByProduct, refetch: refetchTrendByProduct } = useGetTrendByProduct(
    trendByProductDate.from,
    trendByProductDate.to,
  );

  const { data: insights } = useGetModelItems<TInsight>({
    model: 'Insight',
    queryParams: `$filter=(status eq 1)&orderby=priority asc&$top=3`,
  });

  const { Title } = Typography;

  const winStatus: TWidgetData = useMemo(() => {
    if (dashboardData?.byStatus) {
      const compliant = dashboardData?.byStatus?.compliant || 0;
      const nonCompliant = dashboardData?.byStatus?.nonCompliant || 0;
      const data = [
        {
          name: 'Compliant',
          value: compliant,
          color: '#30AE5B',
          appliedFilter: {
            column: 'isCompliant',
            value: 'true',
            type: 'boolean',
            title: 'Is Compliant',
          } as TFilterValue,
        },
        {
          name: 'Non-Compliant',
          value: Math.abs(nonCompliant),
          color: '#D81D2D',
          appliedFilter: {
            column: 'isCompliant',
            value: 'false',
            type: 'boolean',
            title: 'Is Compliant',
          } as TFilterValue,
        },
      ];

      const totalRecommendations = compliant + nonCompliant;
      // calculate the percentage of compliant recommendations
      const scoreValue = totalRecommendations === 0 ? 100 : Math.floor((100 * compliant) / totalRecommendations);
      return {
        data,
        score: scoreValue + '%',
      };
    }

    return {
      score: '',
      data: [],
    };
  }, [dashboardData]);

  const winsRisk: TWidgetData = useMemo(() => {
    if (dashboardData?.quickWins?.byRisk) {
      return {
        score: Object.values(dashboardData.quickWins.byRisk).reduce((acc, curr) => (acc += curr), 0),
        data: [
          {
            name: 'High Risk',
            value: dashboardData.quickWins.byRisk['3'],
            color: '#D81D2D',
            appliedFilter: {
              column: 'severity',
              value: JSON.stringify([getEnumValue('RecommendationSeverity', 'High')]),
              type: 'enum',
              enumKey: 'RecommendationSeverity',
              title: 'Severity',
            },
          },
          {
            name: 'Medium Risk',
            value: dashboardData.quickWins.byRisk['2'],
            color: '#FFB156',
            appliedFilter: {
              column: 'severity',
              value: JSON.stringify([getEnumValue('RecommendationSeverity', 'Moderate')]),
              type: 'enum',
              enumKey: 'RecommendationSeverity',
              title: 'Severity',
            },
          },
          {
            name: 'Low Risk',
            value: dashboardData.quickWins.byRisk['1'],
            color: '#30AE5B',
            appliedFilter: {
              column: 'severity',
              value: JSON.stringify([getEnumValue('RecommendationSeverity', 'Low')]),
              type: 'enum',
              enumKey: 'RecommendationSeverity',
              title: 'Severity',
            },
          },
        ],
      };
    }
    return { score: 0, data: [] };
  }, [dashboardData]);

  const winsProduct: TWidgetData = useMemo(() => {
    if (dashboardData?.quickWins?.byProduct) {
      const byProduct = dashboardData.quickWins.byProduct;
      return {
        score: Object.values(byProduct).reduce((acc, curr) => (acc += curr), 0),
        data: Object.keys(byProduct).map((key) => ({
          name: getProductLabel(key),
          value: byProduct[key],
          color: getRandomColor(),
          appliedFilter: {
            column: 'productName',
            value: getProductLabel(key),
            type: 'text',
            title: 'Product',
          },
        })),
      };
    }
    return {
      data: [],
      score: 0,
    };
  }, [dashboardData]);

  const recomendProduct: TWidgetData = useMemo(() => {
    if (dashboardData?.recommendations?.byProduct) {
      const byProduct = dashboardData.recommendations.byProduct;
      return {
        score: Object.values(byProduct).reduce((acc, curr) => (acc += curr), 0),
        data: Object.keys(byProduct).map((key) => ({
          name: getProductLabel(key),
          value: byProduct[key],
          color: getRandomColor(),
          appliedFilter: {
            column: 'productName',
            value: getProductLabel(key),
            type: 'text',
            title: 'Product',
          },
        })),
      };
    }
    return { data: [], score: 0 };
  }, [dashboardData]);

  const latestAlerts = useMemo(() => {
    if (dashboardData?.latestAlerts) {
      return dashboardData.latestAlerts;
    }

    return [];
  }, [dashboardData]);

  const recentlyRemediated = useMemo(() => {
    if (dashboardData) {
      return dashboardData.recentlyRemediated;
    }

    return [];
  }, [dashboardData]);

  const handleTrendOverTimeDateChange = (value: TimeFrameOptions) => {
    setTrendOverTimeDate(getTimeFrameFromOption(value));
  };

  const handleTrendByProductDateChange = (value: TimeFrameOptions) => {
    setTrendByProductDate(getTimeFrameFromOption(value));
  };

  useEffect(() => {
    refetchTrendByProduct();
  }, [trendByProductDate]);

  useEffect(() => {
    refetchTrendOverTime();
  }, [trendOverTimeDate]);

  return (
    <>
      <PageTitle>Home</PageTitle>

      <div
        style={{
          padding: '2rem 1.5rem',
        }}
      >
        <Title level={3} style={{ fontWeight: 'bold' }}>
          Dashboard
        </Title>

        <Row gutter={[10, 0]}>
          <Col span={6}>
            <Popover
              content={<GeneralWidgetTooltip drillDownType="status" data={winStatus.data} score={winStatus.score} />}
              placement="bottom"
            >
              <Card bodyStyle={{ height: '3.5lh', padding: '0px', display: 'flex', alignItems: 'center' }}>
                <PieChartCard data={winStatus.data} score={winStatus.score} title={'Compliance State'} />
              </Card>
            </Popover>
          </Col>
          <Col span={6}>
            <Popover
              content={<GeneralWidgetTooltip drillDownType="risk" data={winsRisk.data} score={winsRisk.score} />}
              placement="bottom"
            >
              <Card bodyStyle={{ height: '3.5lh', padding: '0px', display: 'flex', alignItems: 'center' }}>
                <PieChartCard data={winsRisk.data} score={winsRisk.score} title={'Quick Wins By Risk'} />
              </Card>
            </Popover>
          </Col>
          <Col span={6}>
            <Popover
              content={
                <GeneralWidgetTooltip drillDownType="product" data={winsProduct.data} score={winsProduct.score} />
              }
              placement="bottom"
            >
              <Card bodyStyle={{ height: '3.5lh', padding: '0px', display: 'flex', alignItems: 'center' }}>
                <PieChartCard
                  data={winsProduct.data}
                  score={winsProduct.score}
                  title={'Quick Wins By Product'}
                  hideLegend
                />
              </Card>
            </Popover>
          </Col>
          <Col span={6}>
            <Popover
              content={
                <GeneralWidgetTooltip
                  drillDownType="product-recommendations"
                  data={recomendProduct.data}
                  score={recomendProduct.score}
                />
              }
              placement="bottom"
            >
              <Card bodyStyle={{ height: '3.5lh', padding: '0px', display: 'flex', alignItems: 'center' }}>
                <PieChartCard
                  data={recomendProduct.data}
                  score={recomendProduct.score}
                  title={'Recomendation By Product'}
                  hideLegend
                />
              </Card>
            </Popover>
          </Col>
        </Row>
        {/* 3 cols grid Cards Alerts */}
        <Row gutter={[10, 0]} style={{ marginTop: '20px' }} align={'stretch'}>
          <Col span={8}>
            <NewsCard
              title={'Latest Alerts'}
              onViewAllClicked={() => {
                navigate('/alerts-history');
              }}
            >
              {latestAlerts.length ? (
                latestAlerts.map((alert) => (
                  <AlertItem
                    key={alert.historyId}
                    id={alert.historyId}
                    title={alert.alertName}
                    description={`Alert was triggered at ${format(alert.triggerTime, 'd/M/yyyy HH:mm')}`}
                    color={'#FFB155'}
                  />
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '220px',
                  }}
                >
                  No alerts triggered so far
                </div>
              )}
            </NewsCard>
          </Col>
          <Col span={8}>
            <NewsCard
              title={'Recently Remediated'}
              onViewAllClicked={() => navigate(`/recommendation-history?$added=${JSON.stringify(remediatedFilters)}`)}
            >
              <RemediatedTable data={recentlyRemediated} />
            </NewsCard>
          </Col>
          <Col span={8}>
            <NewsCard title={'Top Insights'} onViewAllClicked={() => navigate(`/insights`)}>
              {insights &&
                insights.count > 0 &&
                insights.items.map((insight) => <InsightItem key={insight.id} {...insight} icon={<BulbOutlined />} />)}
            </NewsCard>
          </Col>
        </Row>
        {/* 2 grid line chart and Bar Chart */}
        <Row gutter={[20, 0]} style={{ marginTop: '20px' }}>
          <Col span={12}>
            <LineChartCard data={trendOverTime} onSelectDateRangeCallback={handleTrendOverTimeDateChange} />
          </Col>
          <Col span={12}>
            <BarChartCard data={trendByProduct} onSelectDateRangeCallback={handleTrendByProductDateChange} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HomePage;
