import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TInsight } from '@app/types/insight';
import useInsightsForm from '@app/components/apps/manage-insights/useInsightsForm';
import InsightsForm from '@app/components/apps/manage-insights/InsightsForm';
import { useCreateInsight, useDeleteInsight, useUpdateInsight } from '@app/api/insights.api';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';

const manageInsightsTableCols: TDataColumns<TInsight> = [
  {
    title: 'Creation Time',
    dataIndex: 'creationTime',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'InsightStatus',
    showSortDirections: true,
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    allowFiltering: true,
    type: 'number',
    showSortDirections: true,
  },
];

interface IInsightsManagementPageProps {
  title?: string;
}

export default function InsightsManagementPage(props: IInsightsManagementPageProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<TInsight | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createInsight, isLoading: isCreating } = useCreateInsight();
  const { mutateAsync: updateInsight, isLoading: isUpdating } = useUpdateInsight();
  const { mutateAsync: deleteInsight, isLoading: isDeleting } = useDeleteInsight();
  const { toggle, ...insightsFormProps } = useInsightsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedInsight) {
        await updateInsight({
          id: selectedInsight.id,
          data: data,
        });
      } else {
        await createInsight(data);
      }

      setIsEdit(false);
      setSelectedInsight(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedInsight(undefined);
    },
    insight: selectedInsight,
  });

  // const items: ActionDropdownProps<TInsight>['items'] = [
  //   {
  //     label: 'Edit Insight',
  //     key: 'edit',
  //     onClick: (rec) => {
  //       setIsEdit(true);
  //       setSelectedInsight(rec);
  //       toggle();
  //     },
  //   },
  //   {
  //     label: 'Delete Insight',
  //     key: 'delete',
  //     onClick: (rec) => {
  //       setSelectedInsight(rec);
  //       setIsConfirmationModalOpen(true);
  //     },
  //   },
  // ];

  const actionItems: TActionItems<TInsight>[] = [
    // {
    //   label: 'Edit Insight',
    //   key: 'edit',
    //   multiSelect: false,
    //   onClick: (rec) => {
    //     setIsEdit(true);
    //     setSelectedInsight(rec);
    //     toggle();
    //   },
    // },
    {
      label: 'Delete Insight',
      key: 'delete',
      multiSelect: false,
      onClick: (rec) => {
        setSelectedInsight(rec);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  // const tableCols: TDataColumns<any> = [
  //   ...manageInsightsTableCols,
  //   {
  //     title: 'Actions',
  //     key: 'action',
  //     align: 'center' as const,
  //     width: 100,
  //     render: (_, record) => {
  //       return <ActionDropdown items={items} record={record} />;
  //     },
  //   },
  // ];

  const dataTableProps = useDataTable<TInsight>({
    model: 'insight',
    columns: manageInsightsTableCols,
    searchableColumns: ['name'],
    constantFilter: '(status eq 1 or status eq 2)',
    defaultOrder: {
      column: 'creationTime',
      order: 'desc',
    },
    actionItems,
    onRowClick: (data, index) => {
      setIsEdit(true);
      setSelectedInsight(data);
      toggle();
    },
  });

  const handleModalClose = () => {
    setSelectedInsight(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (!!selectedInsight) {
      await deleteInsight(selectedInsight.id);
      handleModalClose();
      dataTableProps.refetch();
    }
  };

  return (
    <>
      <PageTitle>Manage Insights</PageTitle>
      <ComponentHeader
        title={t('common.manageInsights')}
        actionButtons={
          <>
            <CreateAlertButton title="Create Insight" onPress={toggle} />
          </>
        }
      />
      <DataTable {...dataTableProps} />
      <InsightsForm {...insightsFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this insight?"
        isLoading={isDeleting}
      />
    </>
  );
}
