import { httpApi } from './http.api';
import { useQuery } from 'react-query';
import { TDashboardData, TrendByProduct, TrendOverTime } from '@app/types/dashboardData';

export interface Statistic {
  id: number;
  value: number;
  prevValue: number;
  unit: 'kg';
}

export const getStatistics = (): Promise<Statistic[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          id: 1,
          value: 45,
          prevValue: 30,
          unit: 'kg',
        },
        {
          id: 2,
          value: 12,
          prevValue: 20,
          unit: 'kg',
        },
        {
          id: 3,
          value: 90,
          prevValue: 60,
          unit: 'kg',
        },
        {
          id: 4,
          value: 78,
          prevValue: 90,
          unit: 'kg',
        },
      ]);
    }, 0);
  });
};

const getTrendByProduct = async (from: Date, to: Date): Promise<TrendByProduct | undefined> => {
  const response = await httpApi.get<TrendByProduct>(
    `/statistics/trendByProduct?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
  return response.data;
};

export const useGetTrendByProduct = (from: Date, to: Date) =>
  useQuery({
    queryFn: () => getTrendByProduct(from, to),
    queryKey: 'trend-by-product',
  });

const getTrendOverTime = async (from: Date, to: Date): Promise<TrendOverTime | undefined> => {
  const response = await httpApi.get<TrendOverTime>(
    `/statistics/trendOverTime?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
  return response.data;
};

export const useGetTrendOverTime = (from: Date, to: Date) =>
  useQuery({
    queryFn: () => getTrendOverTime(from, to),
    queryKey: 'trend-over-time',
  });

const getDashboardData = async (): Promise<TDashboardData | undefined> => {
  const response = await httpApi.get<TDashboardData>(`/statistics/dashboard`);
  return response.data;
};

export const useGetDashboardData = () =>
  useQuery({
    queryFn: () => getDashboardData(),
    queryKey: 'dashboard-data',
  });
