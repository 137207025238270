import useToggle from '@app/hooks/useToggle';
import { TRegulationsForm } from './RegulationsForm';
import { TRegulations } from '@app/types/regulations';

interface IUseRegulationsFormProps {
  onCancel?: () => void;
  onOk?: (data: TRegulationsForm) => void;
  regulation?: TRegulations;
}

export default function useRegulationsForm(props?: IUseRegulationsFormProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TRegulationsForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    regulation: props?.regulation,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
