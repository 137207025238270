import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { useLogout } from '@app/api/auth.api';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const { mutateAsync: logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <div {...props}>
      {/* <S.Text>
        <Link to="/profile">{t('profile.title')}</Link>
      </S.Text>
      <S.ItemsDivider /> */}
      <S.Text
        onClick={handleLogout}
        style={{
          cursor: 'pointer',
        }}
      >
        {t('header.logout')}
      </S.Text>
    </div>
  );
};
