import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useForm } from 'react-hook-form';
import { useGetAccount, useUpdateAccount } from '@app/api/account.api';

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
  width: 350px;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
  margin: 2px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const UpdateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100px;
`;

export type TPropertiesForm = {
  microsoftApplicationId: string;
  microsoftApplicationSecret: string;
};

const PropertiesForm = () => {
  const { mutateAsync: updateAccount } = useUpdateAccount();
  const { data: account } = useGetAccount();
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TPropertiesForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    reset({
      microsoftApplicationId: account?.microsoftApplicationId,
      microsoftApplicationSecret: account?.microsoftApplicationSecret,
    });
  }, [account]);

  const onSubmit = async (data: TPropertiesForm) => {
    try {
      if (account) {
        const response = await updateAccount({ id: account.id, data });
      }
    } catch (err) {
      setError((err as any)?.data?.message);
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <HorizontalAlignedContainer>
        <FieldLabel>Microsoft Application ID:</FieldLabel>
        <InputContainer>
          <FieldInput
            hasError={!!errors.microsoftApplicationId}
            {...register('microsoftApplicationId', { required: true })}
          />
        </InputContainer>
      </HorizontalAlignedContainer>
      <HorizontalAlignedContainer>
        <FieldLabel>Microsoft Application Secret:</FieldLabel>
        <InputContainer>
          <FieldInput
            type="password"
            hasError={!!errors.microsoftApplicationSecret}
            {...register('microsoftApplicationSecret', { required: true })}
          />
        </InputContainer>
      </HorizontalAlignedContainer>
      <UpdateButton>Update</UpdateButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export { PropertiesForm };
