import { BlobServiceClient } from '@azure/storage-blob';

const account = process.env.REACT_APP_STORAGE_ACCOUNT;
const containerName = process.env.REACT_APP_STORAGE_CONTAINER || '';
const sasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN;

const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sasToken}`);
const containerClient = blobServiceClient.getContainerClient(containerName);

export const uploadFileToStorage = async (filename: string, filetype: string, filedata: Uint8Array) => {
  const blobClient = containerClient.getBlockBlobClient(filename);
  try {
    const response = await blobClient.uploadData(filedata, { blobHTTPHeaders: { blobContentType: filetype } });
    return blobClient.url;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getBlobFileUrl = (filename: string) => {
  try {
    const blobClient = containerClient.getBlockBlobClient(filename);
    return blobClient.url;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
