import React from 'react';
import styled, { css } from 'styled-components';

interface SeverityBadgeProps {
  children: React.ReactNode;
  type?: 'low' | 'moderate' | 'high' | 'critical';
}

const SeverityBadge = ({ children, type = 'low' }: SeverityBadgeProps) => {
  return <StyledStatusBadge $type={type}>{children}</StyledStatusBadge>;
};

export default SeverityBadge;

type Badge = {
  $type: 'low' | 'moderate' | 'high' | 'critical';
};

const StyledStatusBadge = styled.div`
  border-radius: 5px;
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ $type }: Badge) => Variant($type)};
  fontweight: 500;
  width: 100%;
`;

const Variant = (variant = 'enabled') =>
  ({
    low: css`
      background-color: #30af5b1a;
      color: #30af5b;
      border: 1px solid #30af5b80;
    `,
    moderate: css`
      background-color: #ff74551a;
      color: #ff7455;
      border: 1px solid #ff745580;
    `,
    high: css`
      background-color: #d81c2e1a;
      color: #d81c2e;
      border: 1px solid #d81c2e80;
    `,
    critical: css`
      background-color: #d81c2e1a;
      color: #d81c2e;
      border: 1px solid #d81c2e80;
    `,
  }[variant]);
