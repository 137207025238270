import { ProductsToggleForm } from '@app/components/apps/general-settings/ProductsToggleForm';
import { PropertiesForm } from '@app/components/apps/general-settings/PropertiesForm';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const GeneralSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>General Settings</PageTitle>
      <ComponentHeader title={t('common.generalSettings')} subTitle="Configure general accounts settings" />

      <Collapse accordion defaultActiveKey={[1]} expandIconPosition="end">
        <Collapse.Panel showArrow={true} header="Connected Products" key={1}>
          <ProductsToggleForm />
        </Collapse.Panel>
        <Collapse.Panel showArrow={true} header="Properties" key={2}>
          <PropertiesForm />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default GeneralSettings;
