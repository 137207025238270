import { FONT_WEIGHT } from '@app/styles/themes/constants';
import { RiDeleteBinLine, RiErrorWarningLine } from 'react-icons/ri';
import styled from 'styled-components';

export const SettingsWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid #e9eaeb;
  width: 100%;
  background-color: #fff;
`;

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1.25rem 1.375rem;
  margin-bottom: 0;
`;

export const ScopeContentWrapper = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const SettingsLabel = styled.span`
  font-weight: ${FONT_WEIGHT.bold};
  display: flex;
  align-items: center;
`;

export const SelectedContentWrapper = styled.div`
  margin-top: 0.938rem;
`;

export const SelectedContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
`;

export const DeleteIcon = styled(RiDeleteBinLine)`
  width: 1rem;
  height: 1rem;
  color: #d81c2e;
`;

export const NotificationOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin-top: 1rem;
`;

export const NotificationOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 0;
`;

export const NotificationIcon = styled(RiErrorWarningLine)`
  rotate: 180deg;
  width: 1rem;
  height: 1rem;
  margin-left: 0.625rem;
`;

export const IntervalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
