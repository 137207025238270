import { MailOutlined } from '@ant-design/icons';
import { createReport } from '@app/api/report.api';
import { Button, DatePicker, Divider } from 'antd';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useState } from 'react';
import EmailReportModal from './EmailReportModal';
import { saveAs } from 'file-saver';
import AsyncActionButton from '@app/components/common/AsyncActionButton';

const Container = styled.div`
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const HeaderContainer = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

const FieldsContainer = styled.form`
  margin: 20px;
`;

const ReportSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-top: 4px;
`;

const Title = styled.span`
  font-size: 1.125rem;
  font-weight: 700;
  color: #272727;
`;

const Subtitle = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: #6d6d6d;
`;

const TextInput = styled.input`
  background-color: white;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  width: 270px;
  padding: 12px;
`;

const DateInput = styled(DatePicker)`
  width: 270px;
`;

const RangeDateInput = styled(DatePicker.RangePicker)`
  width: 270px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #272727;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const OutlinedButton = styled(Button)`
  color: #3a50d1;
  border: 1px solid #3a50d1;

  &:hover {
    color: #3a50d1;
  }

  &:disabled {
    border: 1px solid #acacac;
    color: #acacac;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: #acacac;
    }
  }
`;

export type TCustomData = {
  name: string;
  customData: {
    label: string;
    name: string;
    type: 'Date' | 'DateRange' | 'Text';
    isRequired: boolean;
    minDate?: string;
    maxDate?: string;
  }[];
};

interface GeneratedReportData {
  name: string;
  blob: Blob;
}

interface IAdditionalDetails {
  customData: TCustomData[];
  onSubmitCallback?: (payload: any) => void;
}

export default function AdditionalDetails({ customData, onSubmitCallback }: IAdditionalDetails) {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm();

  const [isEmailReportModalOpen, setEmailReportModalOpen] = useState(false);

  const getPayload = (data: any) => {
    const payload = Object.keys(data).map((key) => {
      const customDataKeys = Object.keys(data[key].customData);
      const customDataObj = customDataKeys.map((customDataKey) => {
        const type = customData
          .find((cData) => cData.name === key)
          ?.customData?.find((cData) => cData.name === customDataKey)?.type;
        const value =
          type === 'Text'
            ? data[key]['customData'][customDataKey]
            : moment(data[key]['customData'][customDataKey]).format('DD/MM/YYYY');
        // return {
        //   key: customDataKey,
        //   value: value,
        // };
        return {
          [customDataKey]: value,
        };
      });
      return {
        name: key,
        customData: customDataObj,
      };
    });

    return payload;
  };

  const downloadReport = async () => {
    const isValid = await trigger();
    if (isValid) {
      const data = getValues();
      const payload = getPayload(data);

      // get list of report names and their corresponding data
      const toBeGeneratedReports: Promise<GeneratedReportData>[] = payload
        .map((p) => p.customData.map((cData) => ({ name: p.name, customData: cData })))
        .flatMap((p) => p)
        .map(
          (p) =>
            new Promise((resolve, reject) => {
              createReport(p.name, p.customData).then((blob) => {
                if (!blob) {
                  reject();
                } else {
                  resolve({ name: p.name, blob: blob || new Blob() });
                }
              });
            }),
        );

      // wait for all reports to be downloaded from the server
      const reportsToGenerate = await Promise.all(toBeGeneratedReports);
      // download each report to the user's PC
      reportsToGenerate.forEach((reportToGenerate) => {
        saveAs(reportToGenerate.blob, reportToGenerate.name);
      });

      onSubmitCallback?.(payload);
    }
  };

  const openEmailReportModal = async () => {
    const isValid = await trigger();

    if (isValid) {
      setEmailReportModalOpen(true);
    }
  };

  const disabledDate = (currentDate: moment.Moment, minDateString?: string, maxDateString?: string) => {
    let minDate: Date | undefined;
    let maxDate: Date | undefined;

    if (minDateString) {
      if (minDateString === 'currentDate') {
        minDate = new Date();
      } else {
        minDate = new Date(minDateString);
      }
    }

    if (maxDateString) {
      if (maxDateString === 'currentDate') {
        maxDate = new Date();
      } else {
        maxDate = new Date(maxDateString);
      }
    }

    if (minDate && maxDate) {
      return currentDate && (currentDate < moment(minDate) || currentDate > moment(maxDate));
    }

    if (minDate && !maxDate) {
      return currentDate && currentDate < moment(minDate);
    }

    if (!minDate && maxDate) {
      return currentDate && currentDate > moment(maxDate);
    }

    return false;
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Additional Details</Title>
        <Subtitle>Please enter the following details which are required by the report</Subtitle>
      </HeaderContainer>
      <Divider />
      <FieldsContainer>
        {customData.map((data, index) => {
          return (
            <div key={index} style={{ marginBottom: '24px' }}>
              <Title style={{ fontSize: '1rem' }}>{data.name}</Title>
              <ReportSectionContainer>
                {data.customData.map((cData, cIndex) => (
                  <FieldContainer key={cIndex}>
                    <FieldLabel>{cData.label}</FieldLabel>
                    {cData.type === 'Text' ? <TextInput placeholder="Enter text" /> : null}
                    {cData.type === 'Date' ? (
                      <Controller
                        control={control}
                        name={`${data.name}.customData.${cData.name}`}
                        rules={{ required: cData.isRequired }}
                        render={({ field }) => (
                          <DateInput
                            format="DD/MM/yyyy"
                            {...field}
                            disabledDate={(currentDate) => disabledDate(currentDate, cData.minDate, cData.maxDate)}
                          />
                        )}
                      />
                    ) : null}
                    {cData.type === 'DateRange' ? <RangeDateInput format="DD/MM/yyyy" /> : null}
                    {!!errors[data.name] && <ErrorMessage>Field is required.</ErrorMessage>}
                  </FieldContainer>
                ))}
              </ReportSectionContainer>
            </div>
          );
        })}
        <HorizontalAlignedContainer style={{ gap: '10px' }}>
          <OutlinedButton icon={<MailOutlined />} onClick={openEmailReportModal}>
            Email Report
          </OutlinedButton>
          <AsyncActionButton title="Download Report" loadingText={'Generating...'} onPress={downloadReport} />
        </HorizontalAlignedContainer>
      </FieldsContainer>
      <EmailReportModal
        open={isEmailReportModalOpen}
        onCancel={() => setEmailReportModalOpen(false)}
        getPayload={() => getPayload(getValues())}
      />
    </Container>
  );
}
