import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { Severity } from '@app/interfaces/interfaces';
import * as S from './BaseButton.styles';

export const { Group: ButtonGroup } = AntdButton;

export interface BaseButtonProps extends AntButtonProps {
  severity?: Severity;
  noStyle?: boolean;
  styled?: boolean;
  color?: string;
}

export const BaseButton = React.forwardRef<HTMLElement, BaseButtonProps>(
  ({ className, severity, styled, noStyle, color, children, ...props }, ref) => (
    <S.Button
      ref={ref}
      className={className}
      $noStyle={noStyle}
      {...props}
      $styled={styled}
      $severity={severity}
      color={color}
    >
      {children}
    </S.Button>
  ),
);
