import { FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT.bold};
  margin-bottom: 0px;
`;

export const HeaderSubtitle = styled.span<{ fullWidth?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: #6d6d6d;
  width: ${(props) => (props.fullWidth ? '90%' : '50vw')};
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
`;
