import React from 'react';
import {
  HomeOutlined,
  SettingOutlined,
  UserAddOutlined,
  BarChartOutlined,
  FileTextOutlined,
  FileOutlined,
  BulbOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { BsDatabaseFill } from 'react-icons/bs';
import { HiOutlineBell } from 'react-icons/hi';
import { MdOutlineSecurity } from 'react-icons/md';
import { TfiBook } from 'react-icons/tfi';
import { GrCompliance } from 'react-icons/gr';

import { useAppSelector } from '@app/hooks/reduxHooks';

const notDeletedStatusFilter = [
  {
    column: 'status',
    enumKey: 'UserStatus',
    value: JSON.stringify([1, 2, 3]),
    type: 'enum',
    title: 'Status',
  },
];

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  allow?: boolean;
}

function getSideBarItems(regulations: { id: number; name: string }[]): SidebarNavigationItem[] {
  return [
    {
      title: 'common.homepage',
      key: 'HomePage',
      // TODO use path variable
      url: '/dashboard',
      icon: <HomeOutlined />,
    },
    {
      title: 'common.alert',
      key: 'Alerts',
      icon: <HiOutlineBell />,
      children: [
        {
          title: 'common.activeAlerts',
          key: 'ActiveAlerts',
          url: '/active-alerts',
        },
        {
          title: 'common.alertsHistory',
          key: 'AlertsHistory',
          url: '/alerts-history',
        },
        {
          title: 'common.manageAlerts',
          key: 'alerts',
          url: '/manage-alerts',
        },
      ],
    },
    {
      title: 'common.manageAccounts',
      key: 'ManageAccounts',
      // TODO use path variable
      url: `/manage-accounts?$filter=status eq [1]`,
      icon: <UserAddOutlined />,
    },
    {
      title: 'common.manageVendors',
      key: 'ManageVendors',
      // TODO use path variable
      url: `/manage-vendors?$filter=status eq [1]`,
      icon: <UsergroupAddOutlined />,
    },
    {
      title: 'common.manageRecommendations',
      key: 'ManageRecommendations',
      url: '/manage-recommendations',
      icon: <MdOutlineSecurity />,
    },
    {
      title: 'common.manageStories',
      key: 'ManageStories',
      url: '/manage-stories',
      icon: <TfiBook />,
    },
    {
      title: 'common.manageInsights',
      key: 'ManageInsights',
      url: '/manage-insights',
      icon: <BarChartOutlined />,
    },
    {
      title: 'common.dataConnectors',
      key: 'DataConnectors',
      url: '/data-connectors',
      icon: <BsDatabaseFill />,
    },
    {
      title: 'common.manageReports',
      key: 'ManageReports',
      url: '/manage-reports',
      icon: <FileTextOutlined />,
    },
    {
      title: 'common.manageRegulations',
      key: 'ManageRegulations',
      url: '/manage-regulations',
      icon: <GrCompliance />,
    },
    {
      title: 'common.recommendations',
      key: 'Recommendations',
      icon: <MdOutlineSecurity />,
      children: [
        {
          title: 'common.recommendationsRepository',
          key: 'Recommendations',
          url: '/recommendations',
        },
        {
          title: 'common.history',
          key: 'RecommendationHistory',
          url: '/recommendation-history',
        },
        // {
        //   title: 'common.active',
        //   key: 'ActiveRecommendations',
        //   url: '/active-recommendations',
        // },
        {
          title: 'common.exempted',
          key: 'ExemptedRecommendations',
          url: '/exempted-recommendations',
        },
      ],
    },
    {
      title: 'common.stories',
      key: 'Stories',
      url: '/stories',
      icon: <TfiBook />,
    },
    {
      title: 'common.compliance',
      key: 'RegulationsCompliance',
      url: '/regulations-compliance',
      icon: <GrCompliance />,
      children: regulations.map((regulation) => ({
        title: regulation.name,
        key: `RegulationInformation/${regulation.id}`,
        url: `/regulation-information/${regulation.id}`,
        allow: true,
      })),
    },
    {
      title: 'common.insights',
      key: 'Insights',
      url: '/insights',
      icon: <BulbOutlined />,
    },
    {
      title: 'common.reports',
      key: 'Reports',
      url: '/reports',
      icon: <FileOutlined />,
    },
    {
      title: 'Settings',
      key: 'ManageUsers',
      icon: <SettingOutlined />,
      children: [
        {
          title: 'General',
          key: 'generalSettings',
          url: 'settings/general',
        },
        {
          title: 'Manage Users',
          key: 'manageUsers',
          url: `settings/manage-users?$added=${JSON.stringify(notDeletedStatusFilter)}`,
        },
      ],
    },
    {
      title: 'common.manageSettings',
      key: 'ManageSettings',
      url: '/manage-settings',
      icon: <SettingOutlined />,
    },
  ];
}

export const useSideBarNavigation = (): SidebarNavigationItem[] => {
  const userRole = useAppSelector((state) => state.user.user?.role);
  const screenNumbers = useAppSelector((state) => state.app.rolesAndScreens)
    ?.filter((ras) => ras.role === userRole)
    .map((ras) => ras.screen);
  const dashboardScreens = useAppSelector((state) => state.app.appValues)?.DashboardScreens as {
    label: string;
    value: number;
  }[];
  const dashboardKeys = dashboardScreens
    ?.filter((screen) => screenNumbers?.includes(screen.value))
    .map((screen) => screen.label);
  const regulations = useAppSelector((state) => state.user.regulations);
  const sidebarItems = getSideBarItems(regulations);
  const filteredSidebarItems = sidebarItems.filter((item) => dashboardKeys?.includes(item.key));
  return filteredSidebarItems;
};
