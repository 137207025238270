import { useMemo } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomFilterDropdown } from './CustomFilterDropdown';
import { TFilterValue } from '../types';
import { RootState } from '@app/store/store';
import useToggle from '@app/hooks/useToggle';

const Chip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 10px;
`;

const FilterChipColumnText = styled.span`
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
`;

const FilterChipCloseButton = styled(CloseOutlined)`
  margin-left: 5px;
  cursor: pointer;
`;

interface IFilterChipProps {
  filter: TFilterValue;
  columnFilters: ItemType[];
  index: number;
  onFilterOptionClicked: MenuProps['onClick'];
  onBackClicked: () => void;
  onApply: (filterObj: TFilterValue) => void;
  onFilterRemove: (index: number) => void;
}

export default function FilterChip({
  filter,
  columnFilters,
  index,
  onFilterOptionClicked,
  onBackClicked,
  onApply,
  onFilterRemove,
}: IFilterChipProps) {
  const { t } = useTranslation();
  const appValues = useSelector<RootState>((state) => state.app.appValues);

  const [isDropdownOpen, toggle] = useToggle();

  const filterText = useMemo(() => {
    if (filter.type === 'enum') {
      return `: ${getEnumValues(filter.enumKey || '', filter.value)}`;
    }

    if (filter.type === 'enumArray') {
      return `: ${getEnumValues(filter.enumKey || '', filter.value)}`;
    }

    if (filter.type === 'date') {
      const dateValues = JSON.parse(filter.value);
      return `: from: ${format(dateValues[0], 'dd/MM/yyy')} - to: ${format(dateValues[1], 'dd/MM/yyy')}`;
    }

    return `: ${decodeURIComponent(filter.value.toString().replaceAll("''", "'"))}`;
  }, [filter]);

  function getEnumValues(enumKey: string, value: string) {
    const enumValues = JSON.parse(value) as number[];

    const stringEnumValues = enumValues
      .map((val) =>
        t(
          `appValues.${enumKey || ''}.${
            (appValues as any)?.[enumKey || '']?.find((appVal: any) => appVal.value === val)?.label
          }`,
        ),
      )
      .join(',');

    return stringEnumValues;
  }

  const handleOnApply = (filterObj: TFilterValue) => {
    onApply(filterObj);
    toggle();
  };

  return (
    <Dropdown
      menu={{
        items: columnFilters,
        onClick: onFilterOptionClicked,
      }}
      trigger={['click']}
      arrow
      open={isDropdownOpen}
      onOpenChange={toggle}
      dropdownRender={(menu) => (
        <CustomFilterDropdown
          title={filter.title?.toString() || ''}
          dataIndex={filter.column.toString() || ''}
          type={(filter as any).type}
          onBackClicked={onBackClicked}
          onApply={handleOnApply}
          enumKey={filter.enumKey ?? undefined}
          defaultValues={{
            type: filter.type as any,
            value: filter.value,
          }}
        />
      )}
    >
      <Chip>
        <FilterChipColumnText>{filter.title || filter.column}</FilterChipColumnText>
        {filterText}
        <FilterChipCloseButton role="button" onClick={() => onFilterRemove?.(index)} />
      </Chip>
    </Dropdown>
  );
}
