import { useQuery } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { TList } from '@app/types/generalTypes';

export const getAllProducts = async (): Promise<TEnumEntity[]> => {
  const response = await httpApi.get<TList<TEnumEntity>>('/odata/product');
  return response.data.items;
};

export const useGetAllProducts = () => {
  return useQuery({
    queryKey: ['get-all-products'],
    queryFn: () => getAllProducts(),
    enabled: true,
  });
};
