import useToggle from '@app/hooks/useToggle';
import { TRecommendationForm } from './RecommendationForm';
import { TRecommendation } from '@app/types/recommendation';

interface IUseRecommendationProps {
  onCancel?: () => void;
  onOk?: (data: TRecommendationForm) => void;
  recommendation?: TRecommendation;
}

export default function useRecommendation(props?: IUseRecommendationProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TRecommendationForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    recommendation: props?.recommendation,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
