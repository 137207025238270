import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.div`
  background-color: #283dae;
  color: white;
  padding: 0.938rem 0.875rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  padding-right: 0.875rem;
  border: 1px solid #e9eaeb;
  border-radius: 0 0.5rem 0.5rem 0;
  height: 2.75rem;
`;

export const Input = styled(AntInput)`
  text-align: center;
  padding: 0;
  border: none;
  max-width: 2.5rem;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  gap: 0.25rem;
`;
