import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './Tabs.styled';
import * as D from '../Details/Details.styled';
import Details from '../Details/Details';
import AlertScoping from '../AlertScoping/AlertScoping';
import AlertSettings from '../AlertSettings/AlertSettings';
import { useAlertFunctions } from '../useAlertFunctions';
import { DeleteModal } from '../Details/DeleteAlertModal';
import useToggle from '@app/hooks/useToggle';
import { HorizontalAlignedContainer, SpaceGrower } from '@app/components/common/BaseLayout/BaseLayout.styled';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();
  const location = useLocation();

  const {
    alertForm,
    isScopingTabDisabled,
    isCTADisabled,
    isLoading,
    changeAlertFormDetails,
    createAlert,
    updateAlert,
    deleteAlert,
  } = useAlertFunctions();

  const isCreate = useMemo(() => {
    return location.pathname.includes('create');
  }, [location]);

  const isPreviousBtnShown = useMemo(() => {
    if (activeTab === 0) return false;

    return true;
  }, [activeTab]);

  const isNextBtnShown = useMemo(() => {
    if (activeTab === 2) return false;

    if (isScopingTabDisabled) return false;

    return true;
  }, [activeTab, isScopingTabDisabled]);

  const onNext = () => {
    if (activeTab < 2) {
      const newActiveTab = activeTab + 1;
      setActiveTab(newActiveTab);
    }
  };

  const onPrev = () => {
    if (activeTab > 0) {
      const newActiveTab = activeTab - 1;
      console.log(newActiveTab);
      setActiveTab(newActiveTab);
    }
  };

  const tabContent = [
    {
      title: 'Details',
      content: <Details alertFormDetails={alertForm} changeAlertFormDetails={changeAlertFormDetails} />,
      disabled: false,
    },
    {
      title: 'Alert Scoping',
      content: <AlertScoping alertFormDetails={alertForm} changeAlertFormDetails={changeAlertFormDetails} />,
      disabled: isScopingTabDisabled,
    },
    {
      title: 'Alert Settings',
      content: <AlertSettings alertFormDetails={alertForm} changeAlertFormDetails={changeAlertFormDetails} />,
      disabled: isScopingTabDisabled,
    },
  ];

  return (
    <S.TabsContainer>
      <HorizontalAlignedContainer>
        <S.TabsList>
          {tabContent?.map((tab, index) => (
            <S.Tab
              key={index}
              $isActive={activeTab === index ? true : false}
              onClick={() => setActiveTab(index)}
              disabled={tab.disabled}
            >
              {tab.title}
            </S.Tab>
          ))}
        </S.TabsList>
        <SpaceGrower />
        <D.DetailsActionButtons>
          {!isCreate ? (
            <D.DeleteButton onClick={toggleDeleteModal}>
              <D.DeleteIcon />
              Delete This Alert
            </D.DeleteButton>
          ) : null}
          <D.StyledBaseButton
            type="primary"
            ghost
            disabled={isLoading ? true : isCTADisabled ? isCTADisabled : false}
            onClick={isCreate ? createAlert : updateAlert}
          >
            <D.SaveIcon />
            {isCreate ? 'Create Alert' : 'Save Alert'}
          </D.StyledBaseButton>
        </D.DetailsActionButtons>
      </HorizontalAlignedContainer>
      <div>{tabContent[activeTab].content}</div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        onDelete={deleteAlert}
        isDeleting={isLoading}
      />
      <HorizontalAlignedContainer>
        {isPreviousBtnShown && <CreateAlertButton title="Previous" onPress={onPrev} />}
        <SpaceGrower />
        {isNextBtnShown && <CreateAlertButton title="Next" onPress={onNext} />}
      </HorizontalAlignedContainer>
    </S.TabsContainer>
  );
};

export default Tabs;
