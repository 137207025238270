import { useImpersonateUser } from '@app/api/auth.api';
import { useEffect } from 'react';

const ImpersonateUserPage = () => {
  const { mutateAsync: impersonateUser } = useImpersonateUser();

  const params = new URLSearchParams(window.location.search);
  const accountId = params.get('accountId');

  useEffect(() => {
    if (accountId) {
      impersonateUser(accountId);
    }
  }, []);

  return <></>;
};

export default ImpersonateUserPage;
