import { FONT_WEIGHT } from '@app/styles/themes/constants';
import React from 'react';
import styled from 'styled-components';

interface IHeaderProps {
  children: React.ReactNode;
}

const AlertHeader = ({ children }: IHeaderProps) => {
  return (
    <HeaderTitle>
      Alerts <SecondaryTitle>| {children}</SecondaryTitle>
    </HeaderTitle>
  );
};

export default AlertHeader;

const HeaderTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT.bold};
  margin-bottom: 1.5rem;
`;

const SecondaryTitle = styled.span`
  font-weight: ${FONT_WEIGHT.semibold};
`;
