import { useMemo, useState } from 'react';

import Separator from '@app/components/common/Separator/Separator';
import * as S from './AlertScoping.styled';
import CustomRadioInput from '@app/components/common/CustomInputs/CustomRadio';
import { TDataColumns, TFilterValue } from '@app/components/tables/AntdTableWrapper/types';
import { TAlert } from '@app/types/alert';
import { useFilters } from '@app/components/tables/AntdTableWrapper/hooks/useFilters';
import Filters from '@app/components/tables/AntdTableWrapper/components/Filters';
import ItemSelect, { useItemSelect } from '@app/components/common/selects/ItemSelect';
import { useGetModelItems } from '@app/api/table.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { getEnumValue } from '@app/services/enum.service';

export const recommendationTableCols: TDataColumns<TAccountRecommendationExtended> = [
  {
    title: 'Findings',
    dataIndex: 'findings',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Severity',
    dataIndex: 'severity',
    align: 'center',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'RecommendationSeverity',
    showSortDirections: true,
  },
  // {
  //   title: 'Category',
  //   dataIndex: 'category',
  //   align: 'center',
  //   allowFiltering: true,
  //   type: 'enum',
  //   enumValuesKey: 'RecommendationCategory',
  //   showSortDirections: true,
  // },
  {
    title: 'User Impact',
    dataIndex: 'easeOfFix',
    align: 'center',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'RecommendationEaseOfFix',
    showSortDirections: true,
  },
  {
    title: 'Estimated Work',
    dataIndex: 'estimatedWork',
    align: 'center',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'RecommendationEstimatedWork',
    showSortDirections: true,
  },
  {
    title: 'Product',
    dataIndex: 'productName',
    align: 'center',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
];

interface IAlertScopingProps {
  alertFormDetails?: Partial<TAlert>;
  changeAlertFormDetails?: (details: Partial<TAlert>) => void;
}

const AlertScoping = ({ alertFormDetails, changeAlertFormDetails }: IAlertScopingProps) => {
  const [searchString, setSearchString] = useState('');
  const { data } = useGetModelItems<TAccountRecommendationExtended>({
    model: 'accountRecommendationExtended',
    queryParams: `$filter=(status eq ${getEnumValue('AccountRecommendationStatus', 'Enabled')})`,
  });

  const recommendationItems = useMemo(() => {
    if (data) {
      return data.items
        .filter((item) => item.findings.toLowerCase().includes(searchString.toLowerCase()))
        .map((item) => ({ ...item, key: item.id, label: item.findings }))
        .sort((a, b) => (a.findings < b.findings ? -1 : 1));
    }

    return [];
  }, [data, searchString]);

  const defaultSelectedItems = useMemo(() => {
    if (alertFormDetails?.filters === undefined || alertFormDetails?.filters === '') {
      return [];
    }

    const selectedRecommendations: TAccountRecommendationExtended[] = [];
    const recommendationIds = alertFormDetails?.filters
      .replaceAll('id eq ', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll("'", '')
      .split(' or ');

    recommendationIds?.forEach((id) => {
      const selectedRecommendation = data?.items.find((item) => item.id.toString() === id);
      if (selectedRecommendation) {
        selectedRecommendations.push(selectedRecommendation);
      }
    });

    return selectedRecommendations.map((item) => ({ ...item, key: item.id, label: item.findings }));
  }, [alertFormDetails?.filters, data]);

  const filterProps = useFilters({
    columns: recommendationTableCols,
    onFiltersChanged(filters) {
      if (filters.length > 0) {
        const filterString = parseFilters(filters);
        changeAlertFormDetails?.({ filters: filterString || '' });
      }
    },
    ...(alertFormDetails?.scope === 'classification_rules' && { defaultFilter: alertFormDetails?.filters }),
  });

  const itemSelectProps = useItemSelect({
    items: recommendationItems,
    defaultSelectedItems,
    onItemsSelectCallback(selectedItems) {
      let filterString = '';
      if (selectedItems) {
        filterString = `(${selectedItems.map((item) => `id eq '${item.id}'`).join(' or ')})`;
      }
      changeAlertFormDetails?.({ filters: filterString });
    },
    onSearchItemCallback: (searchString) => setSearchString(searchString),
  });

  function parseFilters(filters: TFilterValue[]) {
    const searchableColumnsFilters = filters
      .filter((f) => f.type === 'searchableColumn')
      .map((f) => `(contains(${f.column},'${f.value}'))`)
      .join(' or ');

    const allFilters = filters
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enum') {
          return `(${(JSON.parse(f.value) as number[]).map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        return `(contains(${f.column},'${f.value}'))`;
      })
      .join(' and ');

    return [searchableColumnsFilters, allFilters].filter((f) => f !== '').join(' and ');
  }

  return (
    <S.AlertContainer>
      <S.ScopeWrapper>
        <S.RadioWrapper>
          <CustomRadioInput
            name="scope"
            value="classification_rules"
            checked={alertFormDetails?.scope === 'classification_rules'}
            onChange={() => {
              changeAlertFormDetails?.({ scope: 'classification_rules', filters: undefined });
              filterProps.onClearFilters();
            }}
          />
          <span>Classification Rules</span>
        </S.RadioWrapper>

        {alertFormDetails?.scope === 'classification_rules' ? (
          <>
            <Separator />
            <Filters {...filterProps} />
          </>
        ) : null}
      </S.ScopeWrapper>
      <S.ScopeWrapper>
        <S.RadioWrapper>
          <CustomRadioInput
            name="scope"
            value="specific_recommendation"
            checked={alertFormDetails?.scope === 'specific_recommendation'}
            onChange={() => {
              changeAlertFormDetails?.({ scope: 'specific_recommendation', filters: undefined });
            }}
          />
          <span>Specific Recommendations</span>
        </S.RadioWrapper>

        {alertFormDetails?.scope === 'specific_recommendation' ? (
          <>
            <Separator />
            <ItemSelect {...itemSelectProps} />
          </>
        ) : null}
      </S.ScopeWrapper>
    </S.AlertContainer>
  );
};

export default AlertScoping;
