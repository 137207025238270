import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { useWhoAmI } from '@app/api/auth.api';
import { UserState } from '@app/store/slices/userSlice';
import { IApplicationState } from '@app/store/slices/appSlice';
import { isScreenAllowed } from '@app/utils/utils';
import { getEnumValue } from '@app/services/enum.service';

const enabledStatusFilter = [
  {
    column: 'status',
    enumKey: 'AccountStatus',
    value: JSON.stringify([1]),
    type: 'enum',
    title: 'Status',
  },
];

const MainLayout: React.FC = () => {
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector<RootState>((state) => state.user.isAuthenticated);
  const userRole: number = useSelector<RootState>((state) => state.user.user?.role) as number;
  const { isFetching } = useWhoAmI();

  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/auth/login', { replace: true });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isFetching && location.pathname === '/') {
      if (
        userRole != null &&
        [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(userRole)
      ) {
        if (!location.pathname.includes('manage-accounts')) {
          navigate(`/manage-accounts?$filter=status eq [1]`);
        }
        return;
      }

      navigate('/dashboard');
    }
  }, [userRole, isFetching, location]);

  // useEffect(() => {
  //   const isHomePage = location.pathname === '/';
  //   const statesPopulated = appState.appValues != null && appState.rolesAndScreens != null && userState.user != null;

  //   if (isAuthenticated && !isHomePage && statesPopulated) {
  //     const dashboardScreens = (appState.appValues as any)['DashboardScreens'];
  //     const rolesAndScreens = appState.rolesAndScreens || [];
  //     const userRole = userState.user?.role || 0;
  //     if (!isScreenAllowed(location.pathname, dashboardScreens, rolesAndScreens, userRole)) {
  //       navigate('/');
  //     }
  //   }
  // }, [location.pathname, appState, userState]);

  useEffect(() => {
    setIsTwoColumnsLayout([DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
