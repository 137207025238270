import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useForm } from 'react-hook-form';
import { useCompleteAccountSetup } from '@app/api/account.api';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
  margin: 2px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const CompleteButton = styled.button`
  align-self: center;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 5px;
  width: 150px;
`;

const BottomText = styled.p`
  font-weight: bold;
  color: red;
`;

const Title = styled.h1``;

const SubTitleText = styled.p``;

export type TMicrosoftAppForm = {
  microsoftApplicationId: string;
  microsoftApplicationSecret: string;
};

export type TMicrosoftAppFormProps = {
  onSubmit?: (data: TMicrosoftAppForm) => Promise<void>;
};

const MicrosoftAppForm = ({ onSubmit }: TMicrosoftAppFormProps) => {
  const { mutateAsync: completeAccountSetup } = useCompleteAccountSetup();
  const [error, setError] = useState<string | null>(null);
  const [completeBtnTxt, setCompleteBtnTxt] = useState('Complete Setup');
  const [completeBtnDisabled, setCompleteBtnDisabled] = useState(false);
  const [showRunningScanText, setShowRunningScanText] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TMicrosoftAppForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    reset({
      microsoftApplicationId: '',
      microsoftApplicationSecret: '',
    });
  }, []);

  const onFormSubmit = async (data: TMicrosoftAppForm) => {
    try {
      await setCompleteBtnDisabled(true);
      await setCompleteBtnTxt('Finishing Setup...');
      await setShowRunningScanText(true);
      const response = await completeAccountSetup({
        appId: data.microsoftApplicationId,
        appSecret: data.microsoftApplicationSecret,
      });
      await onSubmit?.(data);
    } catch (err) {
      setError((err as any)?.data?.message);
    } finally {
      await setShowRunningScanText(false);
      await setCompleteBtnTxt('Complete Setup');
      await setCompleteBtnDisabled(false);
    }
  };

  return (
    <Container onSubmit={handleSubmit(onFormSubmit)}>
      <Title>Setup Microsoft Account</Title>
      <SubTitleText>
        To complete your account setup, please enter your app registration details, or contact Griffin31 support.
      </SubTitleText>
      <FormContainer>
        <HorizontalAlignedContainer>
          <FieldLabel>Microsoft Application ID:</FieldLabel>
          <InputContainer>
            <FieldInput
              hasError={!!errors.microsoftApplicationId}
              {...register('microsoftApplicationId', { required: true })}
            />
          </InputContainer>
        </HorizontalAlignedContainer>
        <HorizontalAlignedContainer>
          <FieldLabel>Microsoft Application Secret:</FieldLabel>
          <InputContainer>
            <FieldInput
              type="password"
              hasError={!!errors.microsoftApplicationSecret}
              {...register('microsoftApplicationSecret', { required: true })}
            />
          </InputContainer>
        </HorizontalAlignedContainer>
        <CompleteButton {...{ disabled: completeBtnDisabled ? true : undefined }}>{completeBtnTxt}</CompleteButton>
        {showRunningScanText && (
          <BottomText>Running initial scan of your account, this make take a few minutes</BottomText>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormContainer>
    </Container>
  );
};

export { MicrosoftAppForm };
