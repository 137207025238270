const environmentName = process.env['REACT_APP_ENV'] || 'local';
const config = require(`@app/config.${environmentName}.json`);

export interface Config {
  server: {
    url: string;
  };
}

const envConfig = {
  server: {
    url: config.serverUrl,
  },
} as Config;

export default envConfig;
