import useToggle from '@app/hooks/useToggle';
import { TSettings } from '@app/types/settings';
import { TSettingsForm } from './SettingsForm';

interface IUseSettingsFormProps {
  onOk?: (data: TSettingsForm) => void;
  onCancel?: () => void;
  settings?: TSettings;
}

const useSettingsForm = (props?: IUseSettingsFormProps) => {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOk = (data: TSettingsForm) => {
    props?.onOk?.(data);
  };

  const handleCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };

  return {
    open: isModalOpen,
    onOk: handleOk,
    onCancel: handleCancel,
    toggle,
    settings: props?.settings,
  };
};

export default useSettingsForm;
