import { useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

interface IAsyncActionButtonProps {
  disabled?: boolean;
  title: string;
  loadingText: string;
  onPress?: () => Promise<void>;
}

const AsyncActionButton = ({ disabled = false, title, loadingText, onPress }: IAsyncActionButtonProps) => {
  const [buttonTitle, setButtonTitle] = useState(title);
  const [isDisabled, setIsDisabled] = useState(disabled);

  const handleClick = async () => {
    setButtonTitle(loadingText);
    setIsDisabled(true);
    if (onPress) {
      await onPress();
    }
    setButtonTitle(title);
    setIsDisabled(false);
  };

  return (
    <StyledBaseButton type={isDisabled ? 'default' : 'primary'} disabled={isDisabled} onClick={handleClick}>
      {buttonTitle}
    </StyledBaseButton>
  );
};

export default AsyncActionButton;

const StyledBaseButton = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.75rem;
  &:disabled {
    opacity: 0.6;
    background: ${BASE_COLORS.gray};
    color: ${BASE_COLORS.white};
  }
  &:disabled:hover {
    opacity: 0.6;
    background: ${BASE_COLORS.gray};
    color: ${BASE_COLORS.white};
  }
`;
