import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;
  display: flex;
  justify-content: center;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 6rem;
  }
  overflow: hidden;
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;

export const EmailCol = styled(BaseCol)`
  display: flex;
  align-items: center;
  max-width: 200px;
`;
