import { useEffect, useState } from 'react';
import { Level } from '@tiptap/extension-heading';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import styled from 'styled-components';

const MenuDropDown = styled.select<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? 'black' : 'transparent')};
  border: ${(props) => (props.isActive ? '0.5px solid black' : '0.5px solid #acacac')};
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isActive ? 'white' : 'black')};

  &:hover {
    background-color: ${(props) => (props.isActive ? 'black' : 'lightgray')};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const StylingDropdown = ({ editor }: { editor: Editor }) => {
  const [selectedValue, setSelectedValue] = useState('paragraph');

  useEffect(() => {
    const updateSelection = () => {
      const { $from } = editor.state.selection;
      const node = $from.node();
      const heading = node.type.name === 'heading' ? `heading${node.attrs.level}` : 'paragraph';
      setSelectedValue(heading);
    };

    // Update selection when editor's state changes
    editor.on('selectionUpdate', updateSelection);

    // Clean up event listener when component unmounts
    return () => {
      editor.off('selectionUpdate', updateSelection);
    };
  }, [editor]);

  return (
    <MenuDropDown
      value={selectedValue}
      onChange={(e) => {
        const value = e.target.value;
        editor.chain().focus();
        if (value === 'paragraph') {
          editor.chain().focus().setParagraph().run();
        } else if (value.startsWith('heading')) {
          const level = parseInt(value.replace('heading', ''), 10) as Level;
          editor.chain().focus().toggleHeading({ level }).run();
        }
        setSelectedValue(value); // Manually update the dropdown after applying the formatting
      }}
    >
      <option value="paragraph">Paragraph</option>
      <option value="heading1">H1</option>
      <option value="heading2">H2</option>
      <option value="heading3">H3</option>
      <option value="heading4">H4</option>
      <option value="heading5">H5</option>
      <option value="heading6">H6</option>
    </MenuDropDown>
  );
};

export default StylingDropdown;
