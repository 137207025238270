import useToggle from '@app/hooks/useToggle';
import { TReportsForm } from './ReportsForm';
import { TReport } from '@app/types/reports';

interface IUseManageReportsFormProps {
  onCancel?: () => void;
  onOk?: (data: TReportsForm & { thumbnailFile?: File; templateFile?: File }) => void;
  report?: TReport;
}

export default function useManageReportsForm(props?: IUseManageReportsFormProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TReportsForm & { thumbnailFile?: File; templateFile?: File }) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    report: props?.report,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
