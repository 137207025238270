import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

interface IButtonProps {
  disabled?: boolean;
  title: string;
  onPress?: () => void;
}

const Button = ({ disabled = false, title, onPress }: IButtonProps) => {
  return (
    <StyledBaseButton type={disabled ? 'default' : 'primary'} disabled={disabled} onClick={() => onPress?.()}>
      {title}
    </StyledBaseButton>
  );
};

export default Button;

const StyledBaseButton = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.75rem;
  &:disabled {
    opacity: 0.6;
    background: ${BASE_COLORS.gray};
    color: ${BASE_COLORS.white};
  }
  &:disabled:hover {
    opacity: 0.6;
    background: ${BASE_COLORS.gray};
    color: ${BASE_COLORS.white};
  }
`;
