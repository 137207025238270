import styled from 'styled-components';

import LoginBGImage from '@app/assets/images/LoginBG.png';
import Logo from '@app/assets/logo-dark.png';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { PersonalDetails } from './PersonalDetails';
import { AccountInformation } from './AccountInformation';
import { useState } from 'react';

const Container = styled.div<{ step: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  padding: ${(props) => (props.step === 1 ? '0px' : '80px 0px')};
  background: url('${LoginBGImage}');
  background-size: cover;
  background-position: center;
`;

const CoverContainer = styled.div<{ step: number }>`
  flex-grow: 1;
  background: #3a50d1cc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div<{ step: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const LogoContainer = styled.img`
  object-fit: fill;
`;

export function CreateAccountForm() {
  const params = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(params.has('accountId') ? 2 : 1);

  return (
    <Container step={step}>
      <FormContainer step={step}>
        <HorizontalAlignedContainer style={{ marginBottom: '35px' }}>
          <LogoContainer src={Logo} />
        </HorizontalAlignedContainer>
        {step === 1 ? (
          <PersonalDetails onStepClicked={() => setStep(2)} />
        ) : (
          <AccountInformation onStepClicked={() => setStep(1)} />
        )}
      </FormContainer>
      <CoverContainer step={step} />
    </Container>
  );
}
