import { Modal, ModalFuncProps, Spin } from 'antd';
import styled from 'styled-components';
import { TSettings } from '@app/types/settings';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: gray;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
`;

const FieldTextArea = styled.textarea<{ hasError?: boolean; disabled?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  resize: vertical;
  min-height: 128px;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const UpdateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const StyledHorizontalContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

export type TSettingsForm = {
  name: string;
  displayName: string;
  description: string;
  value: string;
};

interface ISettingsFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  settings?: TSettings;
}

const SettingsForm = ({ isEdit, isLoading, settings, ...modalProps }: ISettingsFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TSettingsForm>();

  useEffect(() => {
    if (modalProps.open && !!settings) {
      reset({
        name: settings.name,
        displayName: settings.displayName,
        description: settings.description,
        value: settings.value,
      });
    } else if (modalProps.open && !settings) {
      reset({
        name: '',
        displayName: '',
        description: '',
        value: '',
      });
    }
  }, [modalProps.open, settings]);

  const onSubmit = (data: TSettingsForm) => {
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal {...modalProps} style={{ minWidth: '600px' }} destroyOnClose closable={false} footer={null}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>Edit</FormTitle>
        <FormContainer>
          <Col>
            <Label>Name</Label>
            <Value>{settings?.displayName}</Value>
          </Col>

          <Col>
            <Label>Description</Label>
            <Value>{settings?.description}</Value>
          </Col>

          <Col>
            <Label>Value</Label>
            <InputContainer>
              <Controller
                control={control}
                name="value"
                render={({ field }) => (
                  <FieldTextArea
                    hasError={!!errors.value}
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
              {!!errors.value && <ErrorMessage>{errors.value.message}</ErrorMessage>}
            </InputContainer>
          </Col>

          <StyledHorizontalContainer>
            {isLoading ? (
              <Spin />
            ) : (
              <>
                <CancelButton type="button" onClick={handleCancel}>
                  Cancel
                </CancelButton>
                <UpdateButton>Update</UpdateButton>
              </>
            )}
          </StyledHorizontalContainer>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default SettingsForm;
