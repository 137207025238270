import React from 'react';
import styled, { css } from 'styled-components';

interface StatusBadgeProps {
  children: React.ReactNode;
  type?: 'enabled' | 'disabled' | 'pending';
}

const StatusBadge = ({ children, type = 'enabled' }: StatusBadgeProps) => {
  return <StyledStatusBadge $type={type}>{children}</StyledStatusBadge>;
};

export default StatusBadge;

type Badge = {
  $type: 'enabled' | 'disabled' | 'pending';
};

const StyledStatusBadge = styled.div`
  border-radius: 10px;
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ $type }: Badge) => Variant($type)};
  fontweight: 500;
`;

const Variant = (variant = 'enabled') =>
  ({
    enabled: css`
      background-color: #e6f9f0;
      color: #30af5b;
    `,
    disabled: css`
      background-color: #ffeae6;
      color: #d81c2e;
    `,
    pending: css`
      background-color: #ffb155;
      color: #fff;
    `,
  }[variant]);
