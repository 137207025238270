import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetModelItemsLazy } from '@app/api/table.api';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TReport } from '@app/types/reports';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import ListComponent from '@app/components/common/lists/ListComponent';
import ReportCard from '@app/components/apps/reports/ReportCard';
import { useListComponent } from '@app/components/common/lists/useListComponent';
import AdditionalDetails, { TCustomData } from '@app/components/apps/reports/AdditionalDetails';
import { getEnumValue } from '@app/services/enum.service';
import { useAppSelector } from '@app/hooks/reduxHooks';

export default function ReportsPage() {
  const { t } = useTranslation();
  const appValues = useAppSelector((state) => state.app.appValues);

  const activeReportsQueryParams = useMemo(() => {
    if (appValues) {
      const activeReportStatus = getEnumValue('ReportStatus', 'Active');
      return `$filter=(status eq ${activeReportStatus})`;
    }

    return undefined;
  }, [appValues]);

  const { data: reports } = useGetModelItemsLazy<TReport>({
    model: 'report',
    queryParams: activeReportsQueryParams,
  });

  const { selectedItems, ...listComponentProps } = useListComponent({
    data: reports?.items || [],
  });

  const customData = useMemo(() => {
    const additionalDetails = selectedItems.map((item) => {
      const parsedCustomData: TCustomData['customData'] = JSON.parse(
        item.customData.replaceAll('”', '"').replaceAll('“', '"'),
      );
      return {
        name: item.name,
        customData: parsedCustomData,
      };
    });

    // const additionalDetails = selectedItems.reduce((customDataArr, item) => {
    //   if (item.customData) {
    //     const parsedCustomData: TCustomData[] = JSON.parse(item.customData.replaceAll('”', '"').replaceAll('“', '"'));
    //     return [...customDataArr, ...parsedCustomData];
    //   }
    //   return [...customDataArr];
    // }, [] as TCustomData[]);

    // return [...new Map(additionalDetails.map((d) => [d.Label, d])).values()];
    return additionalDetails;
  }, [selectedItems]);

  return (
    <>
      <PageTitle>Reports</PageTitle>
      <ComponentHeader
        title={t('common.reports')}
        subTitle="Choose from our library of predefined report templates to generate detailed reports tailored to your specific needs."
      />
      <ListComponent {...listComponentProps} renderComponent={(report) => <ReportCard {...report} />} />
      {customData.length > 0 ? (
        <div style={{ marginTop: '20px' }}>
          <AdditionalDetails customData={customData} />
        </div>
      ) : null}
    </>
  );
}
