import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import FontSize from 'tiptap-extension-font-size';
import Link from '@tiptap/extension-link';
import { Node } from '@tiptap/core';

import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { Level } from '@tiptap/extension-heading';

import { FaBold, FaItalic, FaStrikethrough, FaUnderline } from 'react-icons/fa';
import { HiListBullet } from 'react-icons/hi2';
import { GoListOrdered } from 'react-icons/go';
import { PiCodeBlock } from 'react-icons/pi';
import { TbBlockquote } from 'react-icons/tb';
import { BiUndo } from 'react-icons/bi';
import { BiRedo } from 'react-icons/bi';
import { BiLink } from 'react-icons/bi';
import StylingDropdown from './StylingDropDown';
import { TbClearFormatting } from 'react-icons/tb';

import { HorizontalAlignedContainer, VerticalContainer } from '../BaseLayout/BaseLayout.styled';

const FieldArea = styled.div`
  flex-basis: 75%;
  background-color: transparent;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

const MenuBarContainer = styled.div`
  margin-bottom: 12px;
  border: 1px solid #acacac;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  gap: 4px;
`;

const MenuBarButton = styled.button<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? 'black' : 'transparent')};
  border: ${(props) => (props.isActive ? '0.5px solid black' : '0.5px solid #acacac')};
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isActive ? 'white' : 'black')};

  &:hover {
    background-color: ${(props) => (props.isActive ? 'black' : 'lightgray')};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const SaveButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 28px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:disabled {
    background-color: #acacac;
    border-color: #acacac;
    cursor: default;
  }
`;

const DiscardButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 20px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  const setLink = useCallback(
    (editor: Editor) => {
      const previousUrl = editor.getAttributes('link').href;
      const initialUrl = previousUrl || 'https://';
      const url = window.prompt('URL', initialUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    },
    [editor],
  );

  if (!editor) {
    return null;
  }

  return (
    <MenuBarContainer>
      <MenuBarButton
        title="bold"
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
      >
        <FaBold />
      </MenuBarButton>
      <MenuBarButton
        title="italic"
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
      >
        <FaItalic />
      </MenuBarButton>
      <MenuBarButton
        title="strike-through"
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isActive={editor.isActive('strike')}
      >
        <FaStrikethrough />
      </MenuBarButton>
      <MenuBarButton
        title="strike-through"
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().unsetUnderline().run()}
        isActive={editor.isActive('underline')}
      >
        <FaUnderline />
      </MenuBarButton>
      <MenuBarButton
        title="insert link"
        type="button"
        onClick={() => setLink(editor)}
        isActive={editor.isActive('link')}
      >
        <BiLink />
      </MenuBarButton>
      <MenuBarButton
        title="code block"
        type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        isActive={editor.isActive('code')}
      >
        <PiCodeBlock />
      </MenuBarButton>
      {/* <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          Clear marks
        </button> */}
      <MenuBarButton title="clear formatting" type="button" onClick={() => editor.chain().focus().clearNodes().run()}>
        <TbClearFormatting />
      </MenuBarButton>
      {/* <button type="button" onClick={() => editor.chain().focus().setParagraph().run()}>
          Paragraph
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
          H1
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
          H2
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
          H3
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}>
          H4
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}>
          H5
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}>
          H6
        </button> */}
      <StylingDropdown editor={editor} />

      <MenuBarButton
        title="bullet list"
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
      >
        <HiListBullet />
      </MenuBarButton>
      <MenuBarButton
        title="ordered list"
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive('orderedList')}
      >
        <GoListOrdered />
      </MenuBarButton>
      <MenuBarButton
        title="block quote"
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        isActive={editor.isActive('blockquote')}
      >
        <TbBlockquote />
      </MenuBarButton>
      {/* <button type="button" onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          Blockquote
        </button>
        <button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          Horizontal rule
        </button>
        <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
          Hard break
        </button> */}
      <MenuBarButton
        type="button"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        <BiUndo />
      </MenuBarButton>
      <MenuBarButton
        type="button"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        <BiRedo />
      </MenuBarButton>
    </MenuBarContainer>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextStyle,
  Underline,
  FontSize,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https',
  }),
];

export interface HtmlEditorProps {
  initialValue?: string;
  height?: number;
  showSaveSection?: boolean;
  saveDisabled?: boolean;
  readOnly?: boolean;
  onSave?: (savedValue?: string) => void;
  onDiscard?: () => void;
  onChange?: (htmlValue: string) => void;
}

export default function HtmlEditor({
  initialValue,
  showSaveSection,
  saveDisabled,
  readOnly,
  onChange,
  onSave,
  onDiscard,
}: HtmlEditorProps) {
  const editor = useEditor({
    extensions,
    content: initialValue,
    editable: !readOnly,
    editorProps: {
      attributes: {
        class: readOnly ? 'read-only' : '',
      },
    },
    onUpdate: (props) => {
      onChange?.(props.editor.getHTML());
    },
  });

  const handleSave = () => {
    onSave?.(editor?.getHTML());
    editor?.commands.clearContent();
  };

  if (readOnly) {
    return <EditorContent editor={editor} />;
  }

  return (
    <>
      <FieldArea>
        <VerticalContainer>
          <MenuBar editor={editor} />
          <EditorContent editor={editor} />
        </VerticalContainer>
      </FieldArea>
      {showSaveSection && (
        <HorizontalAlignedContainer style={{ gap: '8px', marginTop: '12px' }}>
          <SaveButton type="button" disabled={editor?.getText().length === 0 || saveDisabled} onClick={handleSave}>
            Save
          </SaveButton>
          {onDiscard && (
            <DiscardButton type="button" onClick={() => onDiscard()}>
              Discard
            </DiscardButton>
          )}
        </HorizontalAlignedContainer>
      )}
    </>
  );
}
