import React from 'react';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Spin } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
`;

const ConfirmationMessage = styled.span`
  font-size: 24px;
  font-weight: 800;
  line-height: 49px;
  color: #272727;
  text-align: center;
  margin: 0px 80px;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  border: 1px solid #d81c2e;
  border-radius: 10px;
  background-color: #d81c2e;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledHorizontalContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  margin-top: 90px;
`;

interface IConfirmationModalProps extends ModalFuncProps {
  isLoading?: boolean;
  message?: string;
}

const ConfirmationModal = ({ isLoading, message, ...modalProps }: IConfirmationModalProps) => {
  return (
    <Modal {...modalProps} destroyOnClose closable={false} footer={null} style={{ minWidth: '400px' }}>
      <Container>
        <ConfirmationMessage>{message}</ConfirmationMessage>
        <StyledHorizontalContainer>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton onClick={modalProps.onCancel}>No</CancelButton>
              <ConfirmButton onClick={modalProps.onOk}>Yes</ConfirmButton>
            </>
          )}
        </StyledHorizontalContainer>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
