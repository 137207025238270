// type newsCardProps = {
//   children: React.ReactNode,
//   title: string,
// };

import { Button, Card } from 'antd';
import { PropsWithChildren } from 'react';

interface INewsCardProps extends PropsWithChildren {
  title: string;
  onViewAllClicked?: () => void;
}

export function NewsCard({ children, title, onViewAllClicked }: INewsCardProps) {
  return (
    <Card style={{ height: '100%' }}>
      {/* Header */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '.8rem',
          fontWeight: '700',
          marginBottom: '6px',
          height: '100%',
        }}
      >
        <span>{title}</span>
        <Button
          size="small"
          type="text"
          style={{ fontSize: '.8rem', color: '#3A50D1' }}
          onClick={() => onViewAllClicked?.()}
        >
          View All
        </Button>
      </div>
      {/* Content */}
      {children}
    </Card>
  );
}
