import { IconProps } from '../types';

export default function ArrowRising(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.12" y="0.697266" width="21" height="21" rx="3" fill={fill} />
      <path
        d="M13.6112 8.97852L13.6156 11.1973H14.8743L14.8743 6.82227H10.4993V8.08102L12.7181 8.08539L6.12433 14.6791L7.01745 15.5723L13.6112 8.97852Z"
        fill={fill}
      />
    </svg>
  );
}
