import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import LoginBGImage from '../../../assets/images/LoginBG.png';
import styled from 'styled-components';

export const RememberMeText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const LoginContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100dvh;
  background: #f8f8f8;
`;

export const LoginBackground = styled.div`
  flex: 1;
  background: url('${LoginBGImage}');
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 100dvh; /* Adjust as needed */
`;

export const LoginForm = styled.div`
  width: 50rem;
  margin: auto 0;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    padding: 0 6rem;
  }
`;

export const LoginFormWrapper = styled.div`
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1.25rem;

  @media (min-width: 768px) {
    padding: 3.125rem;
  }
`;

export const Logo = styled.img`
  object-fit: fill;
  margin-bottom: 2.5rem;
`;

export const LoginTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: ${FONT_WEIGHT.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272727;
  margin-bottom: 0.5rem !important;
`;

export const LoginDescription = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: #6d6d6d;
  text-align: center;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const FormLabel = styled.label`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  margin-bottom: 0.5rem;
`;

export const TextField = styled(BaseInput)`
  height: 2.75rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 12px;
  border: 1px solid #e9eaeb;
  background: #fff;

  &::placeholder {
    color: #acacac;
  }

  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Set a background color */
  }
`;

export const PasswordField = styled(InputPassword)`
  height: 2.75rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 12px;
  border: 1px solid #e9eaeb;
  background: #fff;

  & ::placeholder {
    color: #acacac;
  }

  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Set a background color */
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  width: 100%;
`;

export const CheckBoxField = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.125rem;
  color: #acacac;
  font-size: ${FONT_SIZE.xs};

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const ForgotPasswordText = styled.span`
  color: #3a50d1;
  font-size: ${FONT_SIZE.xs};
  text-decoration: none;
`;

export const LoginButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
`;

export const SocialLogin = styled(StyledButton)`
  width: 100%;
  border-color: #acacac;
  color: #acacac;
  margin-bottom: 4px;
`;

export const MicrosoftIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

export const SignupText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: #6d6d6d;
`;
