import { IconProps } from '../types';

export default function FilterIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5809 0.0100643H2.42052C1.99476 0.00938085 1.57776 0.130943 1.21906 0.360306C0.86037 0.589668 0.575064 0.917187 0.39705 1.30394C0.219037 1.6907 0.1558 2.12043 0.214854 2.54207C0.273909 2.96371 0.452772 3.35954 0.730189 3.68251L5.93198 9.74958C6.12315 9.981 6.23382 10.2728 6.23382 10.5646V16.2392C6.23382 16.853 6.54573 17.4164 7.06892 17.7384C7.35065 17.9094 7.67261 18 7.99458 18C8.26624 18 8.52784 17.9396 8.77938 17.8088L10.7917 16.8027C11.3954 16.5008 11.7676 15.8972 11.7676 15.223V10.5545C11.7676 10.2527 11.8783 9.96087 12.0695 9.73952L17.2713 3.67245C17.5487 3.34948 17.7276 2.95365 17.7866 2.53201C17.8457 2.11037 17.7824 1.68064 17.6044 1.29388C17.4264 0.907126 17.1411 0.579607 16.7824 0.350244C16.4237 0.120882 16.0067 -0.000680628 15.5809 2.86646e-06V0.0100643ZM16.1243 2.69648L10.9225 8.76356C10.4898 9.26663 10.2584 9.90051 10.2584 10.5646V15.2331C10.2584 15.3337 10.2081 15.4142 10.1176 15.4544L8.10526 16.4606C7.99458 16.521 7.90403 16.4807 7.86378 16.4505C7.82354 16.4203 7.74304 16.36 7.74304 16.2392V10.5646C7.74304 9.90051 7.51163 9.26663 7.07899 8.76356L1.8772 2.69648C1.76679 2.56755 1.70612 2.4034 1.70615 2.23365C1.70615 1.84125 2.02812 1.51929 2.42052 1.51929H15.5809C15.9733 1.51929 16.2953 1.84125 16.2953 2.23365C16.2953 2.4047 16.2349 2.56568 16.1243 2.69648Z"
        fill={fill}
      />
    </svg>
  );
}
