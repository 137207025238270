import * as S from './ComponentHeader.styled';

interface IComponentHeaderProps {
  title: string;
  subTitle?: string | React.JSX.Element | React.JSX.Element[];
  subTitleFullWidth?: boolean;
  actionButtons?: React.ReactNode;
}

const ComponentHeader = ({ title, subTitle, subTitleFullWidth, actionButtons }: IComponentHeaderProps) => {
  return (
    <S.HeaderWrapper>
      <S.TitleWrapper>
        <S.HeaderTitle>{title}</S.HeaderTitle>
        {subTitle ? <S.HeaderSubtitle fullWidth={subTitleFullWidth}>{subTitle}</S.HeaderSubtitle> : null}
      </S.TitleWrapper>
      <S.HeaderActionsWrapper>{actionButtons}</S.HeaderActionsWrapper>
    </S.HeaderWrapper>
  );
};

export default ComponentHeader;
