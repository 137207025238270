import { IconProps } from '../types';

export default function TrashIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4H3.33333H14" stroke={fill} strokeWidth="1.68421" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.33398 3.99967V2.66634C5.33398 2.31272 5.47446 1.97358 5.72451 1.72353C5.97456 1.47348 6.3137 1.33301 6.66732 1.33301H9.33399C9.68761 1.33301 10.0267 1.47348 10.2768 1.72353C10.5268 1.97358 10.6673 2.31272 10.6673 2.66634V3.99967M12.6673 3.99967V13.333C12.6673 13.6866 12.5268 14.0258 12.2768 14.2758C12.0267 14.5259 11.6876 14.6663 11.334 14.6663H4.66732C4.3137 14.6663 3.97456 14.5259 3.72451 14.2758C3.47446 14.0258 3.33398 13.6866 3.33398 13.333V3.99967H12.6673Z"
        stroke={fill}
        strokeWidth="1.68421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 7.33301V11.333"
        stroke={fill}
        strokeWidth="1.68421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 7.33301V11.333"
        stroke={fill}
        strokeWidth="1.68421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
