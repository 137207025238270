import { IconProps } from '../types';

export default function DoubleLeftIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12L4 8L8 4" stroke={fill} strokeWidth="1.34513" strokeLinecap="round" strokeLinejoin="round" />
      <path
        opacity="0.4"
        d="M12.666 12L8.66602 8L12.666 4"
        stroke={fill}
        strokeWidth="1.34513"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
