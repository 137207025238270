import { Modal, ModalFuncProps, Spin } from 'antd';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { TReport } from '@app/types/reports';
import FilePicker from '@app/components/common/BaseFilePicker/FilePicker';
import { getBlobFileUrl } from '@app/api/azure-storage.api';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const FieldArea = styled.textarea<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TReportsForm = {
  name: string;
  description: string;
  thumbnailUrl: string;
  url: string;
  customData: string;
  reportData: string;
};

interface IReportsFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  report?: TReport;
}

export default function ReportsForm({ isEdit, isLoading, report, ...modalProps }: IReportsFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<TReportsForm>({
    mode: 'onBlur',
  });

  const onDropThumbnail = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue('thumbnailUrl', file.name);
  }, []);

  const onDropTemplate = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue('url', file.name);
  }, []);

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
    isDragActive: isThumbnailDragActive,
    acceptedFiles: thumbnailFiles,
  } = useDropzone({
    onDrop: onDropThumbnail,
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getTemplateRootProps,
    getInputProps: getTemplateInputProps,
    isDragActive: isTemplateDragActive,
    acceptedFiles: templateFiles,
  } = useDropzone({
    onDrop: onDropTemplate,
    accept: {
      '.docx': [],
    },
  });

  useEffect(() => {
    if (modalProps.open && !!report) {
      reset({
        name: report.name,
        description: report.description,
        customData: report.customData,
        reportData: report.reportData,
        thumbnailUrl: report.thumbnailUrl || '',
        url: report.url || '',
      });
    } else if (modalProps.open && !report) {
      reset({
        name: '',
        description: '',
        customData: '',
        reportData: '',
        thumbnailUrl: '',
        url: '',
      });
    }
  }, [modalProps.open, report]);

  const onSubmit: SubmitHandler<TReportsForm> = (data) => {
    const thumbnailFile = thumbnailFiles[0];
    const templateFile = templateFiles[0];
    modalProps.onOk?.({ ...data, thumbnailFile, templateFile });
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      style={{
        minWidth: '600px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Report`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Title:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
              {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Description:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="description"
                // rules={{
                //   required: true,
                // }}
                render={({ field }) => <FieldArea hasError={!!errors.description} {...field} />}
              />
              {!!errors.description && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Thumbnail:</FieldLabel>
            <InputContainer>
              <FilePicker
                getInputProps={getThumbnailInputProps}
                getRootProps={getThumbnailRootProps}
                isDragActive={isThumbnailDragActive}
                label={getValues('thumbnailUrl')}
                {...(!!getValues('thumbnailUrl') &&
                  getValues('thumbnailUrl') !== '' && { imgPreview: getBlobFileUrl(getValues('thumbnailUrl')) })}
              />
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Template:</FieldLabel>
            <InputContainer>
              <FilePicker
                getInputProps={getTemplateInputProps}
                getRootProps={getTemplateRootProps}
                isDragActive={isTemplateDragActive}
                label={getValues('url')}
              />
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Custom Data:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="customData"
                rules={{
                  required: true,
                }}
                render={({ field }) => <FieldArea hasError={!!errors.customData} {...field} />}
              />
              {!!errors.customData && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Report Data:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="reportData"
                rules={{
                  required: true,
                }}
                render={({ field }) => <FieldArea hasError={!!errors.reportData} {...field} />}
              />
              {!!errors.reportData && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
