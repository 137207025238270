import styled from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpaceGrower = styled.div`
  flex-grow: 1;
`;
