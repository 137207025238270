import { useSelector } from 'react-redux';
import { useState } from 'react';

import CustomCheckbox from '@app/components/common/CustomInputs/CustomCheckbox';
import * as S from './AlertSettings.styled';
import Separator from '@app/components/common/Separator/Separator';
import CustomRadioInput from '@app/components/common/CustomInputs/CustomRadio';
import IntervalDaySelect from './IntervalDaySelect/IntervalDaySelect';
import { RootState } from '@app/store/store';
import { TAlert } from '@app/types/alert';
import EmailGroupInput from '@app/components/apps/manage-users/EmailGroupInput/EmailGroupInput';
import { useEmailGroupInput } from '@app/components/apps/manage-users/EmailGroupInput/useEmailGroupInput';

interface IAlertSettingsProps {
  alertFormDetails?: Partial<TAlert>;
  changeAlertFormDetails?: (details: Partial<TAlert>) => void;
}

const AlertSettings = ({ alertFormDetails, changeAlertFormDetails }: IAlertSettingsProps) => {
  const emailNotificationType = (
    useSelector<RootState>((state) => (state.app.appValues as any).AlertNotificationType) as {
      label: string;
      value: number;
    }[]
  )?.find((type) => type.label === 'Email');

  const emailGroupInputProps = useEmailGroupInput({
    defaultEmailList: alertFormDetails?.recipients,
    onAddEmail: (emailList) => {
      changeAlertFormDetails?.({ recipients: emailList } as any);
    },
  });

  const [intervalValue, setIntervalValue] = useState(alertFormDetails?.notificationRepetition || 1);

  const handleOnIntervalValueChange = (newValue: number) => {
    changeAlertFormDetails?.({ notificationRepetition: newValue });
    setIntervalValue(newValue);
  };

  return (
    <S.SettingsWrapper>
      <S.Wrapper>
        <CustomCheckbox
          checked={alertFormDetails?.notificationType === 0 ? false : true}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              changeAlertFormDetails?.({ notificationType: emailNotificationType?.value || 0 });
            } else {
              changeAlertFormDetails?.({ notificationType: 0 });
            }
          }}
        />
        <S.SettingsLabel>Send Notification Email</S.SettingsLabel>
      </S.Wrapper>
      {alertFormDetails?.notificationType !== 0 ? (
        <>
          <Separator />
          <S.ScopeContentWrapper>
            <div>
              <S.SettingsLabel>Recipients</S.SettingsLabel>
              <S.SelectedContentWrapper>
                <EmailGroupInput {...emailGroupInputProps} />
              </S.SelectedContentWrapper>
            </div>
            <Separator />
            <div>
              <S.SettingsLabel>Repeat Notifications</S.SettingsLabel>
              <S.NotificationOptionWrapper>
                <S.NotificationOption>
                  <CustomRadioInput
                    name="notification"
                    value="yes"
                    checked={(alertFormDetails?.notificationRepetition || 0) > 0}
                    onChange={() => {
                      changeAlertFormDetails?.({ notificationRepetition: 1 });
                    }}
                  />
                  <span>Yes</span>
                </S.NotificationOption>
                <S.NotificationOption>
                  <CustomRadioInput
                    name="notification"
                    value="no"
                    checked={alertFormDetails?.notificationRepetition === 0}
                    onChange={() => {
                      changeAlertFormDetails?.({ notificationRepetition: 0 });
                    }}
                  />
                  <span>No</span>
                </S.NotificationOption>
              </S.NotificationOptionWrapper>
            </div>
            {alertFormDetails?.notificationRepetition !== 0 ? (
              <>
                <Separator />
                <S.IntervalWrapper>
                  <S.SettingsLabel>
                    Repeating Interval <S.NotificationIcon />
                  </S.SettingsLabel>
                  <IntervalDaySelect value={intervalValue} onChange={handleOnIntervalValueChange} />
                </S.IntervalWrapper>
              </>
            ) : null}
          </S.ScopeContentWrapper>
        </>
      ) : null}
    </S.SettingsWrapper>
  );
};

export default AlertSettings;
