import * as S from './Details.styled';
import Separator from '@app/components/common/Separator/Separator';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface IDeleteModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDelete?: () => void;
  isDeleting?: boolean;
}

export const DeleteModal = ({ isOpen, toggle, onDelete, isDeleting }: IDeleteModalProps) => {
  return (
    <S.DeleteModalContent centered open={isOpen} footer={null} closeIcon={<span></span>}>
      <S.DeleteModalMessage>
        Are you sure <br /> you want to delete this alert?
      </S.DeleteModalMessage>
      <Separator />
      {isDeleting ? (
        <S.DeleteModalActions>
          <BaseButton style={{ padding: '0.25rem 3rem' }} styled color="red" disabled type="text">
            Deleting ...
          </BaseButton>
        </S.DeleteModalActions>
      ) : (
        <S.DeleteModalActions>
          <BaseButton styled style={{ padding: '0.25rem 3rem' }} onClick={toggle}>
            Cancel
          </BaseButton>
          <BaseButton style={{ padding: '0.25rem 3rem' }} styled color="red" onClick={onDelete}>
            Delete
          </BaseButton>
        </S.DeleteModalActions>
      )}
    </S.DeleteModalContent>
  );
};
