import useToggle from '@app/hooks/useToggle';
import { TRegulationControlsForm } from './RegulationControlsForm';
import { TRegulationControl } from '@app/types/regulationControl';

interface IUseRegulationControlsFormProps {
  onCancel?: () => void;
  onOk?: (data: TRegulationControlsForm) => void;
  regulationControl?: TRegulationControl;
}

export default function useRegulationControlsForm(props?: IUseRegulationControlsFormProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TRegulationControlsForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    regulationControl: props?.regulationControl,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
