import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { ChangeEvent, useEffect, useState } from 'react';

interface IUseItemSelectProps<T extends MenuItemType> {
  items: T[];
  defaultSelectedItems?: T[];
  onItemsSelectCallback?: (selectedItems: T[]) => void;
  onSearchItemCallback?: (searchString: string) => void;
}

export function useItemSelect<T extends MenuItemType>({
  items,
  defaultSelectedItems,
  onItemsSelectCallback,
  onSearchItemCallback,
}: IUseItemSelectProps<T>) {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchItemValue, setSearchItemValue] = useState('');

  const onItemRemove = (index: number) => {
    const newSelectedItems = selectedItems.filter((item, itemIndex) => index !== itemIndex);
    setSelectedItems(newSelectedItems);
    onItemsSelectCallback?.(newSelectedItems);
  };

  const onItemClicked = (key: string) => {
    const selectedItem = items.find((item) => item.key.toString() === key);

    if (selectedItem && selectedItems.find((item) => selectedItem.key === item.key)) {
      setIsDropdownOpen(false);
      return;
    }

    if (selectedItem) {
      const newSelectedItems = [...selectedItems, selectedItem];
      setSelectedItems(newSelectedItems);
      onItemsSelectCallback?.(newSelectedItems);
    }
    setIsDropdownOpen(false);
  };

  const onClearSelection = () => {
    setSelectedItems([]);
    onItemsSelectCallback?.([]);
  };

  const toggleDropdown = (open: boolean) => {
    setIsDropdownOpen(open);

    if (open === true) {
      setSearchItemValue('');
    }
  };

  const onSearchItem = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchItemValue(e.target.value);
    onSearchItemCallback?.(e.target.value);
  };

  useEffect(() => {
    if (defaultSelectedItems) {
      setSelectedItems([...defaultSelectedItems]);
    }
  }, [defaultSelectedItems]);

  return {
    items,
    selectedItems,
    isDropdownOpen,
    searchItemValue,
    onItemClicked,
    onItemRemove,
    onClearSelection,
    toggleDropdown,
    onSearchItem,
  };
}
