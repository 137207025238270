import styled from 'styled-components';

import MicrosoftIcon from '@app/assets/images/MicrosoftIcon.png';
import Step1 from '@app/assets/images/step-1.png';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { signUp } from '@app/api/auth.api';
import { Link } from 'react-router-dom';
import { FONT_SIZE } from '@app/styles/themes/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 50px;
`;

const Title = styled.span`
  font-size: 40px;
  font-weight: 700;
  color: #272727;
`;

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #6d6d6d;
  margin-top: 20px;
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #6d6d6d;
`;

const LoginText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: #6d6d6d;
`;

const SocialLogin = styled(StyledButton)`
  width: 100%;
  background-color: #3a50d1;
  border-radius: 10px;
  color: white;
  padding: 12px 0px;
  width: 500px;
  margin-bottom: 4px;
`;

const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

const StepImage = styled.img`
  margin-bottom: 30px;
  cursor: pointer;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
`;

interface IPersonalDetailsProps {
  onStepClicked?: () => void;
}

const handleSignUp = async () => {
  const params = new URLSearchParams(window.location.search);
  const invitingUserId = params.get('invite');
  const signUpData = {
    invite: invitingUserId ?? undefined,
  };
  const url = await signUp(signUpData);
  window.location.href = url;
};

export function PersonalDetails({ onStepClicked }: IPersonalDetailsProps) {
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  return (
    <Container>
      <StepImage src={Step1} onClick={() => onStepClicked?.()} />
      <Title>Personal Details</Title>
      <SubTitle>Root User Setup</SubTitle>
      <Description>Note that you need to have global administrator</Description>
      <Description>in your azure tenant for system setup</Description>
      <ErrorMessage>{error}</ErrorMessage>
      <SocialLogin variant="outlined" color="dark" size="large" onClick={handleSignUp}>
        <Icon src={MicrosoftIcon} alt="Microsoft Icon" />
        Signup using Microsoft
      </SocialLogin>
      <LoginText>
        Already a member? <Link to="/auth/login"> Click here to login.</Link>
      </LoginText>
    </Container>
  );
}
