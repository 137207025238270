import { IconProps } from '../types';

export default function BulbOnIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0516 5.04984L19.6416 3.63984L17.8516 5.43984L19.2616 6.84984L21.0516 5.04984ZM13.6016 4.13984L11.6016 4.13984L11.6016 1.13984L13.6016 1.13984L13.6016 4.13984ZM23.6016 13.1398L20.6016 13.1398L20.6016 11.1398L23.6016 11.1398L23.6016 13.1398ZM11.6016 20.0898L11.6016 16.1298L10.6016 15.5498C9.36156 14.8298 8.60156 13.5098 8.60156 12.0898C8.60156 9.87984 10.3916 8.08984 12.6016 8.08984C14.8116 8.08984 16.6016 9.87984 16.6016 12.0898C16.6016 13.5098 15.8316 14.8298 14.6016 15.5498L13.6016 16.1298L13.6016 20.0898L11.6016 20.0898ZM9.60156 22.0898L15.6016 22.0898L15.6016 17.2798C17.3916 16.2398 18.6016 14.3098 18.6016 12.0898C18.6016 8.77984 15.9116 6.08984 12.6016 6.08984C9.29156 6.08984 6.60156 8.77984 6.60156 12.0898C6.60156 14.3098 7.81156 16.2398 9.60156 17.2798L9.60156 22.0898ZM4.60156 13.1398L1.60156 13.1398L1.60156 11.1398L4.60156 11.1398L4.60156 13.1398ZM7.36156 5.42984L5.57156 3.62984L4.16156 5.03984L5.96156 6.82984L7.36156 5.42984Z"
        fill={fill}
      />
    </svg>
  );
}
