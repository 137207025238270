import { RightOutlined } from '@ant-design/icons';
import { TInsight } from '@app/types/insight';
import { useNavigate } from 'react-router-dom';

type IInsightItemProps = TInsight & {
  icon: any;
};

export function InsightItem({ id, title, icon }: IInsightItemProps) {
  const navigate = useNavigate();

  function onInsightClick() {
    navigate(`/insights/${id}`);
  }

  return (
    <div
      style={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #F1F1F2',
        cursor: 'pointer',
        marginTop: '6px',
      }}
      onClick={onInsightClick}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div style={{ background: '#ECEFFD', padding: '10px', borderRadius: '8px' }}>{icon}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0px',
            minHeight: '62px',
          }}
        >
          {/* <h5 style={{ fontSize: '.875rem', margin: '0px' }}>
            <b>{title}</b>
          </h5> */}
          <p style={{ fontSize: '.75rem', width: '100%', margin: '0px' }}>{title}</p>
        </div>
      </div>
      <RightOutlined style={{ color: '#3A50D1' }} />
    </div>
  );
}
