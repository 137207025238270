import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { usePWA } from './hooks/usePWA';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { useDispatch } from 'react-redux';
import { loadAppValues } from './store/slices/appSlice';
import { StatusType, datadogLogs } from '@datadog/browser-logs';
import '@datadog/browser-logs/bundle/datadog-logs';
import { Loading } from './components/common/Loading/Loading';
import { CenterContainer } from './components/common/BaseLayout/BaseLayout.styled';
import { IApplicationState } from './store/slices/appSlice';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const finishedLoadingValues = useAppSelector((state: { app: IApplicationState }) => state.app.finishedLoadingValues);
  const dispatch = useDispatch();

  useEffect(() => {
    const environment = process.env['REACT_APP_ENV'] || 'local';
    datadogLogs.init({
      clientToken: process.env.DATADOG_CLIENT_TOKEN || '',
      site: 'datadoghq.eu',
      env: environment,
      service: 'dashboard',
      sessionSampleRate: 100,
      forwardConsoleLogs: ['error'],
      forwardErrorsToLogs: true,
    });
    datadogLogs.logger.setLevel(StatusType.debug);
    datadogLogs.logger.error(`dropDownUpdateActivityHandler: failed with error`, { user: 'abc' });

    dispatch(loadAppValues());
  }, []);

  usePWA();

  // useAutoNightMode();

  // useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <Helmet>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
        </Helmet>
        {finishedLoadingValues ? (
          <ConfigProvider locale={language === 'en' ? enUS : deDe}>
            <AppRouter />
          </ConfigProvider>
        ) : (
          <CenterContainer style={{ height: '100%' }}>
            <Loading />
          </CenterContainer>
        )}
      </HelmetProvider>
    </>
  );
};

export default App;
