import { useState } from 'react';
import { Spin, Radio, Popover } from 'antd';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';

import EmailGroupInput from './EmailGroupInput/EmailGroupInput';
import { useEmailGroupInput } from './EmailGroupInput/useEmailGroupInput';
import { useInviteUsers } from '@app/api/user.api';
import { SemiCirleCheckIcon } from '@app/components/icons';
import { CenterContainer, HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { InfoCircleOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const Message = styled.span`
  font-size: 32px;
  font-weight: 800;
  line-height: 50px;
  color: #272727;
  text-align: center;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={() => onCancel?.()}>Cancel</CancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Invite
      </ApplyButton>
    </FooterContainer>
  );
};

interface IInviteUsersModalProps extends ModalFuncProps {
  isLoading?: boolean;
  onInviteSuccess?: () => void;
}

export default function InviteUsersModal({ onInviteSuccess, ...modalProps }: IInviteUsersModalProps) {
  const { mutateAsync: inviteUsers, isLoading: isInviteUsersLoading } = useInviteUsers();
  const [isSuccess, setIsSuccess] = useState(false);

  const emailGroupInputProps = useEmailGroupInput();
  const [role, setRole] = useState(1);

  const handleInviteUsers = async () => {
    const invites = emailGroupInputProps.emails.map((email) => ({
      email,
      role,
    }));
    const response = await inviteUsers({ invites });
    if (response) {
      setIsSuccess(true);
      onInviteSuccess?.();
    }
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    emailGroupInputProps.clearEmails();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={!isInviteUsersLoading && isSuccess ? null : 'Invite Users'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isInviteUsersLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : isSuccess ? (
          <FooterContainer>
            <ApplyButton onClick={handleCloseModal}>Continue</ApplyButton>
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={handleInviteUsers}
            onApplyDisabled={emailGroupInputProps.emails.length === 0}
          />
        )
      }
    >
      {isSuccess ? (
        <CenterContainer
          style={{
            gap: '16px',
            marginTop: '60px',
          }}
        >
          <SemiCirleCheckIcon />
          <Message>Users Are Invited!</Message>
        </CenterContainer>
      ) : (
        <Container>
          <FieldContainer>
            <FieldLabel>User Email Addresses:</FieldLabel>
            <EmailGroupInput {...emailGroupInputProps} />
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>Assigned Role For New Users:</FieldLabel>
            <Radio.Group name="radiogroup" value={role}>
              <Radio value={1} onChange={(e) => setRole(e.target.value)}>
                <HorizontalAlignedContainer style={{ gap: '8px' }}>
                  Administrator
                  <Popover trigger={['hover']} content={<div>Administrators can use all system features.</div>}>
                    <InfoCircleOutlined width={24} height={24} />
                  </Popover>
                </HorizontalAlignedContainer>
              </Radio>
              <Radio value={2} onChange={(e) => setRole(e.target.value)}>
                <HorizontalAlignedContainer style={{ gap: '8px' }}>
                  User
                  <Popover
                    trigger={['hover']}
                    content={
                      <div style={{ maxWidth: '20vw' }}>
                        Users can use most system features, but are restricted from performing some administrative
                        actions.
                      </div>
                    }
                  >
                    <InfoCircleOutlined width={24} height={24} />
                  </Popover>
                </HorizontalAlignedContainer>
              </Radio>
            </Radio.Group>
          </FieldContainer>
        </Container>
      )}
    </Modal>
  );
}
