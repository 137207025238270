import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './Details.styled';
import { TAlert } from '@app/types/alert';

interface IDetailsProps {
  alertFormDetails?: Partial<TAlert>;
  changeAlertFormDetails?: (details: Partial<TAlert>) => void;
}

const Details = ({ alertFormDetails, changeAlertFormDetails }: IDetailsProps) => {
  const location = useLocation();

  const isCreate = useMemo(() => {
    return location.pathname.includes('create');
  }, [location]);

  return (
    <S.DetailsWrapper>
      <S.DetailsRow>
        <S.Label>Alert Name:</S.Label>
        <S.AlertName
          value={alertFormDetails?.name}
          onChange={(e) => {
            changeAlertFormDetails?.({ name: e.target.value });
          }}
        />
      </S.DetailsRow>
      {!isCreate ? (
        <S.DetailsRow>
          <S.Label>Last Updated Time:</S.Label>
          <span>
            {alertFormDetails?.lastUpdatedTime && alertFormDetails?.lastUpdatedTime !== ''
              ? new Date(alertFormDetails?.lastUpdatedTime).toDateString()
              : ''}
          </span>
        </S.DetailsRow>
      ) : null}
      <S.DetailsRow>
        <S.Label>Status:</S.Label>
        <S.StyledSelect
          suffixIcon={<S.ArrowIcon />}
          defaultValue={1}
          value={alertFormDetails?.status}
          options={[
            { value: 1, label: 'Enabled' },
            { value: 2, label: 'Disabled' },
          ]}
          onChange={(val: any) => {
            changeAlertFormDetails?.({ status: val });
          }}
        />
      </S.DetailsRow>
    </S.DetailsWrapper>
  );
};

export default Details;
