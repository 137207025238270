import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`
  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 
  
  #root {
    height: 100vh;
  }

  [data-no-transition] * {
    transition: none !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-overlay {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      width: 323px;
    }
  }

  a {
    color: var(--primary-color);
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 2rem;
    min-height: 6rem;
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.25rem;
      right: 1.25rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }
      .ant-notification-notice-with-icon .ant-notification-notice-description  {
        margin-top: 0.625rem;
      }
    }
    
    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
  }
  
    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }
  
    &.ant-notification-notice-success {
      border: 1px solid var(--success-color);
      background: var(--notification-success-color);
      
      .title {
        color: var(--success-color);
      }
    }
  
    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);
  
      .title {
        color: var(--primary-color);
      }
    }
  
    &.ant-notification-notice-warning {
      border: 1px solid var(--warning-color);
      background: var(--notification-warning-color);
  
      .title {
        color: var(--warning-color);
      }
    }
  
    &.ant-notification-notice-error {
      border: 1px solid var(--error-color);
      background: var(--notification-error-color);
  
      .title {
        color: var(--error-color);
      }
    }
  
    .success-icon {
      color: var(--success-color);
    }
  
    .info-icon {
      color: var(--primary-color);
    }
  
    .warning-icon {
      color: var(--warning-color);
    }
  
    .error-icon {
      color: var(--error-color);
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }

  // notifications end

  // Table styles 

  .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #3A50D1;
  }

  ant-checkbox-checked .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #3A50D1;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-radius: 5px;
    border: 1.5px solid #E9EAEB;
    height: 20px;
    width: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: #3A50D1;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #3A50D1;
    border-top: 0;
    border-left: 0;
    left: 25.5%;
  }

  .ant-table-content {
    border: 1px solid #E9EAEB;
    border-radius: 8px;
    overflow: auto;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #fff;
    border-bottom: 1px solid #E9EAEB;
    font-weight: 700;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  .ant-table-row .ant-table-cell {
    background-color: #fff;
    border-bottom: 1px solid #E9EAEB;
    font-weight: 500;
    font-size: 0.875rem;
  }

  // table styles end

  // Antd Dropdown styles

  .ant-dropdown {
    min-width: 250px !important;
  }

  .ant-dropdown-menu {
    padding: 16px;
  }

  .ant-dropdown-menu-item {
    margin-bottom: 10px;
    padding: 5px 20px;
  }

  .ant-dropdown-menu-item:hover {
    background-color: #EDEFFD;
    color: #000;
    font-weight: 600;
    border-radius: 8px;
  }

  .ant-dropdown-menu-item-selected {
    background-color: #4c63ec;
    border-radius: 8px;
    color: #fff
  }

  // Antd Dropdown styles end 

  // Antd Modal styles

  .ant-modal-title {
    font-size: 20px;
    font-weight: 800;
    color: #272727;
  }

  // Antd Modal styles

  // Antd Popover styles

  .ant-popover-inner-content {
    max-height: 350px;
    // min-width: 350px;
    overflow: auto;
  }

  // Antd Popover styles end

  // Antd Select styles

  .ant-select-item-option-content {
    font-size: 12px;
  }

  // Antd Select styles end

  // tiptap style

  .ProseMirror {
    height: 150px;
    border: 1px solid #acacac;
    border-radius: 4px;
    overflow: auto;
    padding: 20px;

    ul, ol {
      padding-left: 20px;
    }
    
    li {
      p {
          display: inline;
      }
    }
  }

  .read-only {
    border: none;
    padding: 0px;
    height: auto;
  }

  .ProseMirror:focus {
    outline: none;
  }

  // tiptap style end
`;
