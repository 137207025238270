import React, { ChangeEvent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const CustomDropdownContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-height: 60vh;
  max-width: 500px;
  overflow: auto;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 12px;
  border: 1px solid #acacac;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  width: 100%;
`;

interface ICustomDropdownProps {
  menu: React.ReactNode;
  inputValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function CustomDropdown({ menu, inputValue, onChange }: ICustomDropdownProps) {
  return (
    <CustomDropdownContainer>
      <SearchContainer>
        <SearchOutlined />
        <SearchInput type="text" placeholder="Search..." value={inputValue} onChange={onChange} />
      </SearchContainer>
      {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
    </CustomDropdownContainer>
  );
}
