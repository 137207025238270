import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';

import Step2 from '@app/assets/images/step-2.png';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useGetAllLicenses } from '@app/api/license.api';
import { useGetAllProducts } from '@app/api/product.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { useFinalizeAccountSignUp } from '@app/api/account.api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  max-width: 600px;
`;

const Title = styled.span`
  font-size: 40px;
  font-weight: 700;
  color: #272727;
`;

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #6d6d6d;
  margin-top: 20px;
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #6d6d6d;
`;

const CreateAccountBtn = styled(StyledButton)`
  width: 100%;
  background-color: #3a50d1;
  border-radius: 10px;
  color: white;
  padding: 12px 0px;
  width: 500px;
`;

const StepImage = styled.img`
  margin-bottom: 30px;
  cursor: pointer;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #272727;
`;

const FieldRequiredLabel = styled(FieldLabel)`
  color: red;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 24px;
`;

const FieldBox = styled.div`
  padding: 15px 14px;
  border: 1px solid #e9eaeb;
  border-radius: 20px;
  width: 100%;
`;

const FieldValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #272727;
`;

const DisabledText = styled(FieldValue)`
  color: #6d6d6d;
`;

const SelectFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -15px;
`;

const SelectOption = styled(HorizontalAlignedContainer)`
  flex-basis: 50%;
  margin-top: 15px;
`;

const AccountNameInput = styled.input`
  background: transparent;
  color: #272727;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
`;

interface IAccountInformationProps {
  onStepClicked?: () => void;
}

export function AccountInformation({ onStepClicked }: IAccountInformationProps) {
  const [accountName, setAccountName] = useState('');
  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<number[]>([]);

  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  const { mutateAsync: finalizeAccountSetup } = useFinalizeAccountSignUp();

  const [products, setProducts] = useState<TEnumEntity[]>([]);
  const { data: productData, isSuccess: productIsSuccess } = useGetAllProducts();
  useEffect(() => {
    if (productIsSuccess) {
      setProducts(productData);
    }
  }, [productData, productIsSuccess]);

  const handleFieldChecked = (value: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedFields(Array.from(new Set([...selectedFields, value])));
      return;
    }

    const newSelectedFields = selectedFields.filter((field) => field !== value);
    setSelectedFields(newSelectedFields);
  };

  const handleLicensesChecked = (value: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedLicenses(Array.from(new Set([...selectedLicenses, value])));
      return;
    }

    const newSelectedFields = selectedLicenses.filter((field) => field !== value);
    setSelectedLicenses(newSelectedFields);
  };

  const onCreateAccount = async () => {
    if (accountName.length === 0) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const accountId = params.get('accountId') || '';
    const redirectUrl = await finalizeAccountSetup({
      accountId,
      accountName,
      licenseIds: selectedLicenses,
      productIds: selectedFields,
    });
    window.location.href = redirectUrl;
  };

  return (
    <Container>
      <StepImage src={Step2} onClick={() => onStepClicked?.()} />
      <Title>Account Information</Title>
      <SubTitle>Create Account</SubTitle>
      <Description>Enter required information for the create a new account</Description>
      <FieldsContainer>
        <FieldGroup>
          <FieldLabel>Account Name</FieldLabel>
          <FieldBox>
            <AccountNameInput type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
          </FieldBox>
          <FieldGroup>{accountName.length === 0 && <FieldRequiredLabel>*Required</FieldRequiredLabel>}</FieldGroup>
        </FieldGroup>

        <FieldGroup>
          <FieldLabel>Select Products Used In Your Tenant</FieldLabel>
          <FieldBox>
            <SelectFieldsContainer>
              {products.map((product) => (
                <SelectOption key={product.id}>
                  <Checkbox
                    checked={selectedFields.includes(product.id)}
                    onChange={(e) => handleFieldChecked(product.id, e.target.checked)}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  {selectedFields.includes(product.id) ? (
                    <FieldValue>{product.displayName}</FieldValue>
                  ) : (
                    <DisabledText>{product.displayName}</DisabledText>
                  )}
                </SelectOption>
              ))}
            </SelectFieldsContainer>
          </FieldBox>
        </FieldGroup>

        {/* <FieldGroup>
          <FieldLabel>Select Licenses Used In Your Tenant</FieldLabel>
          <FieldBox>
            <SelectFieldsContainer>
              {licenses.map((license) => (
                <SelectOption key={license.id}>
                  <Checkbox
                    checked={selectedLicenses.includes(license.id)}
                    onChange={(e) => handleLicensesChecked(license.id, e.target.checked)}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  {selectedLicenses.includes(license.id) ? (
                    <FieldValue>{license.name}</FieldValue>
                  ) : (
                    <DisabledText>{license.name}</DisabledText>
                  )}
                </SelectOption>
              ))}
            </SelectFieldsContainer>
          </FieldBox>
        </FieldGroup> */}
      </FieldsContainer>
      <ErrorMessage>{error}</ErrorMessage>
      <CreateAccountBtn variant="outlined" color="dark" size="large" onClick={onCreateAccount}>
        Create Account
      </CreateAccountBtn>
    </Container>
  );
}
