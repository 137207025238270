import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCreateAlert, useGetAlertData, useUpdateAlert } from '@app/api/alert.api';
import { TAlert } from '@app/types/alert';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';

export type TScopeValue = 'classification_rules' | 'specific_recommendation';

export function useAlertFunctions() {
  const location = useLocation();
  const navigate = useNavigate();

  const { mutateAsync: createAlert, isLoading: createLoading, isSuccess: createSuccess } = useCreateAlert();
  const { mutateAsync: updateAlert, isLoading: updateLoading, isSuccess: updateSuccess } = useUpdateAlert();

  const [alertForm, setAlertForm] = useState<Partial<TAlert> | undefined>({
    status: 1,
    scope: 'classification_rules',
    notificationType: 1,
    notificationRepetition: 1,
  });

  const isCreate = useMemo(() => {
    return location.pathname.includes('create');
  }, [location]);

  const isCTADisabled = useMemo(() => {
    if (
      alertForm?.name === '' ||
      alertForm?.name === undefined ||
      alertForm.filters === '' ||
      alertForm.filters === undefined
    ) {
      return true;
    }

    return false;
  }, [alertForm]);

  const isScopingTabDisabled = useMemo(() => {
    if (alertForm?.name === undefined || alertForm?.name === '') {
      return true;
    }

    return false;
  }, [alertForm?.name]);

  const alertId = location.pathname.split('/').at(-1) !== 'create' ? location.pathname.split('/').at(-1) : undefined;

  const { data: alertData } = useGetAlertData(alertId);

  const setAlertDefaults = async () => {
    setAlertForm({
      id: alertId,
      name: alertData?.name,
      status: alertData?.status,
      filters: alertData?.filters,
      notificationType: alertData?.notificationType,
      notificationRepetition: alertData?.notificationRepetition,
      lastUpdatedTime: alertData?.lastUpdatedTime,
      scope: alertData?.filters.includes('id eq') ? 'specific_recommendation' : 'classification_rules',
      recipients: alertData?.recipients,
    });
  };

  const onCreateAlert = async () => {
    const payload = { ...alertForm } as TAlert;

    await createAlert(payload);
  };

  const onUpdateAlert = async () => {
    const payload = { ...alertForm } as TAlert;
    delete payload.lastUpdatedTime;
    delete payload.scope;

    if (alertId) {
      await updateAlert({
        id: alertId,
        form: payload,
      });
    }
  };

  const onDeleteAlert = async () => {
    const payload = { ...alertForm, status: 3 } as TAlert;
    delete payload.lastUpdatedTime;
    delete payload.scope;

    if (alertId) {
      await updateAlert({
        id: alertId,
        form: payload,
        onSuccessMessage: 'Alert Deleted',
      });
    }
  };

  const changeAlertFormDetails = (details: Partial<TAlert>) => {
    const newFormDetails = { ...alertForm, ...details };
    setAlertForm(newFormDetails);
  };

  useEffect(() => {
    if (((location.state as any)?.recommendations as TAccountRecommendationExtended[])?.length > 0) {
      const filterString = `(${(location.state as any)?.recommendations
        .map((item: TAccountRecommendationExtended) => `id eq ${item.id}`)
        .join(' or ')})`;

      setAlertForm({ ...alertForm, filters: filterString, scope: 'specific_recommendation' });
      return;
    }

    if ((location.state as any)?.filters as string) {
      const filterString = (location.state as any)?.filters + ' and type=1';
      setAlertForm({ ...alertForm, filters: filterString, scope: 'classification_rules' });
    }

    if (!isCreate) {
      setAlertDefaults();
    }
  }, [location, alertData]);

  useEffect(() => {
    if (createSuccess) {
      navigate(-1);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      navigate(-1);
    }
  }, [updateSuccess]);

  return {
    isCTADisabled,
    isScopingTabDisabled,
    isLoading: isCreate ? createLoading : updateLoading,
    alertForm,
    changeAlertFormDetails,
    createAlert: onCreateAlert,
    updateAlert: onUpdateAlert,
    deleteAlert: onDeleteAlert,
  };
}
