import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Spin } from 'antd';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
`;

const ConfirmationMessage = styled.span`
  font-size: 24px;
  font-weight: 800;
  line-height: 49px;
  color: #272727;
  text-align: center;
  margin: 0px 80px;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  border: 1px solid #d81c2e;
  border-radius: 10px;
  background-color: #d81c2e;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

interface IActionConfirmationModalProps extends ModalFuncProps {
  isLoading?: boolean;
  message?: string;
}

export default function ActionConfirmationModal({ isLoading, message, ...modalProps }: IActionConfirmationModalProps) {
  return (
    <Modal
      style={{
        minWidth: '400px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container>
        <ConfirmationMessage>{message}</ConfirmationMessage>
        <HorizontalAlignedContainer style={{ gap: '10px', marginTop: '90px' }}>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton onClick={modalProps.onCancel}>No</CancelButton>
              <ConfirmButton onClick={modalProps.onOk}>Yes</ConfirmButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
