import { Modal, ModalFuncProps } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { TInsight } from '@app/types/insight';
import { Link } from 'react-router-dom';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

interface IInsightDetailsModalProps extends ModalFuncProps {
  insight?: TInsight;
  open: boolean;
  onClose?: () => void;
}

export default function InsightDetailsModal({ insight, ...modalProps }: IInsightDetailsModalProps) {
  return (
    <Modal
      title="Insight Details"
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: '#D81C2E', marginLeft: '12px' }} />}
      footer={null}
      width="70vw"
    >
      <ColumnsContainer>
        <Col>
          <Label>Summary: </Label>
          <Value>{insight?.title}</Value>
        </Col>
        <Col>
          <Label>Description: </Label>
          <Value>{insight?.description}</Value>
        </Col>
        {insight?.reference && (
          <Col>
            <Label>Link: </Label>
            <Link to={`${insight?.reference}`} target="__blank">
              {insight?.reference}
            </Link>
          </Col>
        )}
      </ColumnsContainer>
    </Modal>
  );
}
