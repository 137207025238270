import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import styled from 'styled-components';

const Text = styled.h1`
  font-size: 1em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return user ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[5, 5]} align="stretch">
        <ProfileWrapper>
          <UserOutlined
            style={{
              fontSize: 24,
              marginRight: '4px',
            }}
          />
          {isTablet && (
            <S.EmailCol>
              <Text>{user.email}</Text>
            </S.EmailCol>
          )}
        </ProfileWrapper>
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
