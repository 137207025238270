import styled, { css } from 'styled-components';

import { BaseButton } from '../BaseButton/BaseButton';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

const COLORS = {
  default: '#4C63EC',
  danger: '#D81C2E',
  dark: '#272727',
  success: '#30AF5B',
  warning: '##FFB155',
  lightGray: '#ACACAC',
  white: '#FFFFFF',
};

type ButtonProps = {
  $variant?: string;
  $color?: string;
  $btnSize?: 'default' | 'md' | 'sm' | 'lg';
};

export const StyledButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: ${FONT_WEIGHT.semibold};
  position: relative;
  ${({ $btnSize }: ButtonProps) => ButtonSize($btnSize)}
  ${({ $variant, $color }: ButtonProps) => ButtonVariant($variant, $color)}
`;

const ButtonSize = (size = 'default') =>
  ({
    default: css`
      padding: 0 1rem;
      font-size: 1rem;
      height: 2.75rem;
      border-radius: 0.625rem;
    `,
    sm: css`
      padding: 0 0.625rem;
      font-size: 0.75rem;
      height: 1.875rem;
      border-radius: 0.5rem;
    `,
    md: css`
      font-size: 1rem;
      height: 2.313rem;
      padding: 0 1rem;
      border-radius: 0.625rem;
    `,
    lg: css`
      padding: 0 2rem;
      font-size: 1.125rem;
      height: 3.25rem;
      border-radius: 1rem;
    `,
  }[size]);

const ButtonVariant = (variant = 'default', color = 'default') => {
  switch (variant) {
    case 'default':
      return css`
        border: ${ColorDefault(color)?.border};
        background-color: ${ColorDefault(color)?.backgroundColor};
        color: ${ColorDefault(color)?.color};
        &:hover {
          border: ${ColorDefault(color)?.hover.border};
          background-color: ${ColorDefault(color)?.hover.backgroundColor};
          color: ${COLORS.white};
        }
        &:focus {
          border: ${ColorDefault(color)?.hover.border};
          background-color: ${ColorDefault(color)?.hover.backgroundColor};
          color: ${COLORS.white};
        }
      `;
    case 'outlined':
      return css`
        border: ${ColorOutlined(color)?.border};
        background-color: ${ColorOutlined(color)?.backgroundColor};
        color: ${ColorOutlined(color)?.color};
        &:hover {
          border: ${ColorOutlined(color)?.hover.border};
          background-color: ${ColorOutlined(color)?.hover.backgroundColor};
          color: ${ColorOutlined(color)?.hover.color};
        }
        &:focus {
          border: ${ColorOutlined(color)?.hover.border};
          background-color: ${ColorOutlined(color)?.hover.backgroundColor};
          color: ${ColorOutlined(color)?.hover.color};
        }
      `;
    case 'light':
      return css`
        border: ${ColorLight(color)?.border};
        background-color: ${ColorLight(color)?.backgroundColor};
        color: ${ColorLight(color)?.color};
        &:hover {
          border: ${ColorLight(color)?.hover.border};
          background-color: ${ColorLight(color)?.hover.backgroundColor};
          color: ${ColorLight(color)?.hover.color};
        }
        &:focus {
          border: ${ColorLight(color)?.hover.border};
          background-color: ${ColorLight(color)?.hover.backgroundColor};
          color: ${ColorLight(color)?.hover.color};
        }
      `;
    case 'ghost':
      return css`
        border: ${ColorGhost(color)?.border};
        background-color: ${ColorGhost(color)?.backgroundColor};
        color: ${ColorGhost(color)?.color};
        &:hover {
          border: ${ColorGhost(color)?.hover.border};
          background-color: ${ColorGhost(color)?.hover.backgroundColor};
          color: ${ColorGhost(color)?.hover.color};
        }
        &:focus {
          border: ${ColorGhost(color)?.hover.border};
          background-color: ${ColorGhost(color)?.hover.backgroundColor};
          color: ${ColorGhost(color)?.hover.color};
        }
      `;
    default:
      break;
  }
};

const ColorDefault = (color = 'default') => {
  switch (color) {
    case 'default':
      return {
        border: `1px solid ${COLORS.default}`,
        backgroundColor: COLORS.default,
        color: COLORS.white,
        hover: {
          border: `1px solid #283DAE`,
          backgroundColor: '#283DAE',
        },
      };
    case 'danger':
      return {
        border: `1px solid ${COLORS.danger}`,
        backgroundColor: COLORS.danger,
        color: COLORS.white,
        hover: {
          border: `1px solid #A30F1B`,
          backgroundColor: '#A30F1B',
        },
      };
    case 'dark':
      return {
        border: `1px solid ${COLORS.dark}`,
        backgroundColor: COLORS.dark,
        color: COLORS.white,
        hover: {
          border: `1px solid #1D1D1D`,
          backgroundColor: '#1D1D1D',
        },
      };
    case 'success':
      return {
        border: `1px solid ${COLORS.success}`,
        backgroundColor: COLORS.success,
        color: COLORS.white,
        hover: {
          border: `1px solid #299650`,
          backgroundColor: '#299650',
        },
      };
    default:
      break;
  }
};

const ColorOutlined = (color = 'default') => {
  switch (color) {
    case 'default':
      return {
        border: `1px solid ${COLORS.default}`,
        backgroundColor: COLORS.white,
        color: COLORS.default,
        hover: {
          border: `1px solid #283DAE`,
          backgroundColor: '#283DAE',
          color: COLORS.white,
        },
      };
    case 'danger':
      return {
        border: `1px solid ${COLORS.danger}`,
        backgroundColor: COLORS.white,
        color: COLORS.danger,
        hover: {
          border: `1px solid #A30F1B`,
          backgroundColor: '#A30F1B',
          color: COLORS.white,
        },
      };
    case 'dark':
      return {
        border: `1px solid ${COLORS.dark}`,
        backgroundColor: COLORS.white,
        color: COLORS.dark,
        hover: {
          border: `1px solid #1D1D1D`,
          backgroundColor: '#1D1D1D',
          color: COLORS.white,
        },
      };
    case 'success':
      return {
        border: `1px solid ${COLORS.success}`,
        backgroundColor: COLORS.white,
        color: COLORS.success,
        hover: {
          border: `1px solid #299650`,
          backgroundColor: '#299650',
          color: COLORS.white,
        },
      };
    default:
      break;
  }
};

const ColorLight = (color = 'default') => {
  switch (color) {
    case 'default':
      return {
        border: `1px solid #BAC2EF`,
        backgroundColor: '#BAC2EF',
        color: COLORS.default,
        hover: {
          border: `1px solid #283DAE`,
          backgroundColor: '#283DAE',
          color: COLORS.white,
        },
      };
    case 'danger':
      return {
        border: `1px solid #F8B8C4`,
        backgroundColor: '#F8B8C4',
        color: COLORS.danger,
        hover: {
          border: `1px solid #A30F1B`,
          backgroundColor: '#A30F1B',
          color: COLORS.white,
        },
      };
    case 'dark':
      return {
        border: `1px solid #DADADA`,
        backgroundColor: '#DADADA',
        color: COLORS.dark,
        hover: {
          border: `1px solid #1D1D1D`,
          backgroundColor: '#1D1D1D',
          color: COLORS.white,
        },
      };
    case 'success':
      return {
        border: `1px solid #D6EFDE`,
        backgroundColor: '#D6EFDE',
        color: COLORS.success,
        hover: {
          border: `1px solid #299650`,
          backgroundColor: '#299650',
          color: COLORS.white,
        },
      };
    default:
      break;
  }
};

const ColorGhost = (color = 'default') => {
  switch (color) {
    case 'default':
      return {
        border: `1px solid transparent`,
        backgroundColor: 'transparent',
        color: COLORS.default,
        hover: {
          border: `1px solid #E9EAEB`,
          backgroundColor: '#E9EAEB',
          color: COLORS.default,
        },
      };
    case 'danger':
      return {
        border: `1px solid transparent`,
        backgroundColor: 'transparent',
        color: COLORS.danger,
        hover: {
          border: `1px solid #E9EAEB`,
          backgroundColor: '#E9EAEB',
          color: COLORS.danger,
        },
      };
    case 'dark':
      return {
        border: `1px solid transparent`,
        backgroundColor: 'transparent',
        color: COLORS.dark,
        hover: {
          border: `1px solid #E9EAEB`,
          backgroundColor: '#E9EAEB',
          color: COLORS.dark,
        },
      };
    case 'success':
      return {
        border: `1px solid transparent`,
        backgroundColor: 'transparent',
        color: COLORS.success,
        hover: {
          border: `1px solid #E9EAEB`,
          backgroundColor: '#E9EAEB',
          color: COLORS.success,
        },
      };
  }
};
