import { useQuery } from 'react-query';
import { httpApi } from './http.api';
import { TChangeLogsByDate } from '@app/types/changelogs';

export const getChangeLogs = async (changeLogDate: string): Promise<TChangeLogsByDate[] | undefined> => {
  const logs = await httpApi.get(`change-log/recommendationModelChanges/${changeLogDate}`);
  return logs.data;
};

export const useGetChangeLogs = (changeLogDate: string) =>
  useQuery({
    queryKey: changeLogDate,
    queryFn: () => getChangeLogs(changeLogDate),
  });
