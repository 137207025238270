import useToggle from '@app/hooks/useToggle';
import { TManageAccountsForm } from './ManageAccountsForm';
import { TManageAccounts } from '@app/types/manageAccounts';

interface IUseManageAccountsProps {
  onCancel?: () => void;
  onOk?: (data: TManageAccountsForm) => void;
  account?: TManageAccounts;
}

export default function useManageAccountsForm(props?: IUseManageAccountsProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TManageAccountsForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    account: props?.account,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
