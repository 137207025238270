import { EnumValuesModel } from '@app/domain/EnumValuesModel';
import { httpApi } from './http.api';
import { TRoleAndScreen } from '@app/types/roleAndScreen';
import { TList } from '@app/types/generalTypes';

export const getEnumValues = async (): Promise<EnumValuesModel | null> => {
  try {
    const response = await httpApi.get<EnumValuesModel>(`/enums`);
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const getRolesAndScreens = async (): Promise<TRoleAndScreen[] | null> => {
  try {
    const response = await httpApi.get<TList<TRoleAndScreen>>(`/odata/RolesAndScreens`);
    return response?.data?.items;
  } catch (error) {
    return null;
  }
};
