import { useState } from 'react';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TSettings } from '@app/types/settings';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import useSettingsForm from '@app/components/apps/manage-settings/useSettingsForm';
import SettingsForm, { TSettingsForm } from '@app/components/apps/manage-settings/SettingsForm';
import { useUpdateSettings } from '@app/api/settings.api';
import ConfirmationModal from '@app/components/apps/manage-settings/ConfirmationModal';

const manageSettingsTableCols: TDataColumns<TSettings> = [
  {
    title: 'Settings',
    dataIndex: 'displayName',
    type: 'text',
    allowFiltering: true,
    width: '8vw',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    type: 'text',
    allowFiltering: true,
    render: (text: string) => (text.length > 40 ? `${text.substring(0, 40)}...` : text),
    width: '5vw',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    type: 'text',
    allowFiltering: true,
    render: (text: string) => (text.length > 60 ? `${text.substring(0, 60)}...` : text),
    width: '15vw',
  },
];

const ManageSettingsPage = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState<TSettings | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { mutateAsync: updateSettings, isLoading } = useUpdateSettings();

  const { toggle, ...settingsFormProps } = useSettingsForm({
    onOk: async (data: TSettingsForm) => {
      setSelectedSettings({
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        value: data.value,
      });
      setIsConfirmationModalOpen(true);
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedSettings(undefined);
      toggle();
    },
    settings: selectedSettings,
  });

  const handleConfirmUpdate = async () => {
    if (!!selectedSettings) {
      await updateSettings({ name: selectedSettings.name, data: selectedSettings });
      setIsEdit(false);
      setSelectedSettings(undefined);
      toggle();
      setIsConfirmationModalOpen(false);
      dataTableProps.refetch();
      dataTableProps.clearSelection();
    }
  };

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const actionItems: TActionItems<TSettings>[] = [
    {
      key: 'settings',
      label: 'Edit',
      multiSelect: false,
      onClick: (record) => {
        setIsEdit(true);
        setSelectedSettings(record);
        toggle();
      },
    },
  ];

  const dataTableProps = useDataTable<TSettings & { id: string | number }>({
    model: 'Settings',
    columns: manageSettingsTableCols as TDataColumns<TSettings & { id: string | number }>,
    searchableColumns: ['displayName'],
    actionItems,
    onRowClick: (record: TSettings & { id: string | number }) => {
      setIsEdit(true);
      setSelectedSettings(record);
      toggle();
    },
  });

  return (
    <>
      <PageTitle>Manage Settings</PageTitle>
      <ComponentHeader
        title="Settings Management"
        subTitle="Change values in the screen to control different aspects of the system behavior."
      />
      <DataTable {...dataTableProps} />
      <SettingsForm {...settingsFormProps} isEdit={isEdit} isLoading={isLoading} settings={selectedSettings} />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        message="Are you sure you want to update this setting?"
        onOk={handleConfirmUpdate}
        onCancel={handleModalClose}
        isLoading={isLoading}
      />
    </>
  );
};

export default ManageSettingsPage;
