import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TRecommendationHistory } from '@app/types/recommendationHistory';
import { getProductLabel } from '@app/utils/utils';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import { TimeLine } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import EventDetailsModal from '@app/components/apps/change-logs/EventDetailsModal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { format, isDate, parse } from 'date-fns';

export default function RecommendationHistory() {
  const { t } = useTranslation();
  const productOptions = useAppSelector((state) => state.app.products)?.map((opt) => opt.displayName);

  const [hoveredDate, setHoveredDate] = useState<string | undefined>();
  const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);
  const [changeLogDate, setChangeLogDate] = useState<string | undefined>();
  const [timelineRange, setTimelineRange] = useState<{ startDate: Date; endDate: Date } | undefined>({
    startDate: moment(new Date()).subtract(90, 'days').toDate(),
    endDate: moment(new Date()).toDate(),
  });

  const customQueryKey = useMemo(() => {
    return `accountRecommendationHistory-odata date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()}`;
  }, [timelineRange]);

  const tableCols: TDataColumns<TRecommendationHistory> = [
    {
      title: 'Time',
      dataIndex: 'date',
      allowFiltering: true,
      type: 'datetime',
      render(value, record) {
        return <>{format(parse(record.date as any, 'dd/MM/yyyy HH:mm:ss', new Date()), 'dd/MM/yyyy HH:mm')}</>;
      },
      showSortDirections: true,
      width: '10vw',
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '18vw',
    },
    {
      title: 'Change Type',
      dataIndex: 'changeType',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      align: 'center',
      width: '8vw',
    },
    {
      title: 'Change Description',
      dataIndex: 'changeDescription',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '10vw',
    },
    {
      title: 'Change Field',
      dataIndex: 'changeField',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
    {
      title: 'Snapshot Type',
      dataIndex: 'snapshotType',
      allowFiltering: false,
      showSortDirections: true,
      width: '8vw',
      allowDisplay: false,
      hideColumn: true,
    },
    {
      title: 'Score',
      dataIndex: 'scoreValue',
      allowFiltering: true,
      type: 'number',
      showSortDirections: true,
      render(value, record) {
        return <>{`${record.scoreValue} pts`}</>;
      },
      width: '8vw',
      align: 'center',
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      filterOptions: productOptions,
      render(_, record) {
        const productName = getProductLabel(record.productName);
        return <>{productName || record.productName}</>;
      },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: '8vw',
      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   align: 'center' as const,
    //   render: (_, record) => {
    //     return <ActionDropdown items={items} record={record} />;
    //   },
    // },
  ];

  const dataTableProps = useDataTable<TRecommendationHistory>({
    model: 'accountRecommendationHistory',
    hideExportToExcel: true,
    columns: tableCols,
    searchableColumns: ['productName', 'findings'],
    defaultOrder: {
      column: 'date',
      order: 'desc',
    },
    limit: 50,
    showTimeline: true,
    customQueryKey: customQueryKey,
    constantFilter: `date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()}`,
    stickyHeader: true,
    onTimelineRangeChanged(newRange) {
      if (!newRange) return;

      setTimelineRange(newRange);
    },
    onRowMouseEnter(data, index) {
      setHoveredDate(data.date);
    },
    onRowMouseLeave(data, index) {
      setHoveredDate(undefined);
    },
  });

  const handleAdditionalDetailsClicked = (changeLogDate: string) => {
    setChangeLogDate(changeLogDate);
    setEventDetailsModalOpen(true);
  };

  const handleAdditionalDetailsModalClose = () => {
    setEventDetailsModalOpen(false);
    setChangeLogDate(undefined);
  };

  useEffect(() => {
    if (!timelineRange) return;

    dataTableProps.refetch();
  }, [timelineRange]);

  return (
    <>
      <PageTitle>Recommendation History</PageTitle>
      <ComponentHeader
        title={t('common.recommendationHistory')}
        subTitle="View the history of security recommendation changes, including compliance shifts and exemptions, to understand the evolution of your organization's security posture. Track the dates and details of each state change for comprehensive auditing."
        subTitleFullWidth={true}
      />
      <DataTable {...dataTableProps}>
        {({ filterProps }) => {
          if (!filterProps?.timelineRange) {
            return <></>;
          }

          return (
            <TimeLine
              handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
              startDate={filterProps?.timelineRange?.startDate}
              endDate={filterProps?.timelineRange?.endDate}
              hoveredDate={hoveredDate}
              setHoveredDate={setHoveredDate}
            />
          );
        }}
      </DataTable>
      {eventDetailsModalOpen && changeLogDate && (
        <EventDetailsModal changeLogDate={changeLogDate} open={true} onCancel={handleAdditionalDetailsModalClose} />
      )}
    </>
  );
}
