import { BASE_COLORS, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { RiArrowDownSLine } from 'react-icons/ri';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiSave } from 'react-icons/bi';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

export const DetailsWrapper = styled.div`
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: ${BASE_COLORS.white};
  border: 1px solid ${BASE_COLORS.ashgray};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const Label = styled.span`
  font-weight: ${FONT_WEIGHT.bold};
  flex-basis: 12.5rem;
`;

export const AlertName = styled.input`
  padding: 0.65rem 1rem;
  border-radius: 0.7rem;
  border: 1px solid ${BASE_COLORS.gray};
  width: 630px;
  background-color: white;
`;

export const StyledSelect = styled(BaseSelect)`
  display: flex;
  align-items: center;
  width: 8.75rem;
  color: ${BASE_COLORS.indigo};

  .ant-select-selector {
    border-radius: 0.625rem !important;
    border: 1px solid ${BASE_COLORS.indigo} !important;
    height: 2.75rem !important;
  }

  .ant-select-selection-item {
    font-weight: ${FONT_WEIGHT.semibold} !important;
    line-height: 43px !important;
  }
`;

export const ArrowIcon = styled(RiArrowDownSLine)`
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  color: ${BASE_COLORS.indigo};
`;

export const DetailsActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledBaseButton = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
`;

export const DeleteButton = styled(StyledBaseButton)`
  border: 1px solid #272727 !important;
  color: #272727 !important;
`;

export const DeleteIcon = styled(RiDeleteBinLine)`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: -1px;
  color: #d81c2e;
`;

export const SaveIcon = styled(BiSave)`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: -1px;
`;

export const DeleteModalContent = styled(BaseModal)`
  max-width: 31.25rem;
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const DeleteModalMessage = styled.div`
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
  text-align: center;
`;

export const DeleteModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;
