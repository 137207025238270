import { useMutation, useQueryClient } from 'react-query';
import { TSettingsForm } from '../components/apps/manage-settings/SettingsForm';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSettings,
    onSuccess: () => {
      queryClient.invalidateQueries('settings-odata');
    },
  });
};

const updateSettings = async (payload: { name: string; data: TSettingsForm }) => {
  const { name, data } = payload;

  httpApi
    .patch<any>(`/settings/${name}`, data)
    .then((response) => {
      if (response?.status === 200) {
        notificationController.success({
          message: 'Settings updated',
        });
        return response.data;
      }
    })
    .catch((error) => {
      console.error(error);
      notificationController.error({
        message: 'Something went wrong',
      });
      return undefined;
    });
};
