import { Collapse, Modal, ModalFuncProps } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

import { useGetChangeLogs } from '@app/api/changelogs';
import { getDetailedChanges } from '@app/utils/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

const StyledCollapse = styled(Collapse)`
  background-color: white;
  margin-top: 4px;
`;

const CauseContainer = styled.ol`
  padding: 0px 16px;
`;

interface IManageAccountsProps extends ModalFuncProps {
  changeLogDate: string;
}

export default function EventDetailsModal({ changeLogDate, ...modalProps }: IManageAccountsProps) {
  const { data } = useGetChangeLogs(format(changeLogDate, 'yyyy-MM-dd'));

  const handleCancel = () => {
    modalProps.onCancel?.();
  };

  return (
    <Modal
      title="Event Details"
      style={{
        minWidth: '900px',
      }}
      {...modalProps}
      destroyOnClose={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Container>
        <DetailContainer>
          <Label>Date</Label>
          <Value>{format(changeLogDate, 'yyyy-MM-dd')}</Value>
        </DetailContainer>
        <DetailContainer>
          <Label>Details</Label>
          <StyledCollapse>
            {data?.map((d, i) => (
              <Collapse.Panel header={d.details} key={i}>
                <div>
                  <Label>Time:</Label>
                  <CauseContainer>{format(d.time, 'yyyy-MM-dd HH:mm')}</CauseContainer>
                </div>
                <div>
                  <Label>Possible Cause:</Label>
                  <CauseContainer>
                    {d.changes && d.changes.length > 0
                      ? d.changes.map((changes, i) => (
                          <li key={i}>
                            <Value>{getDetailedChanges(changes)}</Value>
                          </li>
                        ))
                      : 'No additional details are available'}
                  </CauseContainer>
                </div>
              </Collapse.Panel>
            ))}
          </StyledCollapse>
        </DetailContainer>
      </Container>
    </Modal>
  );
}
