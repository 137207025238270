/* eslint-disable react/no-unescaped-entities */
import * as S from './LoginForm.styles';
import Logo from '../../../assets/logo.png';
import MicrosoftIcon from '../../../assets/images/MicrosoftIcon.png';
import { httpApi } from '@app/api/http.api';
import { useEffect } from 'react';
import { displayMissingPermissionsNotification } from '@app/utils/utils';

export const LoginForm = () => {
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  useEffect(() => {
    const missingPermissions = params.get('missingPermissions');
    if (missingPermissions) {
      displayMissingPermissionsNotification();
    }
  }, []);

  const handleSSOLogin = async () => {
    const response = await httpApi.post('/microsoft/login');
    if (response?.status == 200) {
      const url = response.data;
      window.location.href = url;
    }
  };

  return (
    <S.LoginContainer>
      <S.LoginBackground></S.LoginBackground>
      <S.LoginForm>
        <S.Logo src={Logo} alt="Logo" />
        <S.LoginFormWrapper>
          <S.LoginTitle>Login</S.LoginTitle>
          <S.LoginDescription>Login using your Azure credentials</S.LoginDescription>
          <S.ErrorMessage>{error}</S.ErrorMessage>
          <S.SocialLogin variant="outlined" color="dark" size="large" onClick={handleSSOLogin}>
            <S.MicrosoftIcon src={MicrosoftIcon} alt="Microsoft Icon" />
            Continue with Microsoft
          </S.SocialLogin>
          {/* <S.SignupText>
            Don't have a user? <Link to="/auth/sign-up">Click here to signup.</Link>
          </S.SignupText> */}
        </S.LoginFormWrapper>
      </S.LoginForm>
    </S.LoginContainer>
  );
};
