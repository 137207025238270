import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import HomePage from '@app/pages/HomePage';
import AlertDynamicPage from '@app/pages/AlertDynamicPage';
import ManageUsersPage from '@app/pages/ManageUsersPage';
import ManageAccountsPage from '@app/pages/ManageAccountsPage';
import ManageRecommendationsPage from '@app/pages/ManageRecommendationsPage';
import InsightsManagementPage from '@app/pages/InsightsManagementPage';
import ManageSettingsPage from '@app/pages/ManageSettingsPage';
import JoinPage from '@app/pages/JoinPage';
import ImpersonateUserPage from '@app/pages/ImpersonateUserPage';
import RecommendationHistoryPage from '@app/pages/RecommendationHistory';
import ManageReportsPage from '@app/pages/ManageReportsPage';
import InsightsPage from '@app/pages/Insights';
import ReportsPage from '@app/pages/ReportsPage';
import ManageVendorsPage from '@app/pages/ManageVendorsPage';
import RegulationsCompliancePage from '@app/pages/RegulationsCompliancePage';
import ManageRegulationsPage from '@app/pages/ManageRegulationsPage';
import GeneralSettingsPage from '@app/pages/GeneralSettings';

const DataConnectorsPage = React.lazy(() => import('@app/pages/DataConnectorsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/AlertsPage'));
const RecommendationsPage = React.lazy(() => import('@app/pages/RecommendationsPage'));
const ActiveRecommendationsPage = React.lazy(() => import('@app/pages/ActiveRecommendationsPage'));
const ExemptedRecommendationsPage = React.lazy(() => import('@app/pages/ExemptedRecommendationsPage'));
const RegulationInformationPage = React.lazy(() => import('@app/pages/RegulationInformationPage'));
const AlertsHistoryPage = React.lazy(() => import('@app/pages/AlertsHistoryPage'));
const ActiveAlertsPage = React.lazy(() => import('@app/pages/ActiveAlertsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const StoryPage = React.lazy(() => import('@app/pages/StoryPage'));
const StoryRecommendationsPage = React.lazy(() => import('@app/pages/StoryRecommendationsPage'));
const ManageStoriesPage = React.lazy(() => import('@app/pages/ManageStoriesPage'));

export const DASHBOARD_PATH = '/';

const Home = withLoading(HomePage);
const Recommendations = withLoading(RecommendationsPage);
const ActiveRecommendations = withLoading(ActiveRecommendationsPage);
const ExemptedRecommendations = withLoading(ExemptedRecommendationsPage);
const RegulationInformation = withLoading(RegulationInformationPage);
const DataConnectors = withLoading(DataConnectorsPage);
const Alerts = withLoading(AlertsPage);
const RecommendationHistory = withLoading(RecommendationHistoryPage);
const AlertsHistory = withLoading(AlertsHistoryPage);
const ActiveAlerts = withLoading(ActiveAlertsPage);
const Reports = withLoading(ReportsPage);
const Insights = withLoading(InsightsPage);
const RegulationsCompliance = withLoading(RegulationsCompliancePage);
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const Story = withLoading(StoryPage);
const StoryRecommendations = withLoading(StoryRecommendationsPage);
const GeneralSettings = withLoading(GeneralSettingsPage);

// admin pages
const ManageAccounts = withLoading(ManageAccountsPage);
const ManageRecommendations = withLoading(ManageRecommendationsPage);
const InsightsManagement = withLoading(InsightsManagementPage);
const ManageReports = withLoading(ManageReportsPage);
const ManageVendors = withLoading(ManageVendorsPage);
const ManageRegulations = withLoading(ManageRegulationsPage);
const ManageStories = withLoading(ManageStoriesPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const MainLayoutFallback = withLoading(MainLayout);

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={<MainLayoutFallback />}>
          <Route path="dashboard" index element={<Home />} />
          <Route path="recommendations" element={<Recommendations />}>
            <Route path=":recommendationId" element={<Recommendations />} />
          </Route>
          <Route path="active-recommendations" element={<ActiveRecommendations />} />
          <Route path="exempted-recommendations" element={<ExemptedRecommendations />} />
          <Route path="regulation-information/:regulationId" element={<RegulationInformation />} />
          <Route path="data-connectors" element={<DataConnectors />} />
          <Route path="manage-alerts" element={<Alerts />} />
          <Route path="recommendation-history" element={<RecommendationHistory />} />
          <Route path="reports" element={<Reports />} />
          <Route path="insights" element={<Insights />}>
            <Route path=":insightId" element={<Insights />} />
          </Route>
          <Route path="regulations-compliance" element={<RegulationsCompliance />} />
          <Route path="manage-accounts" element={<ManageAccounts />} />
          <Route path="manage-recommendations" element={<ManageRecommendations />} />
          <Route path="manage-insights" element={<InsightsManagement />} />
          <Route path="manage-vendors" element={<ManageVendors />} />
          <Route path="manage-reports" element={<ManageReports />} />
          <Route path="manage-regulations" element={<ManageRegulations />} />
          <Route path="manage-settings" element={<ManageSettingsPage />} />
          <Route path="alert/update/:id" element={<AlertDynamicPage />} />
          <Route path="settings/manage-users" element={<ManageUsersPage />} />
          <Route path="alert/create" element={<AlertDynamicPage />} />
          <Route path="alerts-history" element={<AlertsHistory />}>
            <Route path=":historyId" element={<AlertsHistory />} />
          </Route>

          <Route path="stories" element={<Story />} />
          <Route path="settings/general" element={<GeneralSettings />} />
          <Route path="story/:storyId" element={<StoryRecommendations />} />
          <Route path="manage-stories" element={<ManageStories />} />
          <Route path="active-alerts" element={<ActiveAlerts />}>
            <Route path=":alertId" element={<ActiveAlerts />} />
          </Route>
          {/* <Route path="server-error" element={<ServerError />} /> */}
          <Route path="404" element={<Error404 />} />
          <Route path="impersonate" element={<ImpersonateUserPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="join" element={<JoinPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
