import { httpApi } from '@app/api/http.api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { logout, setUser } from '@app/store/slices/userSlice';
import { UserModel } from '@app/domain/UserModel';
import { useNavigate } from 'react-router-dom';
import { displayMissingPermissionsNotification } from '@app/utils/utils';

export interface ImpersonateResult {
  token: string;
  isMissingPermissions: boolean;
}

const impersonateUser = async (accountId: string): Promise<ImpersonateResult> => {
  const response = await httpApi.post<ImpersonateResult>('/auth/impersonate', { accountId }, { withCredentials: true });
  if (!response || !response.data) {
    throw new Error('impersonateUser null response');
  }
  return response.data;
};

export const useImpersonateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: impersonateUser,
    onSuccess: async (data) => {
      sessionStorage.setItem('impersonatedUserToken', data.token);

      const newWhoAmI = await whoAmI();
      if (!!newWhoAmI) {
        dispatch(setUser(newWhoAmI));
      }

      if (data.isMissingPermissions) {
        displayMissingPermissionsNotification();
      }

      navigate('/');
    },
    onError(err) {
      console.log(err);
      navigate('/404');
    },
  });
};

export const joinMicrosoftUser = async (token: string): Promise<string> => {
  const response = await httpApi.post('/microsoft/join', { token }, { withCredentials: true });
  return response?.data;
};

export const signUp = (signUpData?: any): Promise<string> =>
  httpApi.post<string>('/microsoft/signup', signUpData).then(({ data }) => data);

export const setLoginCookies = async (form: { email: string; password: string }) => {
  try {
    const response = await httpApi.post('/auth/login', form, { withCredentials: true });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const whoAmI = async (): Promise<UserModel | null> => {
  try {
    const response = await httpApi.get('/auth/whoami', { withCredentials: true });
    if (response) {
      return response.data;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useWhoAmI = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useQuery({
    queryFn: whoAmI,
    queryKey: 'whoami',
    refetchOnWindowFocus: false,
    onSuccess(data) {
      dispatch(setUser(data));
    },
    onError(err) {
      console.log(err);
      dispatch(logout());
      queryClient.invalidateQueries('whoami');
    },
  });
};

const doLogout = async (): Promise<void> => {
  try {
    const response = await httpApi.post('/auth/logout', null);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: doLogout,
    onSuccess() {
      dispatch(logout());
      queryClient.invalidateQueries('whoami');
    },
  });
};
