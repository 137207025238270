import { useQuery } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { TList } from '@app/types/generalTypes';

export const getAllLicenses = async (): Promise<TEnumEntity[]> => {
  const response = await httpApi.get<TList<TEnumEntity>>('/odata/license');
  return response?.data?.items;
};

export const useGetAllLicenses = () =>
  useQuery({
    queryKey: ['get-all-licenses'],
    queryFn: () => getAllLicenses(),
  });
