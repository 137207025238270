import { store } from '../store/store';

export const getEnumValue = (enumKey: string, key: string) => {
  const enumValues = store.getState().app.appValues;
  return (enumValues?.[enumKey] as { label: string; value: number }[])?.find((val) => val.label === key)?.value;
};

export const getEnumLabel = (enumKey: string, value: number) => {
  const enumValues = store.getState().app.appValues;
  return (enumValues?.[enumKey] as { label: string; value: number }[])?.find((val) => val.value == value)?.label;
};
