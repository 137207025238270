import { BASE_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

type SeparatorProps = React.HTMLAttributes<HTMLDivElement>;

const Separator = ({ ...props }: SeparatorProps) => {
  return <Line {...props} />;
};

export default Separator;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${BASE_COLORS.ashgray};
`;
