import { PieChart, Pie, Cell } from 'recharts';

import { TWidgetData } from '@app/types/widget';

interface IPieChartCardProps {
  data: TWidgetData['data'];
  score: number | string;
  title: string;
  hideLegend?: boolean;
}

export function PieChartCard({ data, score, title, hideLegend = false }: IPieChartCardProps) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '10px',
          overflow: 'auto',
          scrollbarWidth: 'none',
        }}
      >
        {/* PIECHART */}
        <div style={{ position: 'relative', border: 'none', flexShrink: 0 }}>
          <h1
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-54%)',
              fontSize: '1.2rem',
            }}
          >
            {score}
          </h1>
          <PieChart width={60} height={60} style={{ border: 'none' }}>
            <Pie data={data} innerRadius={25} outerRadius={30} paddingAngle={0} dataKey="value">
              {data.map((entry: any, index: number) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
            {/* <Tooltip content={PieWidgetCustomTooltip} cursor={{ strokeDasharray: '20 20' }} /> */}
          </PieChart>
        </div>
        {/* DETAILS */}
        <div style={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
          <span
            style={{
              fontWeight: 700,
              fontSize: '0.875rem',
              color: '#303030',
              marginBottom: '8px',
            }}
          >
            {title}
          </span>

          {!hideLegend ? (
            <div style={{ display: 'flex', gap: '10px', width: 'fit-content', fontSize: '.563rem' }}>
              {data.map((item: any) => (
                <div key={item.name} style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                  <div style={{ width: '15px', height: '15px', background: item.color, borderRadius: '4px' }} />
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
