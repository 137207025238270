import { Popover, Progress } from 'antd';

import { Container, StatsContainer, StatsTitle } from './HeaderStats.styles';
import ScoreIcon from '@app/components/icons/score/ScoreIcon';
import ConnectorsIcon from '@app/components/icons/connectors/ConnectorsIcon';
import { useGetAccountStatusInfo } from '@app/api/account.api';
import { useEffect, useState } from 'react';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import WarningIcon from '@app/components/icons/warning/WarningIcon';
import Modal from 'antd/lib/modal/Modal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MicrosoftAppForm } from './MicrosoftAppForm';

const alpha = '14';
const green = '#30af5b';
const red = '#ff0000';
const blue = '#3a50d1';

const TooltipContainer = styled.div`
  max-width: 30vw;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #272727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const STANDARD_SECURITY_DRILLDOWN_FILTER = [
  {
    column: 'isCompliant',
    value: false,
    type: 'boolean',
    title: 'Compliant',
  },
];

const CUSTOM_SECURITY_DRILLDOWN_FILTER = [
  {
    column: 'isCompliant',
    value: false,
    type: 'boolean',
    title: 'Compliant',
  },
  {
    column: 'isExempted',
    value: false,
    type: 'boolean',
    title: 'Exempted',
  },
];

const ORDER_BY_SEVERITY_DESC = {
  column: 'severity',
  order: 'desc',
};

export function HeaderStats() {
  const [accountStatusInfo, setAccountStatusInfo] = useState<TAccountStatusInfo>();
  const userRole = useAppSelector((state) => state.user.user?.role);

  const { data, isSuccess, isFetching, refetch } = useGetAccountStatusInfo(
    !!userRole && userRole !== getEnumValue('UserRole', 'SuperAdmin'),
  );

  useEffect(() => {
    if (isSuccess) {
      setAccountStatusInfo(data);
    }
  }, [data, isSuccess]);

  if (userRole && userRole === getEnumValue('UserRole', 'SuperAdmin') && !isFetching) {
    return (
      <span
        style={{
          fontWeight: 500,
          fontSize: '20px',
        }}
      >
        System Admin Management
      </span>
    );
  }

  const alertcolor = accountStatusInfo?.alertStatus == 'OK' ? green : red;
  const connectorcolor = accountStatusInfo?.connectionStatus == 'OK' ? green : red;
  return (
    <Container>
      <Modal
        open={
          accountStatusInfo?.status != null &&
          accountStatusInfo?.status === getEnumValue('AccountStatus', 'PendingSignUpCompletion')
        }
        footer={null}
        closable={false}
      >
        <MicrosoftAppForm
          onSubmit={async (data) => {
            await refetch();
          }}
        />
      </Modal>

      <Modal
        open={
          accountStatusInfo?.status != null &&
          accountStatusInfo?.status === getEnumValue('AccountStatus', 'PendingInitialScan')
        }
        footer={null}
        closable={false}
      >
        Waiting for initial account configuration to complete. Please contact Griffin31 support for more information.
      </Modal>
      <Popover
        content={
          <TooltipContainer>
            <span>{`This score reflects your security posture without any user-exemptions. It shows your current level of security based on all recommendations.`}</span>
            <Link
              to={`/recommendations?$added=${JSON.stringify(
                STANDARD_SECURITY_DRILLDOWN_FILTER,
              )}&$navorder=${JSON.stringify(ORDER_BY_SEVERITY_DESC)}`}
              replace
            >
              View Affecting Recommendations
            </Link>
          </TooltipContainer>
        }
      >
        <StatsContainer color={green + alpha}>
          <Progress
            type="circle"
            format={(percent) => `${percent}%`}
            percent={accountStatusInfo?.standardSecurityScore}
            width={46}
            // used to keep the percent text black every time, otherwise it will change to green when 100%
            status="normal"
            strokeColor={green}
          />
          <StatsTitle>Baseline Score</StatsTitle>
        </StatsContainer>
      </Popover>
      <Popover
        content={
          <TooltipContainer>
            {`This score reflects your security posture with user-exemptions taken into account. It shows your adjusted security level after excluding certain recommendations based on your choices (This score will always be equal or higher than Baseline Security Score).`}
            <Link
              to={`/recommendations?$added=${JSON.stringify(
                CUSTOM_SECURITY_DRILLDOWN_FILTER,
              )}&$navorder=${JSON.stringify(ORDER_BY_SEVERITY_DESC)}`}
            >
              View Affecting Recommendations
            </Link>
          </TooltipContainer>
        }
      >
        <StatsContainer color={blue + alpha}>
          <Progress
            type="circle"
            format={(percent) => `${percent}%`}
            percent={accountStatusInfo?.customSecurityScore}
            width={46}
            status="normal"
            strokeColor={blue}
          />
          <StatsTitle>Custom Score</StatsTitle>
        </StatsContainer>
      </Popover>

      <Popover
        content={
          <TooltipContainer>
            {accountStatusInfo && accountStatusInfo.numOfActiveAlerts > 0
              ? `You currently have ${accountStatusInfo?.numOfActiveAlerts} active alerts. `
              : 'No alerts are currently active.'}
            {accountStatusInfo && accountStatusInfo.numOfActiveAlerts > 0 && (
              <Link to="/active-alerts">Click here to view more details.</Link>
            )}
          </TooltipContainer>
        }
      >
        <StatsContainer color={alertcolor + alpha}>
          {accountStatusInfo?.alertStatus == 'OK' ? <ScoreIcon fill={alertcolor} /> : <WarningIcon fill={alertcolor} />}
          <StatsTitle>Alerts Status</StatsTitle>
        </StatsContainer>
      </Popover>

      <Popover
        content={
          <TooltipContainer>
            {accountStatusInfo?.connectionStatus === 'OK'
              ? `System connecters are configured correctly`
              : `There is an issue with your system connectors. Please contact support`}
          </TooltipContainer>
        }
      >
        <StatsContainer color={connectorcolor + alpha}>
          <ConnectorsIcon fill={connectorcolor} />
          <StatsTitle>Connectors Status</StatsTitle>
        </StatsContainer>
      </Popover>
    </Container>
  );
}
