import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { useSideBarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { IApplicationState } from '@app/store/slices/appSlice';
import { UserState } from '@app/store/slices/userSlice';
import { isScreenAllowed } from '@app/utils/utils';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const appState = useSelector<RootState>((state) => state.app) as IApplicationState;
  const userState = useSelector<RootState>((state) => state.user) as UserState;

  const navItems = useSideBarNavigation();

  const selectedKeys = useMemo(() => {
    const keys = [];
    if (location.pathname.includes('dashboard')) keys.push('Home');

    navItems.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((childItem) => {
          if (childItem.url && location.pathname.includes(childItem.url.split('?')[0])) {
            keys.push(childItem.key);
          }
        });
      } else {
        if (item.url && location.pathname.includes(item.url.split('?')[0])) {
          keys.push(item.key);
        }
      }
    });

    return keys;
  }, [location, appState]);

  const currentMenuItem = navItems
    .reduce(
      (result: SidebarNavigationItem[], current) =>
        result.concat(current.children && current.children.length > 0 ? current.children : current),
      [],
    )
    .find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = navItems.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  function shouldShowScreen(nav: SidebarNavigationItem): boolean {
    const isSubMenu = nav.children?.length;
    if (isSubMenu) {
      return true;
    }
    if (appState.appValues == null && appState.rolesAndScreens == null) {
      return false;
    }
    const dashboardScreens = (appState.appValues as any)['DashboardScreens'];
    const rolesAndScreens = appState.rolesAndScreens || [];
    const userRole = userState.user?.role || 0;
    return nav.allow || isScreenAllowed(nav.key, dashboardScreens, rolesAndScreens, userRole);
  }

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={selectedKeys}
      // onClick={() => setCollapsed(true)}
      items={navItems.filter(shouldShowScreen).map((nav) => {
        const isSubMenu = nav.children?.length;
        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu && nav.url == null ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.filter(shouldShowScreen).map((childNav) => ({
              key: childNav.key,
              label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
              title: t(childNav.title),
            })),
        };
      })}
    />
  );
};

export default SiderMenu;
