import { useState } from 'react';
import { Checkbox, Modal, ModalFuncProps } from 'antd';
import styled from 'styled-components';

import { TDataColumns } from '../types';

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 250px;
`;

const CloseIcon = styled.div``;

interface IModalFooterProps {
  onCancel: () => void;
  onApply: () => void;
}

const ModalFooter = ({ onCancel, onApply }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
      <ApplyButton onClick={onApply}>Apply</ApplyButton>
    </FooterContainer>
  );
};

interface IColumnsModalProps<T> extends ModalFuncProps {
  columns: TDataColumns<T>;
  defaultCheckedColumns?: TDataColumns<T>;
  onApply?: (newColumns: TDataColumns<T>) => void;
}

export function ColumnsModal<T>({ columns, defaultCheckedColumns, onApply, ...modalProps }: IColumnsModalProps<T>) {
  const [selectedCols, setSelectedCols] = useState(columns);

  const handleOnCheckboxChange = (isSelected: boolean, dataIndex: string) => {
    const newSelectedCols = selectedCols.map((col) => {
      if (dataIndex === col.dataIndex) {
        return { ...col, hideColumn: !isSelected };
      }

      return col;
    });
    setSelectedCols(newSelectedCols);
  };

  const handleOnCancel = () => {
    modalProps.onCancel?.();
    setSelectedCols(columns);
  };

  const handleOnApply = () => {
    onApply?.(selectedCols);
    modalProps.onCancel?.();
  };

  return (
    <Modal
      title="Add or remove displayed fields"
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseIcon />}
      footer={<ModalFooter onCancel={handleOnCancel} onApply={handleOnApply} />}
    >
      <ColumnsContainer>
        {columns
          .filter((col) => col.dataIndex !== undefined && col.allowDisplay !== false)
          .map((col, index) => (
            <div key={index}>
              <Checkbox
                // defaultChecked={defaultCheckedColumns?.find((sCol) => sCol.dataIndex === col.dataIndex)?.hideColumn}
                checked={!selectedCols.find((sCol) => sCol.dataIndex === col.dataIndex)?.hideColumn}
                onChange={(e) => handleOnCheckboxChange(e.target.checked, col.dataIndex as string)}
              >
                {typeof col.title === 'string' ? col.title : col.altLabel}
              </Checkbox>
            </div>
          ))}
      </ColumnsContainer>
    </Modal>
  );
}
