import { Fragment, useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '@app/store/store';
import { TEnumEntity } from '@app/types/enumEntity';
import { useGetAccountProducts, useUpdateSettings } from '@app/api/account.api';

import CreateAlertButton from '../recommendations/CreateAlertButton';

const ProductListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 33%;
  flex-shrink: 0;
  gap: 12px;
  margin-bottom: 20px;
`;

const ProductsToggleForm = () => {
  const products = useSelector<RootState>((state) => state.app.products) as TEnumEntity[] | null;

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  const { data: accountProducts, isSuccess } = useGetAccountProducts();
  const { mutateAsync: updateSettings, isLoading } = useUpdateSettings();

  const isSaveDisabled = useMemo(() => {
    if (!accountProducts) return true;

    return (
      JSON.stringify(selectedProductIds.sort((a, b) => a - b)) ===
      JSON.stringify(accountProducts.sort((a, b) => a.id - b.id).map((product) => product.id))
    );
  }, [selectedProductIds, accountProducts]);

  const setDefaultSelectedProducts = () => {
    if (!accountProducts) {
      setSelectedProductIds([]);
      return;
    }

    const selectedIds = accountProducts.map((ap) => ap.id) as number[];
    setSelectedProductIds(selectedIds);
  };

  const onToggle = (checked: boolean, productId: number) => {
    if (!checked) {
      const newSelectedProductIds = Array.from(new Set(selectedProductIds.filter((id) => id !== productId)));
      setSelectedProductIds(newSelectedProductIds);
      return;
    }

    const newSelectedProductIds = Array.from(new Set([...selectedProductIds, productId]));
    setSelectedProductIds(newSelectedProductIds);
  };

  const saveChanges = async () => {
    await updateSettings({
      productIds: selectedProductIds,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    setDefaultSelectedProducts();
  }, [isSuccess, products]);

  return (
    <Fragment>
      <ProductListContainer>
        {products?.map((product, index) => (
          <Fragment key={index}>
            <ProductContainer>
              <Switch
                checked={selectedProductIds.includes(product.id)}
                onChange={(checked) => onToggle(checked, product.id)}
              />
              <span>{product.displayName}</span>
            </ProductContainer>
          </Fragment>
        ))}
      </ProductListContainer>
      <CreateAlertButton title="Save" disabled={isSaveDisabled || isLoading} onPress={saveChanges} />
    </Fragment>
  );
};

export { ProductsToggleForm };
