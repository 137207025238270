import Table from 'antd/lib/table/Table';
import { SorterResult } from 'antd/lib/table/interface';

import { TDataColumns } from './types';

import Pagination, { IPaginationProps } from './components/Pagination';
import TableFilters, { ITableFiltersProps } from './components/TableFitlers';
import { ReactElement } from 'react';
import { Empty } from 'antd';
import { useResponsive } from '@app/hooks/useResponsive';

interface IDataTableProps<T extends Record<string, unknown>> {
  columns: TDataColumns<T>;
  data: T[];
  isLoading?: boolean;
  paginationProps?: IPaginationProps;
  filterProps?: ITableFiltersProps<T>;
  hasFilters?: boolean;
  maxHeight?: number;
  canSelectRows?: boolean;
  showHeader?: boolean;
  stickyHeader?: boolean;
  emptyRowText?: string;
  selectedRows?: T[];
  onSort?: (sortProps: SorterResult<T>) => void;
  onRowClick?: (data: T, index: number | undefined) => void;
  onRowMouseEnter?: (data: T, index: number | undefined) => void;
  onRowMouseLeave?: (data: T, index: number | undefined) => void;
  onSelectRows?: (rows: T[]) => void;
}

interface IDataTableWithChildrenProps<T extends Record<string, unknown>> extends IDataTableProps<T> {
  children?: (props: IDataTableProps<T>) => ReactElement;
}

export default function DataTable<T extends Record<string, unknown>>(props: IDataTableWithChildrenProps<T>) {
  const {
    columns,
    data,
    isLoading,
    paginationProps,
    filterProps,
    hasFilters,
    maxHeight,
    emptyRowText,
    stickyHeader,
    selectedRows,
    canSelectRows = true,
    showHeader = true,
    children,
    onSort,
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    onSelectRows,
  } = props;

  const { isBigScreen } = useResponsive();

  return (
    <>
      {hasFilters && !!filterProps && <TableFilters {...filterProps} />}
      {children?.(props)}
      <Table
        style={{ border: '1px solid #e9eaeb', borderRadius: '9px' }}
        showHeader={showHeader}
        loading={isLoading}
        scroll={{
          ...(!!stickyHeader && { y: isBigScreen ? '50vh' : '30vh' }),
          x: 'max-content',
          scrollToFirstRowOnChange: true,
        }}
        dataSource={data}
        columns={columns}
        locale={{ emptyText: <Empty description={emptyRowText} image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
        onRow={(row, index) => {
          return {
            onClick: () => onRowClick?.(row, index),
            onMouseEnter: () => onRowMouseEnter?.(row, index),
            onMouseLeave: () => onRowMouseLeave?.(row, index),
          };
        }}
        {...(canSelectRows && {
          rowSelection: {
            type: 'checkbox',
            ...(selectedRows && { selectedRowKeys: selectedRows.map((row) => row.key as string) }),
            onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
              onSelectRows?.(selectedRows);
            },
          },
        })}
        pagination={false}
        onChange={(val, filter, sorter, extra) => {
          onSort?.(sorter as SorterResult<T>);
        }}
      />
      {paginationProps && <Pagination {...paginationProps} />}
    </>
  );
}
