const SESSION_TOKEN_KEY = 'sessionToken';

export const persistSessionToken = (token: string) => {
  localStorage.setItem(SESSION_TOKEN_KEY, token);
};

export const readToken = (): string | null => {
  return localStorage.getItem(SESSION_TOKEN_KEY);
};

export const deleteToken = () => localStorage.removeItem(SESSION_TOKEN_KEY);
