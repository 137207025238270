import { TRegulationsForm } from '@app/components/apps/manage-regulations/RegulationsForm';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TAccountRegulations } from '@app/types/accountRegulations';

const createRegulation = async (data: TRegulationsForm) => {
  const response = await httpApi.post<any>('/regulations/create', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Regulation Created`,
    });
  }

  return response?.data;
};

export const useCreateRegulation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRegulation,
    onSuccess() {
      queryClient.invalidateQueries('Regulation-odata');
    },
  });
};

const updateRegulation = async (payload: { id: number; data: TRegulationsForm }) => {
  const { id, data } = payload;

  const response = await httpApi.patch<any>(`/regulations/${id}`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: `Regulation Updated`,
    });
  }

  return response?.data;
};

export const useUpdateRegulation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateRegulation,
    onSuccess() {
      queryClient.invalidateQueries('Regulation-odata');
    },
  });
};

async function getAccountRegulation(id: number): Promise<TAccountRegulations | null> {
  const response = await httpApi.get(`/odata/AccountRegulations?$filter=id eq ${id}`);
  return response?.data.items.at(0);
}

export const useGetAccountRegulation = (id: number) => {
  return useQuery({
    queryKey: `get-account-regulation-${id}`,
    queryFn: () => getAccountRegulation(id),
  });
};
