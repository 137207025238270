import styled from 'styled-components';

interface CustomCheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  className?: string;
  checked: boolean; // Make sure to specify the type for 'checked'
}

const CustomCheckbox = ({ className, checked, ...props }: CustomCheckboxProps) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox>
        <Icon viewBox="0 0 24 24" checked={checked}>
          <path d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default CustomCheckbox;

type StyledCheckboxProps = {
  checked: boolean;
};
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const Icon = styled.svg`
  visibility: ${(props: StyledCheckboxProps) => (props.checked ? 'visible' : 'hidden')};
`;

const StyledCheckbox = styled.div`
  display: inline-flex;
  width: 24px;
  height: 24px;
  border: 2px solid blue;
  border-radius: 5px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px lightblue;
  }
  ${Icon} {
    fill: blue;
  }
`;
