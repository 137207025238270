import { useState } from 'react';

import useToggle from '@app/hooks/useToggle';

interface IUseEmailGroupInputProps {
  defaultEmailList?: string[];
  onAddEmail?: (emailList: string[]) => void;
}

export function useEmailGroupInput(props?: IUseEmailGroupInputProps) {
  const [emails, setEmails] = useState<string[]>(props?.defaultEmailList || []);
  const [isDropdownOpen, toggle] = useToggle();

  const handleAddEmail = (email: string) => {
    const newEmails = [...emails, email];
    setEmails(newEmails);
    props?.onAddEmail?.(newEmails);
    toggle();
  };

  const removeEmail = (index: number) => {
    const newEmailList = emails.filter((_, i) => i !== index);
    setEmails(newEmailList);
    props?.onAddEmail?.(newEmailList);
  };

  const clearEmails = () => {
    setEmails([]);
    props?.onAddEmail?.([]);
  };

  return {
    emails,
    isDropdownOpen,
    toggle,
    handleAddEmail,
    removeEmail,
    clearEmails,
  };
}
