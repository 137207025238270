import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReportsForm from '@app/components/apps/manage-reports/ReportsForm';
import useManageReportsForm from '@app/components/apps/manage-reports/useManageReportsForm';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TReport } from '@app/types/reports';
import { useCreateReportItem, useUpdateReport } from '@app/api/report.api';
import { getFileBufferArray } from '@app/utils/utils';
import { uploadFileToStorage } from '@app/api/azure-storage.api';
import { getEnumValue } from '@app/services/enum.service';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';

const manageReportsTableCols: TDataColumns<TReport> = [
  {
    title: 'Report Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '30vw',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'ReportStatus',
    showSortDirections: true,
  },
  {
    title: 'Link',
    dataIndex: 'url',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Creation Time',
    dataIndex: 'creationTime',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
  },
];

interface IManageReportsPageProps {
  title?: string;
}

export default function ManageReportsPage(props: IManageReportsPageProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [selectedReport, setSelectedReport] = useState<TReport | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: updateReport, isLoading: isUpdating } = useUpdateReport();
  const { mutateAsync: createReport, isLoading: isCreating } = useCreateReportItem();
  const { toggle, ...manageReportsFormProps } = useManageReportsForm({
    onOk: async (data) => {
      const { thumbnailFile, templateFile, ...dataWithoutFiles } = data;
      let thumbnailUrl, url;

      if (!!thumbnailFile) {
        setIsUploadingFile(true);
        const thumbnail = await getFileBufferArray(thumbnailFile);
        const imgUrl = await uploadFileToStorage(thumbnailFile.name, thumbnailFile.type, thumbnail);
        thumbnailUrl = imgUrl;
      }

      if (!!templateFile) {
        setIsUploadingFile(true);
        const template = await getFileBufferArray(templateFile);
        const templateUrl = await uploadFileToStorage(templateFile.name, templateFile.type, template);
        url = templateUrl;
      }

      const payload = {
        ...dataWithoutFiles,
        ...(thumbnailUrl && { thumbnailUrl: thumbnailFile?.name }),
        ...(url && { url: templateFile?.name }),
      };

      setIsUploadingFile(false);

      if (isEdit && !!selectedReport) {
        await updateReport({
          id: selectedReport.id,
          data: payload,
        });
      } else {
        const activeReportStatus = getEnumValue('ReportStatus', 'Active');
        await createReport({ ...payload, status: activeReportStatus });
      }
      setIsEdit(false);
      setSelectedReport(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedReport(undefined);
    },
    report: selectedReport,
  });

  // const items: ActionDropdownProps<TReport>['items'] = [
  //   {
  //     label: 'Edit Report',
  //     key: 'edit',
  //     onClick: (rec) => {
  //       setIsEdit(true);
  //       setSelectedReport(rec);
  //       toggle();
  //     },
  //   },
  //   {
  //     label: 'Delete Report',
  //     key: 'delete',
  //     onClick: (rec) => {
  //       setSelectedReport(rec);
  //       setIsConfirmationModalOpen(true);
  //     },
  //   },
  // ];

  const actionItems: TActionItems<TReport>[] = [
    // {
    //   label: 'Edit Report',
    //   key: 'edit',
    //   multiSelect: false,
    //   onClick: (rec) => {
    //     setIsEdit(true);
    //     setSelectedReport(rec);
    //     toggle();
    //   },
    // },
    {
      label: 'Delete Report',
      key: 'delete',
      multiSelect: false,
      onClick: (rec) => {
        setSelectedReport(rec);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  // const tableCols: TDataColumns<TReport> = [
  //   ...manageReportsTableCols,
  //   {
  //     title: 'Actions',
  //     key: 'action',
  //     align: 'center' as const,
  //     width: 100,
  //     render: (_, record) => {
  //       return <ActionDropdown items={items} record={record} />;
  //     },
  //   },
  // ];

  const dataTableProps = useDataTable<TReport>({
    model: 'report',
    columns: manageReportsTableCols,
    searchableColumns: ['name'],
    constantFilter: '(status eq 1 or status eq 2)',
    // defaultOrder: {
    //   column: 'creationTime',
    //   order: 'desc',
    // },
    onRowClick: (data, index) => {
      setIsEdit(true);
      setSelectedReport(data);
      toggle();
    },
    actionItems,
  });

  const handleModalClose = () => {
    setSelectedReport(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (!!selectedReport) {
      // await deleteInsight(selectedReport.id);
      handleModalClose();
      dataTableProps.refetch();
    }
  };

  return (
    <>
      <PageTitle>Manage Reports</PageTitle>
      <ComponentHeader
        title={t('common.manageReports')}
        actionButtons={
          <>
            <CreateAlertButton title="Create Report" onPress={toggle} />
          </>
        }
      />
      <DataTable {...dataTableProps} />
      <ReportsForm
        {...manageReportsFormProps}
        isEdit={isEdit}
        isLoading={isUpdating || isCreating || isUploadingFile}
      />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this report?"
        // isLoading={isDeleting}
      />
    </>
  );
}
