import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import { readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import { store } from '@app/store/store';
import { logout } from '@app/store/slices/userSlice';
import config from '@app/config';

export const httpApi = axios.create({
  baseURL: config.server.url,
  withCredentials: true,
});

httpApi.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('impersonatedUserToken');
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

httpApi.interceptors.response.use(onRequestFulfilled, onRequestRejected);

export const httpApiMock = axios.create();

httpApiMock.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApiMock.interceptors.response.use(onRequestFulfilled, onRequestRejected);

export interface ApiErrorData {
  message: string;
}

function onRequestFulfilled(value: AxiosResponse<any, any>) {
  if (value.config.data) {
    try {
      const successMessage = (JSON.parse(value.config.data) as any)?.successMessage;
      if (successMessage) {
        notificationController.success({
          message: successMessage,
        });
      }
    } catch (error) {}
  }
  return value;
}

function onRequestRejected(error: AxiosError) {
  if (error.message.includes('401')) {
    store.dispatch(logout());
    return null;
  }

  const isAuthenticated = store.getState().user.isAuthenticated;
  if (isAuthenticated) {
    const message =
      error?.response?.data.message ||
      error?.response?.data?.exception?.message ||
      error?.message ||
      'Something went wrong. Please try again';

    notificationController.error({
      message,
    });

    const status = error?.response?.status;
    return Promise.reject({ data: { message }, status });
  }
  return Promise.reject(null);
  // throw new ApiError<ApiErrorData>(error?.response?.data.message || error?.message, error?.response?.data);
}

export const axiosBaseQuery =
  () => async (params: AxiosRequestConfig & { successMessage?: string; errorMessage?: string }) => {
    const { url, successMessage, errorMessage } = params;
    try {
      const result = await httpApi({
        url: url,
        withCredentials: true,
        ...params,
      });

      if (successMessage) {
        notificationController.success({ message: successMessage });
      }

      return { data: result.data };
    } catch (error: any) {
      const message = errorMessage ?? error?.message ?? 'Something went wrong. Please try again.';
      notificationController.error({
        message,
      });
      throw new Error(message);
    }
  };
