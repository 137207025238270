import { TList } from '@app/types/generalTypes';
import { httpApi, httpApiMock } from './http.api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import saveAs from 'file-saver';

interface GetModelItemsProps<T> {
  model?: string;
  endpoint?: string;
  customQueryKey?: string;
  queryParams?: string;
  searchQueryParam?: string;
  columns?: TDataColumns<T>;
  queryFn?: (queryString: string) => Promise<TList<T>>;
}

const getModelItems = async <T>({
  model,
  endpoint,
  queryParams,
  searchQueryParam,
  queryFn,
}: GetModelItemsProps<T>): Promise<TList<T>> => {
  const params = searchQueryParam
    ? queryParams?.startsWith('$top')
      ? `$filter=(${searchQueryParam})&${queryParams?.replace('$filter=', '')}`
      : `$filter=(${searchQueryParam}) and ${queryParams?.replace('$filter=', '')}`
    : queryParams;

  if (queryFn) {
    return await queryFn(params ?? '');
  }

  const url = endpoint || `/odata/${model}`;

  const response = await httpApi.get(`${url}?${params ?? ''}`);
  return response?.data;
};

export const useGetModelItems = <T>({
  model,
  endpoint,
  customQueryKey,
  queryParams,
  queryFn,
}: GetModelItemsProps<T>) => {
  return useQuery(customQueryKey || `${model}-odata`, () =>
    getModelItems<T>({ model, endpoint, customQueryKey, queryParams, queryFn }),
  );
};
export const useGetModelItemsLazy = <T>({
  model,
  customQueryKey,
  queryParams,
  columns,
  queryFn,
}: GetModelItemsProps<T>) => {
  const { t } = useTranslation();
  const appValues = useSelector<RootState>((state) => state.app.appValues);

  return useQuery({
    queryKey: customQueryKey || `${model}-odata`,
    queryFn: () => getModelItems<T>({ model, customQueryKey, queryParams, queryFn }),
    enabled: !!queryParams,
    select(data) {
      if (columns) {
        let items: TList<T>['items'] = [];

        const enumArrayCols = columns.filter((col) => col.allowFiltering && col.type === 'enumArray');
        const enumCols = columns.filter((col) => col.allowFiltering && col.type === 'enum');
        const booleanCols = columns.filter((col) => col.allowFiltering && col.type === 'boolean');
        const dateCols = columns.filter((col) => col.allowFiltering && col.type === 'date');
        const dateTimeCols = columns.filter((col) => col.allowFiltering && col.type === 'datetime');

        items = data.items?.map((item, index) => {
          let mergedEnumObject = {};
          let mergedBooleanObject = {};
          let mergedEnumArrayObject = {};

          if (enumArrayCols.length > 0 && appValues !== null) {
            const enumArrayValues = enumArrayCols.map((col) => (item as any)[col.dataIndex as string]);
            enumArrayValues.forEach((eav) => {
              mergedEnumArrayObject = { ...mergedEnumArrayObject, ...eav };
            });
          }

          // map items that has enum type
          if (enumCols.length > 0 && appValues !== null) {
            const enumValues = enumCols.map((col) => {
              if (!(item as any)[col.dataIndex as string]) {
                return '';
              }
              return {
                [col.dataIndex as string]: t(
                  `appValues.${(col as any).enumValuesKey}.${
                    (appValues as any)?.[(col as any).enumValuesKey]?.find(
                      (val: any) => val.value == (item as any)[col.dataIndex as string],
                    )?.label
                  }`,
                ),
              };
            });
            enumValues.forEach((obj) => {
              mergedEnumObject = { ...mergedEnumObject, ...obj };
            });
          }

          // map boolean values to string
          if (booleanCols.length > 0) {
            const booleanValues = booleanCols.map((col) => ({
              [col.dataIndex as string]:
                (item as any)[col.dataIndex as string] === true
                  ? 'True'
                  : (item as any)[col.dataIndex as string] === false
                  ? 'False'
                  : '',
            }));

            booleanValues.forEach((obj) => {
              mergedBooleanObject = { ...mergedBooleanObject, ...obj };
            });
          }

          // format date values
          if (dateCols.length > 0) {
            const dateValues = dateCols.map((col) => {
              if ((item as any)[col.dataIndex as string] === null) {
                return;
              }
              return {
                [col.dataIndex as string]: format(parseISO((item as any)[col.dataIndex as string]), 'dd/MM/yyyy'),
              };
            });

            dateValues.forEach((obj) => {
              mergedBooleanObject = { ...mergedBooleanObject, ...obj };
            });
          }

          // format datetime values
          if (dateTimeCols.length > 0) {
            const dateTimeValues = dateTimeCols.map((col) => {
              if ((item as any)[col.dataIndex as string] === null) {
                return;
              }
              return {
                [col.dataIndex as string]: format(
                  parseISO((item as any)[col.dataIndex as string]),
                  'dd/MM/yyyy HH:mm:ss',
                ),
              };
            });

            dateTimeValues.forEach((obj) => {
              mergedBooleanObject = { ...mergedBooleanObject, ...obj };
            });
          }

          // add keys to the items so that checkbox works propeperly on the table
          return { ...item, key: index, ...mergedEnumObject, ...mergedBooleanObject, ...mergedEnumArrayObject };
        });

        return {
          count: data.count,
          items,
        };
      }

      return data;
    },
  });
};

interface GetTableModelItemsProps<T> {
  model: string | undefined;
  endpoint?: string;
  customQueryKey?: string;
  columns: TDataColumns<T>;
  queryParams?: string;
  searchQueryParam?: string;
  trigger?: boolean;
  queryFn?: (queryString: string) => Promise<TList<T>>;
}

export const useGetTableModelItems = <T>({
  model,
  endpoint,
  columns,
  queryParams,
  customQueryKey,
  searchQueryParam,
  trigger,
  queryFn,
}: GetTableModelItemsProps<T>) => {
  const { t } = useTranslation();
  const appValues = useSelector<RootState>((state) => state.app.appValues);

  return useQuery({
    queryKey: customQueryKey || `${model}-odata`,
    queryFn: () => getModelItems<T>({ model, endpoint, customQueryKey, queryFn, queryParams, searchQueryParam }),
    enabled: trigger || false,
    select(data) {
      let items: TList<T>['items'] = [];

      const enumArrayCols = columns.filter((col) => col.allowFiltering && col.type === 'enumArray');
      const enumCols = columns.filter((col) => col.allowFiltering && col.type === 'enum');
      const booleanCols = columns.filter((col) => col.allowFiltering && col.type === 'boolean');
      const dateCols = columns.filter((col) => col.allowFiltering && col.type === 'date');
      const dateTimeCols = columns.filter((col) => col.allowFiltering && col.type === 'datetime');

      items = data.items?.map((item, index) => {
        let mergedEnumObject = {};
        let mergedBooleanObject = {};
        let mergedEnumArrayObject = {};

        if (enumArrayCols.length > 0 && appValues !== null) {
          const enumArrayValues = enumArrayCols.map((col) => (item as any)[col.dataIndex as string]);
          enumArrayValues.forEach((obj) => {
            mergedEnumArrayObject = { ...mergedEnumArrayObject, ...obj };
          });
        }

        // map items that has enum type
        if (enumCols.length > 0 && appValues !== null) {
          const enumValues = enumCols.map((col) => {
            // make sure label exists
            const label = (appValues as any)?.[(col as any).enumValuesKey]?.find(
              (val: any) => val.value == (item as any)[col.dataIndex as string],
            )?.label;
            // if doesn't exist - we set it as null
            if (!label) {
              return { [col.dataIndex as string]: null };
            }

            // otherwise return the path string
            return {
              [col.dataIndex as string]: t(`appValues.${(col as any).enumValuesKey}.${label}`),
            };
          });
          enumValues.forEach((obj) => {
            mergedEnumObject = { ...mergedEnumObject, ...obj };
          });
        }

        // map boolean values to string
        if (booleanCols.length > 0) {
          const booleanValues = booleanCols.map((col) => ({
            [col.dataIndex as string]:
              (item as any)[col.dataIndex as string] === true
                ? 'True'
                : (item as any)[col.dataIndex as string] === false
                ? 'False'
                : '',
          }));

          booleanValues.forEach((obj) => {
            mergedBooleanObject = { ...mergedBooleanObject, ...obj };
          });
        }

        // format date values
        if (dateCols.length > 0) {
          const dateValues = dateCols.map((col) => {
            if ((item as any)[col.dataIndex as string] === null) {
              return;
            }
            return {
              [col.dataIndex as string]: format(parseISO((item as any)[col.dataIndex as string]), 'dd/MM/yyyy'),
            };
          });

          dateValues.forEach((obj) => {
            mergedBooleanObject = { ...mergedBooleanObject, ...obj };
          });
        }

        // format datetime values
        if (dateTimeCols.length > 0) {
          const dateTimeValues = dateTimeCols.map((col) => {
            if ((item as any)[col.dataIndex as string] === null) {
              return;
            }
            return {
              [col.dataIndex as string]: format(
                parseISO((item as any)[col.dataIndex as string]),
                'dd/MM/yyyy HH:mm:ss',
              ),
            };
          });

          dateTimeValues.forEach((obj) => {
            mergedBooleanObject = { ...mergedBooleanObject, ...obj };
          });
        }

        // add keys to the items so that checkbox works propeperly on the table
        return { ...item, key: index, ...mergedEnumObject, ...mergedBooleanObject, ...mergedEnumArrayObject };
      });

      return {
        count: data.count,
        items,
      };
    },
  });
};

export const getModelItemsMock = async <T>(model: string, queryParams?: string): Promise<TList<T>> => {
  try {
    const response = await httpApiMock.get<TList<T>>(`${model}`);
    return response?.data;
  } catch (error) {
    return {
      count: 0,
      items: [],
    };
  }
};

const exportToExcel = async (payload: {
  modelName: string;
  filters?: string;
  sortOrder?: {
    fieldName: string;
    order: 'asc' | 'desc';
  };
  selectedFields: { name: string; displayName: string }[];
}) => {
  try {
    const response = await httpApi.post('/export', payload, { responseType: 'arraybuffer' });
    return new Blob([response.data], { type: response.headers['content-type'] });
  } catch (error) {
    console.log(error);
  }
};

export const useExportToExcel = () => {
  return useMutation({
    mutationFn: exportToExcel,
    onSuccess(data) {
      if (data) {
        const fileName = window.document.title.replace('| Griffin31 Dashboard', '').trim();
        saveAs(data, `${fileName}.xlsx`);
      }
    },
  });
};
