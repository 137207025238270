import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface IAlertItemProps {
  id: number;
  title: string;
  description: string;
  color: string;
}

export function AlertItem({ id, title, description, color }: IAlertItemProps) {
  const navigate = useNavigate();

  function onAlertClick() {
    navigate(`/alerts-history/${id}`);
  }
  return (
    <div
      title={description}
      style={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        paddingBottom: '0px',
        borderRadius: '8px',
        border: '1px solid #F1F1F2',
        //cursor: 'pointer',
        marginTop: '6px',
        overflow: 'hidden',
      }}
      onClick={onAlertClick}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div
          style={{
            width: '4px',
            height: '60px',
            background: color,
            borderRadius: '13px',
            flexShrink: 0,
            marginBottom: '8px',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <span style={{ fontSize: '0.9rem', fontWeight: 700, color: '#303030' }}>{title}</span>
          <p
            style={{
              fontSize: '.75rem',
              fontWeight: 500,
              color: '#272727',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </p>
        </div>
      </div>
      <RightOutlined style={{ color: '#3A50D1' }} />
    </div>
  );
}
