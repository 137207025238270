import { IconProps } from '../types';

export default function ScoreIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="46" height="47" viewBox="0 0 46 47" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.15" cx="22.998" cy="23.4922" r="23" fill={fill} />
      <path
        d="M20.6863 22.792C20.2863 22.392 19.6863 22.392 19.2863 22.792C18.8863 23.192 18.8863 23.792 19.2863 24.192L22.2863 27.192C22.4863 27.392 22.6863 27.492 22.9863 27.492C23.2863 27.492 23.4863 27.392 23.6863 27.192L30.6863 19.192C30.9863 18.692 30.9863 18.092 30.4863 17.792C30.0863 17.492 29.4863 17.492 29.1863 17.892L22.9863 24.992L20.6863 22.792Z"
        fill={fill}
      />
      <path
        d="M31.9863 22.4922C31.3863 22.4922 30.9863 22.8922 30.9863 23.4922C30.9863 27.8922 27.3863 31.4922 22.9863 31.4922C18.5863 31.4922 14.9863 27.8922 14.9863 23.4922C14.9863 21.3922 15.7863 19.3922 17.2863 17.8922C18.7863 16.2922 20.7863 15.4922 22.9863 15.4922C23.5863 15.4922 24.2863 15.5922 24.8863 15.6922C25.3863 15.8922 25.9863 15.5922 26.1863 14.9922C26.3863 14.3922 25.9863 13.9922 25.4863 13.7922H25.3863C24.5863 13.5922 23.7863 13.4922 22.9863 13.4922C17.4863 13.4922 12.9863 17.9922 12.9863 23.5922C12.9863 26.1922 14.0863 28.7922 15.8863 30.5922C17.7863 32.4922 20.2863 33.4922 22.8863 33.4922C28.3863 33.4922 32.8863 28.9922 32.8863 23.4922C32.9863 22.8922 32.4863 22.4922 31.9863 22.4922Z"
        fill={fill}
      />
    </svg>
  );
}
