import useToggle from '@app/hooks/useToggle';
import { TInsightsForm } from './InsightsForm';
import { TInsight } from '@app/types/insight';

interface IUseInsightsFormProps {
  onCancel?: () => void;
  onOk?: (data: TInsightsForm) => void;
  insight?: TInsight;
}

export default function useInsightsForm(props?: IUseInsightsFormProps) {
  const [isModalOpen, toggle, setiSModalOpen] = useToggle();

  const handleOnOk = (data: TInsightsForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setiSModalOpen(false);
  };

  return {
    open: isModalOpen,
    insight: props?.insight,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
