import { useMemo, useState } from 'react';

interface IUseListComponentProps<T extends { id: number | string }> {
  data: T[];
  onSelectCallback?: (data: T) => void;
}

export function useListComponent<T extends { id: string | number }>({
  data,
  onSelectCallback,
}: IUseListComponentProps<T>) {
  const [selectedIds, setSelectedIds] = useState<T['id'][]>([]);

  const selectedItems = useMemo(() => {
    return data.filter((d) => selectedIds.indexOf(d.id) >= 0);
  }, [selectedIds]);

  const handleOnItemClick = (data: T) => {
    if (selectedIds.indexOf(data.id) > -1) {
      const newSelectedItems = selectedIds.filter((item) => item !== data.id);
      setSelectedIds(newSelectedItems);
    } else {
      setSelectedIds([...selectedIds, data.id]);
    }

    onSelectCallback?.(data);
  };

  const isItemSelected = (id: T['id']) => {
    return selectedIds.indexOf(id) > -1;
  };

  return {
    data,
    selectedItems,
    onSelectCallback,
    handleOnItemClick,
    isItemSelected,
  };
}
