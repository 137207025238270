import { useTranslation } from 'react-i18next';

import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import { useState } from 'react';
import useRegulationsForm from '@app/components/apps/manage-regulations/useRegulationsForm';
import RegulationsForm from '@app/components/apps/manage-regulations/RegulationsForm';
import RegulationsModal from '@app/pages/RegulationsControlsModal';

import { useCreateRegulation, useUpdateRegulation } from '@app/api/regulation.api';
import { TRegulations } from '@app/types/regulations';

const regulationsComplianceCols: TDataColumns<TRegulations> = [
  {
    title: 'Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Summary',
    dataIndex: 'summary',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '15vw',
    hideColumn: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '15vw',
    hideColumn: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'RegulationStatus',
    showSortDirections: true,
    width: 200,
    hideColumn: false,
  },
];

export default function ManageRegulationsPage() {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRegulation, setSelectedRegulation] = useState<TRegulations | undefined>(undefined);
  const [IsControlsModalOpen, setIsControlsModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createRegulation, isLoading: isCreating } = useCreateRegulation();
  const { mutateAsync: updateRegulation, isLoading: isUpdating } = useUpdateRegulation();

  const { toggle, ...regulationFormProps } = useRegulationsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedRegulation) {
        await updateRegulation({
          id: selectedRegulation.id,
          data: data,
        });
      } else {
        await createRegulation(data);
      }

      setIsEdit(false);
      setSelectedRegulation(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedRegulation(undefined);
    },
    regulation: selectedRegulation,
  });

  const actionItems: TActionItems<TRegulations>[] = [
    {
      label: 'Update Regulation',
      key: 'update-regulation',
      multiSelect: false,
      onClick: (item) => {
        setIsEdit(true);
        setSelectedRegulation(item);
        toggle();
      },
    },
    {
      label: 'Manage Controls',
      key: 'manage-controls',
      multiSelect: false,
      onClick: (item) => {
        setSelectedRegulation(item);
        setIsControlsModalOpen(true);
      },
    },
  ];

  const dataTableProps = useDataTable<TRegulations>({
    model: 'Regulation',
    columns: regulationsComplianceCols,
    searchableColumns: ['name', 'description'],
    constantFilter: '(status eq 1)',
    defaultOrder: {
      column: 'name',
      order: 'asc',
    },
    actionItems,
    onRowClick: (data, index) => {
      setIsEdit(true);
      setSelectedRegulation(data);
      toggle();
    },
  });

  return (
    <>
      <PageTitle>{t('common.manageRegulations')}</PageTitle>
      <ComponentHeader
        title={t('common.manageRegulations')}
        subTitle="Create and update regulations and assign relevant recommendations to their controls."
        actionButtons={
          <>
            <CreateAlertButton title="Create Regulation" onPress={toggle} />
          </>
        }
      />
      <RegulationsModal
        open={IsControlsModalOpen}
        regulationData={{
          RegulationId: selectedRegulation ? selectedRegulation.id : 0,
          RegulationName: selectedRegulation?.name || '',
        }}
        onCancel={() => setIsControlsModalOpen(false)}
      />
      <DataTable {...dataTableProps} />
      <RegulationsForm {...regulationFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
    </>
  );
}
