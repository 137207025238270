import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import AlertHeader from '@app/components/apps/alerts/AlertDetailPage/AlertHeader';
import Tabs from '@app/components/apps/alerts/AlertDetailPage/Tabs/Tabs';

const AlertDynamicPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCreate = useMemo(() => {
    return location.pathname.includes('create');
  }, [location]);

  return (
    <>
      <PageTitle>{t('common.alerts')}</PageTitle>
      <AlertHeader>{`${isCreate ? 'Create' : 'Edit'} Alert`}</AlertHeader>
      <Tabs />
    </>
  );
};

export default AlertDynamicPage;
