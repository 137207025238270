import styled from 'styled-components';

import { HorizontalAlignedContainer } from '../common/BaseLayout/BaseLayout.styled';
import { TWidgetData } from '@app/types/widget';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { getEnumValue } from '@app/services/enum.service';

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Legend = styled.div<{ backgroundColor: string }>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) => props.backgroundColor};
`;

const LegendLabel = styled(Link)`
  font-weight: 500;
  font-size: 0.75rem;
  color: #303030;
  line-height: 14px;
`;

const ViewAllLink = styled(Link)`
  align-self: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3a50d1;
`;

interface IGeneralWidgetProps extends TWidgetData {
  drillDownType: 'status' | 'risk' | 'product' | 'product-recommendations';
}

export default function GeneralWidgetTooltip({ data, drillDownType }: IGeneralWidgetProps) {
  const filters = useMemo(() => {
    if (drillDownType === 'product-recommendations') {
      return [
        {
          column: 'isCompliant',
          value: false,
          type: 'boolean',
          title: 'Is Compliant',
        },
        {
          column: 'isExempted',
          value: false,
          type: 'boolean',
          title: 'Is Exempted',
        },
      ];
    }

    if (drillDownType === 'status') {
      return [
        {
          column: 'isExempted',
          value: false,
          type: 'boolean',
          title: 'Is Exempted',
        },
      ];
    }

    return [
      {
        column: 'easeOfFix',
        enumKey: 'RecommendationEaseOfFix',
        value: JSON.stringify([getEnumValue('RecommendationEaseOfFix', 'Minimal')]),
        type: 'enum',
        title: 'User Impact',
      },
      {
        column: 'estimatedWork',
        enumKey: 'RecommendationEstimatedWork',
        value: JSON.stringify([getEnumValue('RecommendationEstimatedWork', 'Minimal')]),
        type: 'enum',
        title: 'Estimated Work',
      },
      {
        column: 'isCompliant',
        value: false,
        type: 'boolean',
        title: 'Is Compliant',
      },
      {
        column: 'isExempted',
        value: false,
        type: 'boolean',
        title: 'Is Exempted',
      },
    ];
  }, [drillDownType]);

  return (
    <Container>
      {data?.map((d, index) => {
        const newFilters = [...filters.filter((f) => f.column !== d.appliedFilter.column), d.appliedFilter];
        const link = `/recommendations?$added=${JSON.stringify(newFilters)}`;
        return (
          <HorizontalAlignedContainer key={index} style={{ gap: '5px' }}>
            <Legend backgroundColor={d?.color || 'transparent'} />
            <LegendLabel to={link}>{`${d.name}: ${d.value || 0} Recommendations`}</LegendLabel>
          </HorizontalAlignedContainer>
        );
      })}

      <ViewAllLink to={`/recommendations?$added=${JSON.stringify(filters)}`}>View All</ViewAllLink>
    </Container>
  );
}
