import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import { Divider, Dropdown, MenuProps, Popover, DatePicker, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import moment from 'moment';

import { ActionsIcon, ColumnsIcon, FilterIcon, TrashIcon } from '@app/components/icons';
import { CustomDropdown } from './CustomDropdown';
import { TDataCol, TDataColumns, TFilterValue } from '../types';
import { CustomFilterDropdown } from './CustomFilterDropdown';
import { ColumnsModal } from './ColumnsModal';
import FilterChip from './FilterChip';
import { TActionItems } from '../hooks/useTableFilters';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e9eaeb;
  background-color: #fff;
  padding: 6px 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  gap: 4px;
`;

const IconContainer = styled.div`
  background-color: #f8f8f8;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColumnIconContainer = styled(IconContainer)`
  cursor: pointer;
  background-color: transparent;
  padding: 12px 6px;
`;

const FilterChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 94px;
`;

const Chip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 10px;
`;

const ClearFilterIcon = styled(Chip)<{ isActive: boolean }>`
  align-self: flex-start;
  margin-top: 4px;
  font-weight: 600;
  color: ${(props) => (props.isActive ? '#272727' : '#acacac')};
  flex-shrink: 0;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  gap: 4px;
  ${(props) => (props.isActive ? 'border: 1px solid #272727;' : '')}
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px;
  gap: 12px;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;

  ::placeholder {
    color: #acacac;
  }
`;

export interface ITableFiltersProps<T> {
  columns: TDataColumns<T>;
  defaultCheckedColumns?: TDataColumns<T>;
  columnFilters: ItemType[];
  appliedFilters: TFilterValue[];
  searchColumnValue: string;
  selectedFilterColumn: TDataCol<T> | null;
  isDropdownOpen: boolean;
  isModalOpen: boolean;
  searchValue: string;
  hasSearchFunctionality: boolean;
  selectedRows?: T[];
  actionItems?: TActionItems<T>[];
  hideExportToExcel?: boolean;
  showTimeline?: boolean;
  timelineRange?: {
    startDate: Date;
    endDate: Date;
  };
  setTimelineRange?: (newRange: { startDate: Date; endDate: Date } | undefined) => void;
  onSearchColumn: (e: ChangeEvent<HTMLInputElement>) => void;
  onFilterOptionClicked: MenuProps['onClick'];
  toggleDropdown: (open: boolean) => void;
  onBackClicked: () => void;
  onApply: (filterObj: TFilterValue) => void;
  onFilterRemove: (index: number) => void;
  onClearFilters: () => void;
  toggleModal: (open: boolean) => void;
  onColumnsChanged?: (newCols: TDataColumns<T>) => void;
  onChangeSearchValue?: (e: ChangeEvent<HTMLInputElement>) => void;
  onExport?: () => void;
}

function TableFilters<T>({
  columns,
  defaultCheckedColumns,
  columnFilters,
  appliedFilters,
  searchColumnValue,
  selectedFilterColumn,
  isDropdownOpen,
  isModalOpen,
  searchValue,
  hasSearchFunctionality,
  selectedRows,
  actionItems,
  hideExportToExcel,
  showTimeline,
  timelineRange,
  setTimelineRange,
  onSearchColumn,
  onFilterOptionClicked,
  toggleDropdown,
  onBackClicked,
  onApply,
  onClearFilters,
  onFilterRemove,
  toggleModal,
  onColumnsChanged,
  onChangeSearchValue,
  onExport,
}: ITableFiltersProps<T>) {
  const menuItems =
    actionItems
      ?.filter((item) => {
        if (!!selectedRows) {
          if (!!item.show) {
            if (selectedRows.length > 0) {
              if (!item.multiSelect) {
                return item.show(selectedRows[0]);
              } else {
                return item.show(selectedRows);
              }
            }
          }
          return true;
        }
        return false;
      })
      .map((item) => {
        const isItemDisabled = () => {
          if (!selectedRows) {
            return true;
          }

          if (!item.multiSelect && selectedRows.length > 1) {
            return true;
          }

          if (item.multiSelect && selectedRows.length < 1) {
            return true;
          }

          return false;
        };

        return (
          <Menu.Item
            key={item.key}
            disabled={isItemDisabled()}
            onClick={() => {
              if (!!selectedRows) {
                if (!item.multiSelect && selectedRows.length === 1) {
                  item.onClick(selectedRows[0]);
                  return;
                }

                if (item.multiSelect && selectedRows.length > 0) {
                  item.onClick(selectedRows);
                  return;
                }
              }
            }}
          >
            {item.label}
          </Menu.Item>
        );
      }) || [];

  return (
    <>
      <Container>
        <FilterContainer style={{ flexGrow: 1, paddingBottom: '0px' }}>
          <Popover content={<div>Refine the list of displayed items</div>}>
            <IconContainer
              style={{ alignSelf: 'flex-start', marginTop: '8px', cursor: 'pointer' }}
              onClick={() => toggleDropdown(!isDropdownOpen)}
            >
              <FilterIcon />
            </IconContainer>
          </Popover>

          <Divider type="vertical" style={{ alignSelf: 'flex-start', marginTop: '16px' }} />

          <FilterChipsContainer>
            <FilterChipsContainer>
              {appliedFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  index={index}
                  filter={filter}
                  columnFilters={columnFilters}
                  onApply={onApply}
                  onBackClicked={onBackClicked}
                  onFilterOptionClicked={onFilterOptionClicked}
                  onFilterRemove={onFilterRemove}
                />
              ))}
            </FilterChipsContainer>
            <Dropdown
              menu={{
                items: columnFilters,
                onClick: onFilterOptionClicked,
              }}
              trigger={['click']}
              arrow
              open={isDropdownOpen}
              onOpenChange={toggleDropdown}
              dropdownRender={(menu) =>
                selectedFilterColumn ? (
                  <CustomFilterDropdown
                    title={
                      typeof selectedFilterColumn.title === 'string'
                        ? selectedFilterColumn.title?.toString()
                        : !!selectedFilterColumn.altLabel
                        ? selectedFilterColumn.altLabel
                        : ''
                    }
                    dataIndex={selectedFilterColumn.dataIndex?.toString() || ''}
                    type={(selectedFilterColumn as any).type}
                    filterOptions={(selectedFilterColumn as any).filterOptions}
                    onBackClicked={onBackClicked}
                    onApply={onApply}
                    enumKey={(selectedFilterColumn as any).enumValuesKey ?? undefined}
                  />
                ) : (
                  <CustomDropdown menu={menu} inputValue={searchColumnValue} onChange={onSearchColumn} />
                )
              }
            >
              <Popover content={<div>Refine the list of displayed items</div>}>
                <Text role="button" style={{ flexShrink: 0 }}>
                  Add Filter
                </Text>
              </Popover>
            </Dropdown>
          </FilterChipsContainer>

          <div style={{ flexGrow: 1 }} />

          <ClearFilterIcon role="button" isActive={appliedFilters.length > 0} onClick={() => onClearFilters?.()}>
            <TrashIcon fill={appliedFilters.length > 0 ? '#D81C2E' : '#ACACAC'} />
            Clear Filters
          </ClearFilterIcon>
        </FilterContainer>
        {showTimeline && (
          <FilterContainer style={{ alignSelf: 'flex-start' }}>
            <DatePicker.RangePicker
              value={timelineRange ? [moment(timelineRange.startDate), moment(timelineRange.endDate)] : null}
              style={{ border: 'none' }}
              format="DD/MM/yyyy"
              onChange={(val) => {
                if (!val || val.length < 1) {
                  setTimelineRange?.(undefined);
                  return;
                }

                setTimelineRange?.({
                  startDate: val[0]!.toDate(),
                  endDate: val[1]!.toDate(),
                });
              }}
            />
          </FilterContainer>
        )}
        {hasSearchFunctionality ? (
          <FilterContainer style={{ alignSelf: 'flex-start' }}>
            <SearchContainer>
              <SearchOutlined style={{ color: '#ACACAC' }} />
              <SearchInput type="text" placeholder="Search..." value={searchValue} onChange={onChangeSearchValue} />
            </SearchContainer>
          </FilterContainer>
        ) : null}
        <Popover content={<div>Modify the list of fields displayed</div>} placement="topLeft">
          <FilterContainer style={{ alignSelf: 'flex-start' }}>
            <ColumnIconContainer role="button" onClick={() => toggleModal(!isModalOpen)}>
              <ColumnsIcon fill="#30AF5B" />
            </ColumnIconContainer>
          </FilterContainer>
        </Popover>
        {!hideExportToExcel && (
          <Popover content={<div>Export to excel</div>} placement="topLeft">
            <FilterContainer style={{ alignSelf: 'flex-start' }}>
              <ColumnIconContainer role="button" onClick={onExport}>
                <ExportOutlined style={{ color: '#30AF5B' }} />
              </ColumnIconContainer>
            </FilterContainer>
          </Popover>
        )}
        {menuItems.length > 0 && (
          <Popover content={<div>Select an action to perform on the selected rows</div>} placement="topLeft">
            <FilterContainer style={{ alignSelf: 'flex-start' }}>
              <Dropdown
                overlay={<Menu>{menuItems}</Menu>}
                trigger={(selectedRows || []).length > 0 ? ['click'] : []}
                arrow
              >
                <ColumnIconContainer
                  role="button"
                  style={(selectedRows || []).length > 0 ? { cursor: 'pointer' } : { cursor: 'default' }}
                >
                  <ActionsIcon fill={(selectedRows || []).length > 0 ? '#30AF5B' : '#ACACAC'} />
                </ColumnIconContainer>
              </Dropdown>
            </FilterContainer>
          </Popover>
        )}
      </Container>
      <ColumnsModal
        columns={columns}
        open={isModalOpen}
        defaultCheckedColumns={defaultCheckedColumns}
        maskClosable
        onCancel={() => toggleModal(false)}
        onApply={onColumnsChanged}
      />
    </>
  );
}

export default TableFilters;
