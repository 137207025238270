import { getEnumValues, getRolesAndScreens } from '@app/api/app.api';
import { getAllLicenses } from '@app/api/license.api';
import { getAllProducts } from '@app/api/product.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { TRoleAndScreen } from '@app/types/roleAndScreen';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface IApplicationState {
  appValues?: Record<string, unknown> | null;
  rolesAndScreens?: TRoleAndScreen[] | null;
  products?: TEnumEntity[] | null;
  licenses?: TEnumEntity[] | null;
  finishedLoadingValues: boolean;
}

const initialState: IApplicationState = {
  appValues: null,
  rolesAndScreens: null,
  products: null,
  licenses: null,
  finishedLoadingValues: false,
};

export const loadAppValues = createAsyncThunk('app/loadAppValues', async () => {
  try {
    const responseValues = await Promise.all([
      getEnumValues(),
      getAllProducts(),
      getAllLicenses(),
      getRolesAndScreens(),
    ]);

    let enumValues: Record<string, unknown> = {};
    if (responseValues[0]) {
      const keys = Object.keys(responseValues[0]);
      for (const key of keys) {
        const valueKeys = Object.keys((responseValues[0] as any)[key]);
        const values = [];

        for (const valKey of valueKeys) {
          // allow Deleted enum in user status
          if (key === 'UserStatus') {
            values.push({
              label: valKey,
              value: (responseValues[0] as any)[key][valKey],
            });
          } else {
            if (valKey !== 'Deleted') {
              values.push({
                label: valKey,
                value: (responseValues[0] as any)[key][valKey],
              });
            }
          }
        }
        enumValues = {
          ...enumValues,
          [key]: values,
        };
      }
    }

    return {
      appValues: enumValues,
      products: responseValues[1],
      licenses: responseValues[2],
      rolesAndScreens: responseValues[3],
    };
  } catch (error) {
    return null;
  }
});

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAppValues.fulfilled, (state, action) => {
      state.appValues = action.payload?.appValues;
      state.products = action.payload?.products;
      state.licenses = action.payload?.licenses;
      state.rolesAndScreens = action.payload?.rolesAndScreens;
      state.finishedLoadingValues = true;
    });
  },
});

export default appSlice.reducer;
